import React from 'react';
import { ECommonFormType } from 'common/models/commonModel';
import { PromoCreateFormSkeleton } from 'entities/Promo/components/Skeleton/PromoCreateFormSkeleton';
import { PromoEditFormSkeleton } from 'entities/Promo/components/Skeleton/PromoEditFormSkeleton';

interface IComponentProps {
  typeForm: ECommonFormType;
}
export const PromoFormSkeletonPage: React.FC<IComponentProps> = (props: IComponentProps) => {
  const { typeForm } = props;
  const isCreateForm = typeForm === ECommonFormType.Add;

  return isCreateForm ? <PromoCreateFormSkeleton /> : <PromoEditFormSkeleton />;
};
