import moment from 'moment';

export const dateFormatter = (date: string | undefined | null, dateFormat: string = 'DD MMMM YYYY, HH:mm') =>
  date ? moment(date).format(dateFormat) : '';
export const dateFormatterToUTC = (date: string, dateFormat: string = 'DD MMM YYYY, HH:mm') =>
  moment.utc(date, dateFormat).format();
export const dateDifference = (firstDate: string, secondDate: string) => moment(firstDate).diff(secondDate);
export const timeFormatter = (time: string | undefined | null, dateFormat: string = 'HH:mm') =>
  time ? moment(time, 'HH:mm:ss').format(dateFormat) : '';

export const rangeConfig: any = {
  Today: [moment().startOf('day'), moment()],
  Yesterday: [
    moment()
      .subtract(1, 'days')
      .startOf('day'),
    moment()
      .subtract(1, 'days')
      .endOf('day')
  ],
  'This week': [moment().startOf('week'), moment().endOf('week')],
  'This month': [moment().startOf('month'), moment().endOf('month')],
  'Last week': [
    moment()
      .subtract(1, 'weeks')
      .startOf('week'),
    moment()
      .subtract(1, 'weeks')
      .endOf('week')
  ],
  'Last month': [
    moment()
      .subtract(1, 'months')
      .startOf('month'),
    moment()
      .subtract(1, 'months')
      .endOf('month')
  ],
  'Last 7 days': [
    moment()
      .subtract(7, 'days')
      .startOf('day'),
    moment()
      .subtract(1, 'days')
      .endOf('day')
  ],
  'Last 14 days': [
    moment()
      .subtract(14, 'days')
      .startOf('day'),
    moment()
      .subtract(1, 'days')
      .endOf('day')
  ],
  'Last 30 days': [
    moment()
      .subtract(30, 'days')
      .startOf('day'),
    moment()
      .subtract(1, 'days')
      .endOf('day')
  ]
};
