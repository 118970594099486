import { message } from 'antd';
import { IStoreLocation } from 'entities/Store/Store.models';

export enum ELocationType {
  Country = 'country',
  City = 'locality',
  Street = 'route',
  HouseNumber = 'street_number',
  RegionLevelOne = 'administrative_area_level_1',
  RegionLevelTwo = 'administrative_area_level_2'
}

export function parseLocation(location: any, type: ELocationType) {
  if (!location.address_components) {
    message.error('Incorrect request!');
  }

  const locationArr = location.address_components.filter((item: any) => item.types[0] === type);

  return locationArr.length !== 0 ? locationArr[0].long_name : null;
}

export function locationFormatter(location?: IStoreLocation) {
  return location
    ? `${location?.house || ''} ${location?.street || ''} ${location?.city || ''} ${location?.region || ''} ${location?.country ||
        ''}`
    : undefined;
}
