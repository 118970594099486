import React from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ECommonFormType } from 'common/models/commonModel';
import { nameBuilder } from 'common/helpers/name.helper';
import { communicationUser, IUserConnectedProps } from 'entities/User/User.communication';
import {
  EUserRole,
  EUserStatus,
  EUserStatusText,
  IFranchiseeData,
  IUserInviteData,
  IUserUpdateData
} from 'entities/User/User.models';
import { UserFormSkeleton } from 'entities/User/components/Skeleton/UserFormSkeleton';
import { UserForm } from 'entities/User/components/Form/UserForm';

interface IParams {
  id?: string;
}

type AllProps = IUserConnectedProps & RouteComponentProps<IParams>;

class UserPageComponent extends React.PureComponent<AllProps> {
  componentDidMount(): void {
    const { getUserModel, match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      getUserModel({ id });
    }
  }

  componentWillUnmount() {
    const { clearUserModel } = this.props;

    clearUserModel();
  }

  render() {
    const { userModel, match } = this.props;
    const { params } = match;
    const { id } = params;
    const { data: userModelData, loading: userLoading } = userModel;
    const isEdit = !!id;
    const typeForm = isEdit ? ECommonFormType.Update : ECommonFormType.Add;
    const isBlocked = userModelData?.status === EUserStatus.Blocked;
    const isPending = userModelData?.status === EUserStatus.Pending;
    const name = nameBuilder(userModelData?.firstName, userModelData?.lastName);
    const role = userModelData ? `${userModelData?.role.charAt(0).toUpperCase() + userModelData?.role.slice(1)}` : 'Unknown role';
    const statusBlocked = isBlocked ? `(${EUserStatusText.Blocked})` : isPending ? `(${EUserStatusText.Pending})` : '';
    const title = isEdit ? `${name} - ${role} ${statusBlocked}` : 'Invite new user';

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
        <Col span={16}>
          <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
            <Breadcrumb.Item href={ERoutesPrivate.Users}>User list</Breadcrumb.Item>
            <Breadcrumb.Item>
              {isEdit ? (
                !userLoading ? (
                  title
                ) : (
                  <Skeleton.Input active style={{ width: '200px' }} size="small" />
                )
              ) : (
                'Invite new user'
              )}
            </Breadcrumb.Item>
          </Breadcrumb>
          {!userLoading || !isEdit ? (
            <UserForm
              typeForm={typeForm}
              userModel={userModel}
              inviteUser={this.inviteUser}
              updateUser={this.updateUser}
              deleteUser={this.deleteUser}
              reInviteUser={this.reInviteUser}
              blockUser={this.blockUser}
            />
          ) : (
            <UserFormSkeleton />
          )}
        </Col>
      </CommonLayout>
    );
  }

  inviteUser = (data: IUserInviteData) => {
    const { inviteUserModel } = this.props;

    inviteUserModel({ data });
  };

  updateUser = (data: IUserUpdateData) => {
    const { updateUserModel, match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      updateUserModel({ path: { id }, data });
    }
  };

  reInviteUser = () => {
    const { reInviteUserModel, match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      reInviteUserModel({ id });
    }
  };

  blockUser = () => {
    const { userModel } = this.props;
    const { data: userData } = userModel;
    const isBlocked = userData?.status === EUserStatus.Blocked;
    const role = userData?.role;
    const firstName = userData?.firstName;
    const lastName = userData?.lastName;
    const phone = userData?.phone;
    const franchisees = userData?.franchisees;

    if (firstName && lastName && phone) {
      const status = isBlocked ? EUserStatus.Active : EUserStatus.Blocked;
      const vals: IUserUpdateData = {
        firstName,
        lastName,
        phone,
        status
      };

      if (role === EUserRole.SalesOps) {
        vals.franchisees = franchisees?.map((franchisee: IFranchiseeData) => franchisee.id);
      }

      this.updateUser(vals);
    }
  };

  deleteUser = () => {
    const { deleteUserModel, match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      deleteUserModel({ id });
    }
  };
}

export const UserPage = communicationUser.injector(UserPageComponent);
