import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import {
  IPromoCollection,
  IPromoCollectionFilter,
  IPromoCreateData,
  IPromoModel,
  IPromoUpdateData
} from 'entities/Promo/Promo.models';
import { IStoreModel } from 'entities/Store/Store.models';

const basePath = '/promos';

class PromoTransport extends BaseHttpTransport<
  AxiosStatic,
  IPromoModel,
  IPromoCollection,
  IPromoCreateData,
  IPromoUpdateData,
  IPromoCollectionFilter
> {
  public create = (data: IPromoCreateData): Promise<IPromoModel> => {
    const { storesArrayValue, criteriaMenuItemsValue, bonusMenuItemValue, criteriaCategoriesValue } = data || {};
    const stores = storesArrayValue?.map((item: IStoreModel) => item.id);
    const params: IPromoCreateData = {
      ...data,
      stores
    };
    if (criteriaMenuItemsValue) {
      params.criteriaMenuItems = [criteriaMenuItemsValue.id];
    }
    if (bonusMenuItemValue) {
      params.bonusMenuItem = bonusMenuItemValue.id;
    }
    if (criteriaCategoriesValue) {
      params.criteriaCategories = [criteriaCategoriesValue.id];
    }
    return axios.post(basePath, params);
  };
}

export const promoTransport = new PromoTransport(basePath, axios);
