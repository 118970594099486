import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EStoreStatus, EStoreStatusText } from 'entities/Store/Store.models';

interface IComponentProps {
  onChange?: (value: EStoreStatus) => void;
}

type AllProps = SelectProps<any> & IComponentProps;

export class StoreStatusSelector extends React.PureComponent<AllProps> {
  handleChange = (value: EStoreStatus) => {
    const { onChange } = this.props;
    onChange && onChange(value);
  };

  render() {
    const { value, defaultValue } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        placeholder="Select status"
        value={value}
        defaultActiveFirstOption={false}
        defaultValue={defaultValue}
        showArrow
        onChange={(value: EStoreStatus) => this.handleChange(value)}
      >
        <Select.Option key={EStoreStatus.New} value={EStoreStatus.New} label={EStoreStatusText.New}>
          {EStoreStatusText.New}
        </Select.Option>
        <Select.Option key={EStoreStatus.Active} value={EStoreStatus.Active} label={EStoreStatusText.Active}>
          {EStoreStatusText.Active}
        </Select.Option>
        <Select.Option key={EStoreStatus.Archived} value={EStoreStatus.Archived} label={EStoreStatusText.Archived}>
          {EStoreStatusText.Archived}
        </Select.Option>
        <Select.Option key={EStoreStatus.Unavailable} value={EStoreStatus.Unavailable} label={EStoreStatusText.Unavailable}>
          {EStoreStatusText.Unavailable}
        </Select.Option>
      </Select>
    );
  }
}
