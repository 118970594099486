import React from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { EMenuPageTabKeys } from 'app/pages/Franchisor/MenuPage';
import { IMenuItemCreateData, IMenuItemUpdateData } from 'entities/MenuItem/MenuItem.models';
import { communicationMenuItem, IMenuItemConnectedProps } from 'entities/MenuItem/MenuItem.communication';
import { MenuItemForm } from 'entities/MenuItem/components/Form/MenuItemForm';
import { MenuItemFormSkeleton } from 'entities/MenuItem/components/Skeleton/MenuItemFormSkeleton';

interface IParams {
  id?: string;
}

type AllProps = IMenuItemConnectedProps & RouteComponentProps<IParams>;

class MenuItemPageComponent extends React.PureComponent<AllProps> {
  componentDidMount() {
    const { getMenuItemModel, match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      getMenuItemModel(id);
    }
  }

  componentWillUnmount() {
    const { clearMenuItemModel } = this.props;

    clearMenuItemModel();
  }

  render() {
    const { menuItemModel, match } = this.props;
    const { params } = match;
    const { id } = params;
    const { data, loading } = menuItemModel;
    const menuItemName = data?.name;
    const isEdit = !!id;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
        <Col span={12}>
          <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
            <Breadcrumb.Item href={`${ERoutesPrivate.Menu}?tab=${EMenuPageTabKeys.Items}`}>Items list</Breadcrumb.Item>
            <Breadcrumb.Item>
              {isEdit ? (
                !loading ? (
                  menuItemName
                ) : (
                  <Skeleton.Input active style={{ width: '200px' }} size="small" />
                )
              ) : (
                'Add new item'
              )}
            </Breadcrumb.Item>
          </Breadcrumb>
          {!loading || !isEdit ? (
            <MenuItemForm
              menuItemModel={menuItemModel}
              createMenuItem={this.createMenuItem}
              updateMenuItem={this.updateMenuItem}
              deleteMenuItem={this.deleteMenuItem}
              menuItemId={id}
            />
          ) : (
            <MenuItemFormSkeleton isEdit={isEdit} />
          )}
        </Col>
      </CommonLayout>
    );
  }

  updateMenuItem = (data: IMenuItemUpdateData) => {
    const { updateMenuItemModel, match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      updateMenuItemModel({ ...data, id });
    }
  };

  createMenuItem = (data: IMenuItemCreateData) => {
    const { addMenuItemModel } = this.props;

    addMenuItemModel(data);
  };

  deleteMenuItem = () => {
    const { deleteMenuItemModel, match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      deleteMenuItemModel(id);
    }
  };
}

export const MenuItemPage = withRouter(communicationMenuItem.injector(MenuItemPageComponent));
