import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { message } from 'antd';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { IError } from '@axmit/error-helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { categoryTransport } from 'entities/Category/Category.transport';
import {
  ECategorySuccessMessage,
  ICategoryCollection,
  ICategoryCollectionFilter,
  ICategoryCreateData,
  ICategoryModel,
  ICategoryUpdateData,
  ICategoryParamsModel
} from 'entities/Category/Category.models';

const namespace = 'category';

export interface ICategoryStoreProps {
  collection: StoreBranch<ICategoryCollection, ICategoryCollectionFilter>;
}

export interface ICategoryConnectedProps {
  categoryModel: StoreBranch<ICategoryModel, ICategoryParamsModel>;
  categoryCollection: StoreBranch<ICategoryCollection, ICategoryCollectionFilter>;

  getCategoryModel(id: string): void;
  updateCategoryModel(data: ICategoryUpdateData): void;
  addCategoryModel(data: ICategoryCreateData): void;
  deleteCategoryModel(id: string): void;

  getCategoryCollection(filter?: ICategoryCollectionFilter): void;

  clearCategoryModel(): void;
  clearCategoryCollection(): void;
}

const CategoryModelAPIProviders = [
  new APIProvider(EActionsTypes.get, categoryTransport.get),
  new APIProvider(EActionsTypes.update, categoryTransport.update, {
    preRequestDataMapper: function(response: null, params: any, branch: StoreBranch<ICategoryModel | null, any, any>) {
      return branch?.data;
    },
    onSuccess: function*() {
      message.success(ECategorySuccessMessage.Edit);
      yield put(push(`${ERoutesPrivate.Menu}?tab=categories`));
    }
  }),
  new APIProvider(EActionsTypes.delete, categoryTransport.delete, {
    preRequestDataMapper: function(response: null, params: any, branch: StoreBranch<ICategoryModel | null, any, any>) {
      return branch?.data;
    },
    onSuccess: function*() {
      message.success(ECategorySuccessMessage.Delete);
      yield put(push(`${ERoutesPrivate.Menu}?tab=categories`));
    },
    onFail: function(error: IError) {
      message.error(error.data.message);
    }
  }),
  new APIProvider(EActionsTypes.add, categoryTransport.add, {
    onSuccess: function*() {
      message.success(ECategorySuccessMessage.Create);
      yield put(push(`${ERoutesPrivate.Menu}?tab=categories`));
    }
  })
];

const CategoryCollectionAPIProviders = [new APIProvider(EActionsTypes.get, categoryTransport.getCollection)];

const branches = [new Branch('model', CategoryModelAPIProviders), new Branch('collection', CategoryCollectionAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationCategory = buildCommunication<ICategoryConnectedProps>(strategy);

communicationCategory.sagas.push();
