import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { message } from 'antd';
import { ERoutesPrivate } from 'common/models/routesModel';
import { IError } from 'common/helpers/error.helper';
import { promoTransport } from 'entities/Promo/Promo.transport';
import {
  EPromoSuccessMessage,
  IPromoCollection,
  IPromoCollectionFilter,
  IPromoCreateData,
  IPromoModel,
  IPromoParamsModel,
  IPromoUpdateData
} from 'entities/Promo/Promo.models';

const namespace = 'promo';

export interface IPromoConnectedProps {
  promoModel: StoreBranch<IPromoModel, IPromoParamsModel, IError>;
  promoCollection: StoreBranch<IPromoCollection, IPromoCollectionFilter>;

  getPromoModel(id: string): void;
  updatePromoModel(data: IPromoUpdateData): void;
  addPromoModel(data: IPromoCreateData): void;
  deletePromoModel(id: string): void;

  getPromoCollection(filter?: IPromoCollectionFilter): void;

  clearPromoModel(): void;
  clearPromoCollection(): void;
}

const PromoModelAPIProviders = [
  new APIProvider(EActionsTypes.get, promoTransport.get),
  new APIProvider(EActionsTypes.update, promoTransport.update, {
    onSuccess: function*() {
      message.success(EPromoSuccessMessage.Edit);
      yield put(push(ERoutesPrivate.Promo));
    },
    clearParams: true
  }),
  new APIProvider(EActionsTypes.delete, promoTransport.delete, {
    onSuccess: function*() {
      message.success(EPromoSuccessMessage.Delete);
      yield put(push(ERoutesPrivate.Promo));
    }
  }),
  new APIProvider(EActionsTypes.add, promoTransport.create, {
    onSuccess: function*() {
      message.success(EPromoSuccessMessage.Create);
      yield put(push(ERoutesPrivate.Promo));
    }
  })
];

const PromoCollectionAPIProviders = [new APIProvider(EActionsTypes.get, promoTransport.getCollection)];

const branches = [new Branch('model', PromoModelAPIProviders), new Branch('collection', PromoCollectionAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationPromo = buildCommunication<IPromoConnectedProps>(strategy);
