import { Col, Row, Skeleton } from 'antd';
import React from 'react';

export const CustomerFormSkeleton: React.FC = () => {
  return (
    <>
      <Row className="flex-noWrap mb-4" align="middle">
        <span className="fs-xxs font-family__default font-weight-7 mr-3" title="Email">
          Name:
        </span>
        <Col span={10}>
          <Skeleton.Input active className="width-full" />
        </Col>
      </Row>
      <Row className="flex-noWrap mb-4" align="middle">
        <span className="fs-xxs font-family__default font-weight-7 mr-3" title="Birthdate">
          Birthdate:
        </span>
        <Col span={9}>
          <Skeleton.Input active className="width-full" />
        </Col>
      </Row>

      <Row className="flex-noWrap mb-4" align="middle">
        <span className="fs-xxs font-family__default font-weight-7 mr-3" title="Email">
          Email:
        </span>
        <Col span={10}>
          <Skeleton.Input active className="width-full" />
        </Col>
      </Row>

      <Row className="flex-noWrap mb-4" align="middle">
        <span className="fs-xxs font-family__default font-weight-7 mr-3" title="Phone">
          Phone:
        </span>
        <Col span={10}>
          <Skeleton.Input active className="width-full" />
        </Col>
      </Row>
    </>
  );
};
