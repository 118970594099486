import React from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ECommonFormType } from 'common/models/commonModel';
import { EMenuPageTabKeys } from 'app/pages/Franchisor/MenuPage';
import { communicationCategory, ICategoryConnectedProps } from 'entities/Category/Category.communication';
import { CategoryFormSkeleton } from 'entities/Category/components/Skeleton/CategoryFormSkeleton';
import { CategoryForm } from 'entities/Category/components/Form/CategoryForm';

interface IParams {
  id?: string;
}

type AllProps = ICategoryConnectedProps & RouteComponentProps<IParams>;

class CategoryPageComponent extends React.PureComponent<AllProps> {
  componentDidMount(): void {
    const { getCategoryModel, match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      getCategoryModel(id);
    }
  }
  componentWillUnmount() {
    const { clearCategoryModel } = this.props;

    clearCategoryModel();
  }

  render() {
    const { categoryModel, match, updateCategoryModel, addCategoryModel } = this.props;
    const { params } = match;
    const { id } = params;
    const { data: categoryModelData, loading } = categoryModel;
    const categoryName = categoryModelData?.name;
    const isEdit = !!id;
    const typeForm = isEdit ? ECommonFormType.Update : ECommonFormType.Add;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
        <Col span={12}>
          <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
            <Breadcrumb.Item href={`${ERoutesPrivate.Menu}?tab=${EMenuPageTabKeys.Categories}`}>Category list</Breadcrumb.Item>
            <Breadcrumb.Item>
              {isEdit ? (
                !loading ? (
                  categoryName
                ) : (
                  <Skeleton.Input active style={{ width: '200px' }} size="small" />
                )
              ) : (
                'Add new category'
              )}
            </Breadcrumb.Item>
          </Breadcrumb>
          {!loading || !isEdit ? (
            <CategoryForm
              categoryModel={categoryModel}
              typeForm={typeForm}
              createCategory={addCategoryModel}
              updateCategory={updateCategoryModel}
              deleteCategory={this.deleteCategory}
            />
          ) : (
            <CategoryFormSkeleton typeForm={typeForm} />
          )}
        </Col>
      </CommonLayout>
    );
  }

  deleteCategory = () => {
    const { deleteCategoryModel, match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      deleteCategoryModel(id);
    }
  };
}

export const CategoryPage = communicationCategory.injector(CategoryPageComponent);
