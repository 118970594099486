import { EBaseOrderDirection } from 'common/models/requestModels';
import { AbstractSearchableSelector } from 'common/components/Input/AbstractSearchableSelector';
import { ICategoryCollectionFilter, ICategoryModel } from 'entities/Category/Category.models';
import { categoryTransport } from 'entities/Category/Category.transport';

interface IComponentProps {
  isAddOns?: boolean;
}

export class CategorySelector extends AbstractSearchableSelector<ICategoryModel, IComponentProps> {
  getItemLabel = (item: ICategoryModel) => {
    return item.name;
  };

  loadData = (value?: string) => {
    const { isAddOns } = this.props;
    const filterParams: ICategoryCollectionFilter = {
      name: value,
      orderField: 'name',
      orderDirection: EBaseOrderDirection.ASC,
      isDeleted: false
    };
    if (isAddOns !== undefined) {
      filterParams.isAddOns = isAddOns;
    }
    return categoryTransport.getCollection(filterParams);
  };
}
