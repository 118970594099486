import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import {
  IMenuItemCollection,
  IMenuItemCollectionFilter,
  IMenuItemCreateData,
  IMenuItemModel,
  IMenuItemsNotOnMenuParams,
  IMenuItemUpdateData
} from 'entities/MenuItem/MenuItem.models';

const basePath = '/menu-items';

class MenuItemTransport extends BaseHttpTransport<
  AxiosStatic,
  IMenuItemModel,
  IMenuItemCollection,
  IMenuItemCreateData,
  IMenuItemUpdateData,
  IMenuItemCollectionFilter
> {
  public deleteSoft = (id: string): Promise<void> => axios.delete(`${basePath}/${id}/soft`);
  public getNotOnMenuCollection = (params: IMenuItemsNotOnMenuParams): Promise<IMenuItemCollection> =>
    axios.get(`${basePath}/intersection`, { params });
}

export const menuItemTransport = new MenuItemTransport(basePath, axios);
