import { AbstractSearchableSelector } from 'common/components/Input/AbstractSearchableSelector';
import { EUserRole, IUserCollectionFilter, IUserModel } from 'entities/User/User.models';
import { userTransport } from 'entities/User/User.transport';

interface IComponentProps {
  role: EUserRole;
}

export class UserSelector extends AbstractSearchableSelector<IUserModel, IComponentProps> {
  getItemLabel = (item: IUserModel): string => {
    return `${item?.firstName} ${item?.lastName}`;
  };

  loadData = (value?: string): Promise<{ data: IUserModel[] }> => {
    const { role } = this.props;
    const filter: IUserCollectionFilter = {
      roles: [role]
    };
    if (value) {
      filter.search = value;
    }
    return userTransport.getCollection(filter);
  };
}
