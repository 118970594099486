import { Col, Row, Skeleton } from 'antd';
import React from 'react';
import { SkeletonFormItem } from 'common/components/Skeleton/SkeletonFormItem';

export const PromoCreateFormSkeleton: React.FC = () => {
  return (
    <>
      <span className="basic__text_title" title="General info">
        General info
      </span>
      <div>
        <Row gutter={[28, 10]}>
          <Col span={12}>
            <SkeletonFormItem label="Promo name">
              <Skeleton.Input active />
            </SkeletonFormItem>
          </Col>
          <Col span={12}>
            <SkeletonFormItem label="Promo type">
              <Skeleton.Input active />
            </SkeletonFormItem>
          </Col>
          <Col span={12}>
            <SkeletonFormItem label="Promo period">
              <Skeleton.Input active />
            </SkeletonFormItem>
          </Col>
        </Row>

        <Row align="middle">
          <span className="basic__text_title" title="Eligibility">
            Eligibility
          </span>
        </Row>

        <Row gutter={[28, 10]}>
          <Col span={12}>
            <SkeletonFormItem label="Store">
              <Skeleton.Input active />
            </SkeletonFormItem>
          </Col>
          <Col span={12}>
            <SkeletonFormItem label="Customer">
              <Skeleton.Input active />
            </SkeletonFormItem>
          </Col>
        </Row>

        <Row align="middle">
          <span className="basic__text_title" title="Redemption criteria">
            Redemption criteria
          </span>
        </Row>
        <Row gutter={[28, 10]}>
          <Col span={12}>
            <SkeletonFormItem label="Criterion">
              <Skeleton.Input active />
            </SkeletonFormItem>
          </Col>
        </Row>
        <Row align="middle">
          <span className="basic__text_title" title="Reward">
            Reward
          </span>
        </Row>
        <Row gutter={[28, 10]}>
          <Col span={12}>
            <SkeletonFormItem label="Reward option">
              <Skeleton.Input active />
            </SkeletonFormItem>
          </Col>
        </Row>

        <Col span={10}>
          <Row gutter={[28, 0]}>
            <Col span={12}>
              <SkeletonFormItem>
                <Skeleton.Button active />
              </SkeletonFormItem>
            </Col>
            <Col span={12}>
              <SkeletonFormItem>
                <Skeleton.Button active />
              </SkeletonFormItem>
            </Col>
          </Row>
        </Col>
      </div>
    </>
  );
};
