import React from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { communicationQR, IQRConnectedProps } from 'entities/QR/QR.communication';
import { QRForm } from 'entities/QR/components/Form/QRForm';

interface IParams {
  id?: string;
}

type AllProps = IQRConnectedProps & RouteComponentProps<IParams>;

class QRPageComponent extends React.PureComponent<AllProps> {
  componentDidMount(): void {
    const { getQrModel, match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      getQrModel(id);
    }
  }

  componentWillUnmount() {
    const { clearQrModel } = this.props;

    clearQrModel();
  }

  render() {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;

    const loading = false;
    const isEdit = !!id;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
        <Col span={12}>
          <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
            <Breadcrumb.Item href={`${ERoutesPrivate.QR}`}>QRs</Breadcrumb.Item>
            <Breadcrumb.Item>
              {isEdit ? !loading ? 'name' : <Skeleton.Input active style={{ width: '200px' }} size="small" /> : 'Create new QR'}
            </Breadcrumb.Item>
          </Breadcrumb>

          <QRForm qrId={id} />
        </Col>
      </CommonLayout>
    );
  }
}

export const QRPage = communicationQR.injector(QRPageComponent);
