import { IBasePathParams } from 'common/models/requestModels';
import { dateFormatter, timeFormatter } from 'common/helpers/date.helper';
import { DATE_FORMAT, DATE_FORMAT_WITH_TIME, TIME_FORMAT } from 'common/const/common.const';
import { IStoreModel } from 'entities/Store/Store.models';
import { IMenuItemModel } from 'entities/MenuItem/MenuItem.models';
import { ICategoryModel } from 'entities/Category/Category.models';

export interface IPromoModel extends Components.Schemas.PromoDto {}
export interface IPromoCollection extends Components.Schemas.PromoCollectionDto {}
export interface IPromoUpdateData extends Components.Schemas.UpdatePromoDto {
  id: string;
}
export interface IPromoCreateData extends Components.Schemas.CreatePromoDto {
  storesArrayValue?: IStoreModel[] | null;
  criteriaMenuItemsValue?: IMenuItemModel | null;
  bonusMenuItemValue?: IMenuItemModel | null;
  criteriaCategoriesValue?: ICategoryModel | null;
}

export interface IPromoCollectionFilter extends Paths.PromoControllerList.QueryParameters {
  pageIndex?: number;
}

export interface IPromoParamsModel extends IPromoCreateData, IPromoUpdateData, IBasePathParams {
  csvFile: File;
}

export enum EPromoCriteriaType {
  MenuItem = 'menuItem',
  Category = 'category',
  Price = 'price',
  EveryXPesos = 'everyPesos'
}
export enum EPromoCriteriaTypeText {
  MenuItem = 'Selected menu item',
  Category = 'Selected category',
  Price = 'Min order value',
  EveryXPesos = 'Every X pesos added to bag'
}
export enum EPromoCustomerSelectType {
  All = 'all',
  New = 'new',
  Specific = 'specific'
}
export enum EPromoCustomerSelectTypeText {
  All = 'All customers',
  New = 'New customers',
  Specific = 'Specific customers'
}

export enum EPromoBonusTypeText {
  DiscountAllPeso = 'Fixed peso discount',
  DiscountAllPercent = '% off discount',
  DiscountItem = 'Discount on item',
  Additional = 'Free menu item',
  AdditionalNotOnMenu = 'Free item not on menu'
}

export enum EPromoBonusType {
  DiscountAllPeso = 'discountFix',
  DiscountAllPercent = 'discountPercent',
  DiscountItem = 'discountMenuItem',
  Additional = 'additionalMenuItem',
  AdditionalNotOnMenu = 'additionalItemNotInMenu'
}

export enum EPromoType {
  Action = 'action',
  Code = 'code'
}
export enum EPromoTypeText {
  Action = 'Auto-apply',
  Code = 'Promo code'
}

export enum EPromoStatusText {
  Enable = 'Enabled',
  Disable = 'Disabled'
}

export enum EPromoPeriodText {
  specificDate = 'Specific date range',
  specificTime = 'Specific time range',
  specificDateAndTime = 'Specific date & time range'
}

export enum EPromoPeriod {
  specificDate = 'specificDate',
  specificTime = 'SpecificTime',
  specificDateAndTime = 'specificDateAndTime'
}

export enum EPromoSuccessMessage {
  Delete = 'Promo has been successfully deleted',
  Create = 'Promo has been successfully created',
  Edit = 'Promo has been successfully edited'
}

export enum EPromoCsvErrors {
  InvalidFormat = 'error.promoCsvSemanticException'
}

export const tablePromoConfig = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true
  },
  {
    title: 'Type',
    dataIndex: 'typeText',
    key: 'typeText',
    ellipsis: true,
    width: 110
  },
  {
    title: 'Promo status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true,
    width: 120
  },
  {
    title: 'Start date',
    dataIndex: 'startDate',
    key: 'startDate',
    ellipsis: true,
    width: 175,
    render: (value: string, record: IPromoModel) => dateFormatter(value, record.startTime ? DATE_FORMAT : DATE_FORMAT_WITH_TIME)
  },
  {
    title: 'End date',
    dataIndex: 'endDate',
    key: 'endDate',
    ellipsis: true,
    width: 175,
    render: (value: string, record: IPromoModel) => dateFormatter(value, record.endTime ? DATE_FORMAT : DATE_FORMAT_WITH_TIME)
  },
  {
    title: 'Start time',
    dataIndex: 'startTime',
    key: 'startTime',
    ellipsis: true,
    width: 110,
    render: (value: string) => {
      return value ? timeFormatter(value, TIME_FORMAT) : '-';
    }
  },
  {
    title: 'End time',
    dataIndex: 'endTime',
    key: 'endTime',
    ellipsis: true,
    width: 110,
    render: (value: string) => {
      return value ? timeFormatter(value, TIME_FORMAT) : '-';
    }
  },
  {
    title: 'Criterion',
    dataIndex: 'criteria',
    key: 'criteria',
    ellipsis: true,
    width: 150
  },
  {
    title: 'Reward option',
    dataIndex: 'bonusType',
    key: 'bonusType',
    ellipsis: true
  }
];
