import React from 'react';
import { Col, Row } from 'antd';
import { colLayoutSize, ELayoutSize } from 'common/const/common.const';

interface IComponentProps {
  typeLayout: ELayoutSize;
  classStyle?: string;
}

export const CommonLayout: React.FC<IComponentProps> = props => {
  const { children, classStyle, typeLayout } = props;
  const className = classStyle || '';

  return (
    <Row justify="center" className={`width-full ${className}`}>
      <Col span={colLayoutSize[typeLayout]}>{children}</Col>
    </Row>
  );
};
