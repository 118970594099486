import React, { useEffect } from 'react';
import { Button, Form, Input, Row } from 'antd';
import queryString from 'query-string';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useFormMapper } from '@axmit/antd4-helpers';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { ERoutesPublic } from 'common/models/routesModel';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { IAuthPasswordRestoreParams } from 'entities/Auth/Auth.models';

type AllProps = IAuthConnectedProps & RouteComponentProps;

const PasswordRestorePageComponent: React.FunctionComponent<AllProps> = props => {
  const { history, updateAuthPasswordRestore, authPasswordRestore, passwordRestoreAuthTokenCheck, authTokenCheck } = props;
  const { loading: tokenCheckLoading, errors: tokenCheckError } = authTokenCheck;
  const { loading, errors, params } = authPasswordRestore;
  const isTokenNotExpired = !tokenCheckLoading && !tokenCheckError;
  const token = (queryString.parse(history.location.search).token as string) || '';
  const { fields } = useFormMapper(['password'], null, params, errors);

  useEffect(() => {
    passwordRestoreAuthTokenCheck({ token });
  }, [passwordRestoreAuthTokenCheck, token]);

  const newPassword = (values: any) => {
    const { password } = values;
    const params: IAuthPasswordRestoreParams = {
      password,
      token
    };
    updateAuthPasswordRestore(params);
  };

  if (tokenCheckLoading) {
    return <LoadingSpin />;
  }

  return isTokenNotExpired ? (
    <>
      <Row justify="center" className="mb-4">
        <h1 className="basic__text_title fs-md" title="Create new password">
          Create new password
        </h1>
      </Row>
      <Form onFinish={newPassword} fields={fields}>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Password is required'
            },
            {
              min: 8,
              message: 'Password must be at least 8 characters'
            },
            {
              max: 16,
              message: 'Password must be shorter than or equal 16 characters'
            }
          ]}
          name="password"
          label="New password"
        >
          <Input.Password type="password" />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary" disabled={loading} loading={loading} title="Submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  ) : (
    <Row justify="center" align="middle">
      <span className="t-align-c fs-sm font-weight-5 font-family__default">The link to set a new password has expired.</span>
      <span className="t-align-c">
        Please click <Link to={ERoutesPublic.PasswordForgot}>here</Link> to request a new one.
      </span>
    </Row>
  );
};

export const PasswordRestorePage = communicationAuth.injector(withRouter(PasswordRestorePageComponent));
