import React, { useEffect } from 'react';
import { Button, Form, Input, Row } from 'antd';
import queryString from 'query-string';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useFormMapper } from '@axmit/antd4-helpers';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { IUserConfirmData } from 'entities/User/User.models';

type AllProps = IAuthConnectedProps & RouteComponentProps;

const SignUpConfirmPageComponent: React.FunctionComponent<AllProps> = props => {
  const { history, confirmAuthModel, inviteUserAuthTokenCheck, authTokenCheck, authModel } = props;
  const { loading: tokenCheckLoading, errors: tokenCheckError } = authTokenCheck;
  const { loading, errors, params } = authModel;
  const isTokenNotExpired = !tokenCheckLoading && !tokenCheckError;
  const token = (queryString.parse(history.location.search).token as string) || '';
  const { fields } = useFormMapper(['password'], null, params, errors);

  useEffect(() => {
    inviteUserAuthTokenCheck({ token });
  }, [inviteUserAuthTokenCheck, token]);

  const newPassword = (values: any) => {
    const { password } = values;
    const params: IUserConfirmData = {
      password,
      token
    };

    confirmAuthModel(params);
  };

  if (tokenCheckLoading) {
    return <LoadingSpin />;
  }

  return isTokenNotExpired ? (
    <>
      <Row justify="center" className="mb-4">
        <h1 className="basic__text_title fs-md t-align-c" title="Welcome to MB Go! Set up a password to complete registration">
          Welcome to MB Go!
          <br /> Set up a password to complete registration
        </h1>
      </Row>
      <Form onFinish={newPassword} fields={fields}>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Password is required'
            },
            {
              min: 8,
              message: 'Password must be at least 8 characters'
            },
            {
              max: 16,
              message: 'Password must be shorter than or equal 16 characters'
            }
          ]}
          name="password"
          label="Password"
        >
          <Input.Password type="password" />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" block type="primary" disabled={loading} loading={loading} title="Submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  ) : (
    <Row justify="center" align="middle">
      <span className="t-align-c fs-sm font-weight-5 font-family__default">The invitation link has expired.</span>
      <span className="t-align-c">
        Please request for another invitation to be resent by sending us a ticket on our Franchisee Care Helpdesk.
      </span>
    </Row>
  );
};

export const SignUpConfirmPage = communicationAuth.injector(withRouter(SignUpConfirmPageComponent));
