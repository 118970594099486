import { Col, Row, Skeleton } from 'antd';
import React from 'react';
import { SkeletonFormItem } from 'common/components/Skeleton/SkeletonFormItem';

export const RatingFormSkeleton: React.FC = () => {
  return (
    <Row gutter={[28, 10]}>
      <Col span={12}>
        <SkeletonFormItem label="Customer name">
          <Skeleton.Input active />
        </SkeletonFormItem>
        <SkeletonFormItem label="Feedback">
          <Skeleton.Input active />
        </SkeletonFormItem>
        <SkeletonFormItem label="Rating feedback">
          <Skeleton.Input active />
        </SkeletonFormItem>
        <SkeletonFormItem label="Posted">
          <Skeleton.Input active />
        </SkeletonFormItem>
        <SkeletonFormItem label="Last edit">
          <Skeleton.Input active />
        </SkeletonFormItem>
        <SkeletonFormItem label="Visibility">
          <Skeleton.Input active />
        </SkeletonFormItem>
      </Col>

      <Col span={12}>
        <SkeletonFormItem label="Store name">
          <Skeleton.Input active />
        </SkeletonFormItem>
        <SkeletonFormItem label="Store rating">
          <Skeleton.Input active />
        </SkeletonFormItem>
        <SkeletonFormItem label="Franchisee name">
          <Skeleton.Input active />
        </SkeletonFormItem>
        <SkeletonFormItem label="Store address">
          <Skeleton.Input active />
        </SkeletonFormItem>
      </Col>
    </Row>
  );
};
