import React from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ECommonFormType } from 'common/models/commonModel';
import { communicationPromo, IPromoConnectedProps } from 'entities/Promo/Promo.communication';
import { PromoCreateForm } from 'entities/Promo/components/Form/PromoCreateForm';
import { PromoEditForm } from 'entities/Promo/components/Form/PromoEditForm';
import { PromoFormSkeletonPage } from 'entities/Promo/components/Skeleton/PromoFormSkeletonPage';

interface IParams {
  id?: string;
}

type AllProps = IPromoConnectedProps & RouteComponentProps<IParams>;

class PromoPageComponent extends React.PureComponent<AllProps> {
  componentDidMount(): void {
    const { getPromoModel, match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      getPromoModel(id);
    }
  }

  componentWillUnmount() {
    const { clearPromoModel } = this.props;

    clearPromoModel();
  }

  render() {
    const { promoModel, match, addPromoModel, updatePromoModel, deletePromoModel } = this.props;
    const { params } = match;
    const { id } = params;
    const { data: promoModelData, loading } = promoModel;
    const promoName = promoModelData?.name;
    const isEdit = !!id;
    const typeForm = isEdit ? ECommonFormType.Update : ECommonFormType.Add;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
        <Col span={16}>
          <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
            <Breadcrumb.Item href={ERoutesPrivate.Promo}>Promo list</Breadcrumb.Item>
            <Breadcrumb.Item>
              {isEdit ? (
                !loading ? (
                  promoName
                ) : (
                  <Skeleton.Input active style={{ width: '200px' }} size="small" />
                )
              ) : (
                'Create new promo'
              )}
            </Breadcrumb.Item>
          </Breadcrumb>
          {!loading ? (
            isEdit ? (
              <PromoEditForm promoModel={promoModel} updatePromo={updatePromoModel} deletePromo={deletePromoModel} />
            ) : (
              <PromoCreateForm promoModel={promoModel} createPromo={addPromoModel} />
            )
          ) : (
            <PromoFormSkeletonPage typeForm={typeForm} />
          )}
        </Col>
      </CommonLayout>
    );
  }
}

export const PromoPage = communicationPromo.injector(PromoPageComponent);
