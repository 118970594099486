import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  buildCollectionPreRequestDataMapper,
  buildCollectionResponseFormatter
} from '@axmit/redux-communications';
import { orderTransport } from 'entities/Order/Order.transport';
import { IOrderCollection, IOrderCollectionFilter } from 'entities/Order/Order.models';

const namespace = 'order';

export interface IOrderConnectedProps {
  orderCollection: StoreBranch<IOrderCollection, IOrderCollectionFilter>;

  getOrderCollection(filter?: IOrderCollectionFilter): void;

  clearOrderCollection(): void;
}

const OrderCollectionAPIProviders = [
  new APIProvider(EActionsTypes.get, orderTransport.getCollection, {
    // todo fix any
    mapSuccess: buildCollectionResponseFormatter<any, any>(),
    preRequestDataMapper: buildCollectionPreRequestDataMapper<IOrderCollection, IOrderCollectionFilter>()
  })
];

const branches = [new Branch('collection', OrderCollectionAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationOrder = buildCommunication<IOrderConnectedProps>(strategy);
