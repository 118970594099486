import { Col, Row, Skeleton } from 'antd';
import React from 'react';
import { SkeletonFormItem } from 'common/components/Skeleton/SkeletonFormItem';

interface IComponentProps {
  isEdit: boolean;
}
export const AssetFormSkeleton: React.FC<IComponentProps> = (props: IComponentProps) => {
  const { isEdit } = props;

  return (
    <div className="mt-5">
      <SkeletonFormItem label="Asset name">
        <Skeleton.Input active />
      </SkeletonFormItem>
      {isEdit && (
        <SkeletonFormItem>
          <Skeleton.Input active />
        </SkeletonFormItem>
      )}
      <Row className="flex-noWrap" justify="space-between" align="middle">
        <Col span={6}>
          <Row className="flex-noWrap" justify="space-between">
            <Col span={10}>
              <SkeletonFormItem>
                <Skeleton.Button active />
              </SkeletonFormItem>
            </Col>
            <Col span={10}>
              <SkeletonFormItem>
                <Skeleton.Button active />
              </SkeletonFormItem>
            </Col>
          </Row>
        </Col>
        {isEdit && (
          <SkeletonFormItem>
            <Skeleton.Button active />
          </SkeletonFormItem>
        )}
      </Row>
    </div>
  );
};
