import { Col, Row, Skeleton } from 'antd';
import React from 'react';
import { SkeletonFormItem } from 'common/components/Skeleton/SkeletonFormItem';
import { ECommonFormType } from 'common/models/commonModel';

interface IComponentProps {
  typeForm: ECommonFormType;
}
export const StoreFormSkeleton: React.FC<IComponentProps> = (props: IComponentProps) => {
  const { typeForm } = props;
  const isCreateForm = typeForm === ECommonFormType.Add;

  return (
    <>
      <span className="basic__text_title" title="General info">
        General info
      </span>
      <div className="mt-5">
        <Row gutter={[28, 10]}>
          <Col span={12}>
            <SkeletonFormItem label="Email">
              <Skeleton.Input active />
            </SkeletonFormItem>
          </Col>
          <Col span={12}>
            <SkeletonFormItem label="New password">
              <Skeleton.Input active />
            </SkeletonFormItem>
          </Col>
          {!isCreateForm && (
            <Col span={12}>
              <SkeletonFormItem label="Store status">
                <Skeleton.Input active />
              </SkeletonFormItem>
            </Col>
          )}
          {isCreateForm && (
            <Col span={12}>
              <SkeletonFormItem label="Store code">
                <Skeleton.Input active />
              </SkeletonFormItem>
            </Col>
          )}
          <Col span={12}>
            <SkeletonFormItem label="Store name">
              <Skeleton.Input active />
            </SkeletonFormItem>
          </Col>

          <Col span={12}>
            <SkeletonFormItem label="Contact phone">
              <Skeleton.Input active />
            </SkeletonFormItem>
          </Col>
        </Row>
        <Col span={24}>
          <SkeletonFormItem label="Address">
            <div className="map__place-picker_skeleton" />
          </SkeletonFormItem>
        </Col>
        <Row className="mt-8 mb-5" align="middle">
          <span className="basic__text_title" title="Management">
            Management
          </span>
        </Row>
        <Row gutter={[28, 10]}>
          <Col span={12}>
            <SkeletonFormItem label="Franchisee">
              <Skeleton.Input active />
            </SkeletonFormItem>
          </Col>
        </Row>
        <Col span={24} className="mt-5 mb-5">
          <span className="basic__text_title" title="Menu asset">
            Menu asset
          </span>
        </Col>
        <Col span={12}>
          <SkeletonFormItem label="Please select asset">
            <Skeleton.Input active />
          </SkeletonFormItem>
        </Col>

        <Col span={24} className="mt-5 mb-5">
          <span className="basic__text_title" title="Working hours">
            Working hours
          </span>
        </Col>
        <Col span={12}>
          <SkeletonFormItem>
            <Skeleton.Input active />
          </SkeletonFormItem>
        </Col>

        <Col span={10}>
          <Row gutter={[28, 0]}>
            <Col span={12}>
              <SkeletonFormItem>
                <Skeleton.Button active />
              </SkeletonFormItem>
            </Col>
            <Col span={12}>
              <SkeletonFormItem>
                <Skeleton.Button active />
              </SkeletonFormItem>
            </Col>
          </Row>
        </Col>
      </div>
    </>
  );
};
