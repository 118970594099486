import React from 'react';
import { Select } from 'antd';

interface IComponentProps {
  value?: string;
  onChange?: (val: string) => void;
}

export const TimePickerCommon: React.FC<IComponentProps> = props => {
  const { value, onChange } = props;
  const hours = [];
  for (let i = 0; i < 24; i++) {
    const modulo = i % 12;
    const hour = modulo < 10 ? `0${modulo}` : modulo;
    const value = i < 10 ? `0${i}:00` : `${i}:00`;
    hours.push(
      <Select.Option key={value} value={value}>
        {i === 12 || i === 0 ? 12 : hour}:00 {i !== 0 ? (i / 11 > 1 ? 'PM' : 'AM') : 'AM'}
      </Select.Option>
    );
  }

  return (
    <Select value={value} onChange={onChange}>
      {hours}
    </Select>
  );
};
