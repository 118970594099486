type TErrorMapper = (
  error: any,
  popupConfig?: IPopupConfig,
  codesConfig?: ICodesConfig,
  translate?: Function
) => Promise<IError | null>;

export const errorMapper: TErrorMapper = (error, ...rest) => {
  if (!error) {
    return Promise.reject(error);
  }

  if (!error.response) {
    return Promise.reject({ ...error, status: error.code });
  }

  const { status, data } = error.response;
  const shortError = { status, data };

  return Promise.reject(mapErrors(shortError, ...rest));
};

export const mapErrors = (
  error: IAxiosError,
  popupConfig?: IPopupConfig,
  codesConfig?: ICodesConfig,
  translate?: Function
): IError => {
  const { data, status } = error;

  const withPopupMessage = codesConfig?.withPopupMessage || defaultCodesWithPopupMessage;
  const withValidations = codesConfig?.withValidations || defaultCodesWithValidations;

  if (withValidations.includes(status)) {
    return {
      status,
      data,
      validation: mapValidationData(data, translate),
      validationCommon: mapValidationCommon(data, translate)
    };
  }

  console.error('data', data);

  if (popupConfig && withPopupMessage.includes(status)) {
    showPopupError(
      {
        showError: popupConfig.showError,
        defaultError: data.message || data.error || popupConfig.defaultError
      },
      translate
    );
  }

  return error;
};

const mapValidationCommon = (data: IErrorData, translate?: Function): string | undefined => {
  const fieldsErrorsIsEmpty = data.errors && Object.keys(data.errors).length === 0;

  return !data.errors || fieldsErrorsIsEmpty ? translateErrors(data, translate) : undefined;
};

const mapValidationData = (data: IErrorData, translate?: Function): IValidationModel | undefined => {
  const fieldsErrorsIsEmpty = data.errors && Object.keys(data.errors).length === 0;

  if (!data.errors || fieldsErrorsIsEmpty) {
    return undefined;
  }

  const result: IValidationModel = {};

  for (const [key, value] of Object.entries(data.errors)) {
    const keyWithoutFirstDot: string = key.replace(/^\./, '');

    result[keyWithoutFirstDot] = value.map(item => translateErrors(item, translate));
  }

  if (Object.keys(result).length === 0) {
    return { [DEFAULT_VALIDATION_FIELD]: translateErrors(data, translate) };
  }

  return result;
};

const translateErrors = (data: IErrorData | IValidationError, t?: Function) => {
  const { code, message } = data;

  return t ? t([code, message]) : message;
};

const showPopupError = (popupConfig: IPopupConfig, translate?: Function) => {
  const { showError, defaultError } = popupConfig;

  return translate ? showError(translate(defaultError)) : showError(defaultError);
};

export enum EErrorStatus {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  PayloadTooLarge = 413,
  Validation = 422,
  FailedDependency = 424,
  TooManyRequests = 429,
  RetryWith = 449,
  InternalServerError = 500
}

export const defaultCodesWithValidations: number[] | EErrorStatus[] = [EErrorStatus.Validation];
export const defaultCodesWithPopupMessage: number[] | EErrorStatus[] = [
  EErrorStatus.BadRequest,
  EErrorStatus.Forbidden,
  EErrorStatus.Conflict,
  EErrorStatus.PayloadTooLarge,
  EErrorStatus.FailedDependency,
  EErrorStatus.TooManyRequests,
  EErrorStatus.RetryWith,
  EErrorStatus.InternalServerError
];
export const DEFAULT_VALIDATION_FIELD = '_base';

export interface IAxiosError {
  status: number;
  data: IErrorData;
}

export interface IError {
  status: number;
  data: IErrorData;
  validation?: IValidationModel;
  validationCommon?: string;
}

interface IErrorData {
  message: string;
  statusCode: number;
  code: string;
  error?: string;
  errors?: {
    [key: string]: IValidationError[];
  };
}

interface IValidationError {
  message: string;
  code: string;
  params?: {
    [key: string]: any;
  };
}

interface IPopupConfig {
  showError: Function;
  defaultError: string;
}

interface ICodesConfig {
  withValidations?: number[] | EErrorStatus[];
  withPopupMessage?: number[] | EErrorStatus[];
}

interface IValidationModel {
  [fieldName: string]: string[];
}
