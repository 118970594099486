import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { EBaseOrderDirection, IBaseFilterModel } from 'common/models/requestModels';
import { ERoutesPrivate } from 'common/models/routesModel';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { nameBuilder } from 'common/helpers/name.helper';
import { ERatingStatusText, IRatingCollection, IRatingCollectionFilter, IRatingModel } from 'entities/Rating/Rating.models';
import { communicationRating, IRatingConnectedProps } from 'entities/Rating/Rating.communication';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & IRatingConnectedProps & RouteComponentProps;

class RatingTableComponent extends TableCommon<
  IRatingCollection,
  IRatingModel,
  ColumnsType<any>,
  AllProps,
  IRatingCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getRatingCollection, filter } = this.props;
    const filterParams: IRatingCollectionFilter = { ...params, orderDirection: EBaseOrderDirection.ASC };

    if (filter?.storeSearch) {
      filterParams.storeSearch = filter?.storeSearch;
    }
    if (filter?.isEnable !== undefined) {
      filterParams.isEnable = filter?.isEnable;
    }
    if (filter?.franchisee) {
      filterParams.franchisee = filter?.franchisee;
    }
    getRatingCollection(filterParams);
  };

  getCollection = () => {
    const { ratingCollection } = this.props;

    if (ratingCollection?.data?.data) {
      const data: IRatingModel[] = ratingCollection?.data?.data.map(item => {
        return {
          ...item,
          storeCode: item?.store?.storeCode,
          storeName: item?.store?.storeName,
          franchisee: nameBuilder(item?.store?.franchisee?.firstName, item?.store?.franchisee?.lastName),
          customerName: nameBuilder(item.customer?.firstName, item.customer?.lastName),
          rating: item?.rate,
          feedback: item?.feedback || 'No feedback',
          visibility: item?.isEnable ? ERatingStatusText.Visible : ERatingStatusText.NotVisible
        };
      });
      return {
        ...ratingCollection,
        data: {
          ...ratingCollection.data,
          data
        }
      };
    } else {
      return ratingCollection;
    }
  };

  addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<IRatingCollectionFilter>({
      storeSearch: undefined,
      franchiseeValue: undefined,
      isEnable: undefined,
      pageIndex: 1
    });
    const search = objectToQuery({ ...searchPath, pageIndex });
    return this.props.history.replace({ search });
  };

  onRowClick = (item: IRatingModel) => {
    const { history } = this.props;
    history.push(`${ERoutesPrivate.Rating}/${item.id}`);
  };
  clearCollection = () => {
    return this.props.clearRatingCollection();
  };
}

export const RatingTable = communicationRating.injector(withRouter(RatingTableComponent));
