import React from 'react';
import { Row } from 'antd';
import { EBaseOrderDirection, IBasePathParams } from 'common/models/requestModels';
import { ReactComponent as StarIcon } from 'app/assets/icons/star.svg';
import { IUserModel } from 'entities/User/User.models';

export interface IRatingCollection extends Components.Schemas.RatingCollectionDto {}
export interface IRatingCollectionFilter extends Paths.RatingControllerList.QueryParameters {
  pageIndex?: number;
  franchiseeValue?: Partial<IUserModel>;
  order?: EBaseOrderDirection;
  dateFrom?: any;
  dateTo?: any;
}
export interface IRatingModel extends Components.Schemas.RatingDto {}
export interface IRatingCreateData extends Components.Schemas.CreateRatingDto {}
export interface IRatingUpdateData extends Components.Schemas.UpdateRatingDto {
  id: string;
}
export interface IRatingParamsModel extends IRatingCreateData, IRatingUpdateData, IBasePathParams {}

export enum ERatingSuccessMessage {
  Delete = 'Rating has been successfully deleted',
  Add = 'Rating has been successfully added',
  Edit = 'Rating has been successfully edited'
}

export enum ERatingStatus {
  Visible = 'visible',
  NotVisible = 'notVisible'
}
export enum ERatingStatusText {
  Visible = 'Visible',
  NotVisible = 'Not visible'
}

export enum EStoreFeedbackOrder {
  LowestTop = 'Lowest on top',
  HighestTop = 'Highest on top'
}

export const ratingTableConfig = [
  {
    title: 'Store Code',
    dataIndex: 'storeCode',
    key: 'storeCode',
    ellipsis: true
  },
  {
    title: 'Store name',
    dataIndex: 'storeName',
    key: 'storeName',
    ellipsis: true
  },
  {
    title: 'Franchisee',
    dataIndex: 'franchisee',
    key: 'franchisee',
    ellipsis: true
  },
  {
    title: 'Customer name',
    dataIndex: 'customerName',
    key: 'customerName',
    ellipsis: true
  },
  {
    title: (
      <Row align="middle" justify="center" title="Rating">
        Rating
      </Row>
    ),
    dataIndex: 'rating',
    key: 'rating',
    ellipsis: true,
    width: '100px',

    render: (item: string) => (
      <Row align="middle" justify="center" title={item}>
        <StarIcon /> <div className="ml-2">{item}</div>
      </Row>
    )
  },
  {
    title: 'Feedback',
    dataIndex: 'feedback',
    key: 'feedback',
    ellipsis: true,
    width: '300px'
  },
  {
    title: 'Visibility',
    dataIndex: 'visibility',
    key: 'visibility',
    ellipsis: true
  }
];
