import React from 'react';
import { Col, Row } from 'antd';
import { EUserRole } from 'entities/User/User.models';

interface IComponentProps {
  id?: string;
  layoutType: EUserRole.Franchisee | EUserRole.Franchisor | EUserRole.SalesOps;
}

export const BasicLayout: React.FC<IComponentProps> = props => {
  const { id, children, layoutType } = props;
  const className = layoutType === EUserRole.Franchisor ? `basic__layout_franchisor` : `basic__layout_franchisee`;

  return (
    <Row id={id} className={className}>
      <Col span={24}>{children}</Col>
    </Row>
  );
};
