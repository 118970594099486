import React, { useEffect } from 'react';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { OrderCard } from 'entities/Statistic/components/Card/OrderCard';
import { communicationStatistic, IStatisticConnectedProps } from 'entities/Statistic/Statistic.communication';
import { StatsCard } from 'entities/Statistic/components/Card/StatsCard';

interface IComponentProps {
  isSalesOps?: boolean;
  franchiseeId?: string;
  storeId?: string;
  dateFrom?: string;
  dateTo?: string;
}
type AllProps = IStatisticConnectedProps & IComponentProps;

const StatisticPageComponent: React.FC<AllProps> = props => {
  const {
    getStatisticModel,
    getStatisticGlobalModel,
    getStatisticSalesOpsModel,
    statisticModel,
    statisticGlobalModel,
    statisticSalesOpsModel,
    storeId,
    franchiseeId,
    dateFrom,
    dateTo,
    isSalesOps
  } = props;
  const globalData = isSalesOps ? statisticSalesOpsModel.data : statisticGlobalModel.data;
  const storeData = statisticModel.data;
  const statisticData = isSalesOps ? globalData : storeId ? { ...storeData } : { ...globalData };

  useEffect(() => {
    if (isSalesOps) {
      getStatisticSalesOpsModel({ storeId, franchiseeId, dateFrom, dateTo });
    } else {
      storeId ? getStatisticModel({ id: storeId, dateFrom, dateTo }) : getStatisticGlobalModel({ dateFrom, dateTo });
    }
  }, [storeId, franchiseeId, dateFrom, dateTo, getStatisticModel, getStatisticGlobalModel]);

  return statisticData ? (
    <>
      <StatsCard item={statisticData} />
      <OrderCard item={statisticData} />
    </>
  ) : (
    <LoadingSpin />
  );
};

export const StatisticPage = communicationStatistic.injector(StatisticPageComponent);
