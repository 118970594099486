import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { ERoutesPrivate } from 'common/models/routesModel';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { EMenuPageTabKeys, IMenuPageState } from 'app/pages/Franchisor/MenuPage';
import { IMenuItemCollection, IMenuItemCollectionFilter, IMenuItemModel } from 'entities/MenuItem/MenuItem.models';
import { communicationMenuItem, IMenuItemConnectedProps } from 'entities/MenuItem/MenuItem.communication';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & IMenuItemConnectedProps & RouteComponentProps;

class MenuItemTableComponent extends TableCommon<
  IMenuItemCollection,
  IMenuItemModel,
  ColumnsType<any>,
  AllProps,
  IMenuItemCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getMenuItemCollection } = this.props;

    getMenuItemCollection({ ...params, isDeleted: false });
  };

  getCollection = () => {
    const { menuItemCollection } = this.props;
    if (menuItemCollection?.data?.data) {
      const data: IMenuItemModel[] = menuItemCollection.data.data.map(item => {
        return {
          ...item,
          categoryName: item?.categoryData?.name
        };
      });
      return {
        ...menuItemCollection,
        data: {
          ...menuItemCollection.data,
          data
        }
      };
    } else {
      return menuItemCollection;
    }
  };

  addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<IMenuPageState>({
      tab: EMenuPageTabKeys.Categories,
      pageIndex: 1
    });
    const search = objectToQuery({ ...searchPath, pageIndex });
    return this.props.history.replace({ search });
  };

  onRowClick = (item: IMenuItemModel) => {
    const { history } = this.props;
    history.push(`${ERoutesPrivate.MenuItem}/${item.id}`);
  };
  clearCollection = () => {
    return this.props.clearMenuItemCollection();
  };
}

export const MenuItemTable = communicationMenuItem.injector(withRouter(MenuItemTableComponent));
