import React from 'react';
import { Button, Col, Tabs } from 'antd';
import { Link } from 'react-router-dom';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { AssetTable } from 'entities/Asset/components/AssetTable';
import { assetTableConfig } from 'entities/Asset/Asset.models';
import { CategoryTable } from 'entities/Category/components/CategoryTable';
import { categoryTableConfig } from 'entities/Category/Category.models';
import { MenuItemTable } from 'entities/MenuItem/components/MenuItemTable';
import { menuItemTableConfig } from 'entities/MenuItem/MenuItem.models';

export enum EMenuPageTabKeys {
  Categories = 'categories',
  Items = 'items',
  Assets = 'assets'
}
enum EMenuPageTabName {
  Categories = 'Categories',
  Items = 'Items',
  Assets = 'Assets'
}

export interface IMenuPageState {
  tab?: EMenuPageTabKeys;
  pageIndex?: number;
}
interface IComponentState {
  pageState: IMenuPageState;
}

class MenuPageComponent extends React.PureComponent<any, IComponentState> {
  state: IComponentState = {
    pageState: {
      tab: EMenuPageTabKeys.Categories,
      pageIndex: 1
    }
  };
  constructor(props: any) {
    super(props);
    const parsedQuery = queryToObject<IMenuPageState>(this.state.pageState) as IMenuPageState;
    this.state.pageState = { ...this.state.pageState, pageIndex: parsedQuery.pageIndex, tab: this.selectTab(parsedQuery?.tab) };
  }

  render() {
    const { pageState } = this.state;
    const isCategories = pageState.tab === EMenuPageTabKeys.Categories;
    const isItems = pageState.tab === EMenuPageTabKeys.Items;
    const isAssets = pageState.tab === EMenuPageTabKeys.Assets;
    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Tabs onChange={this.onTabChange} type="card" activeKey={pageState.tab}>
          <Tabs.TabPane tab={EMenuPageTabName.Categories} key={EMenuPageTabKeys.Categories}>
            {isCategories && (
              <>
                <Col span={24} className="mt-4 mb-8">
                  <Link to={ERoutesPrivate.CategoryCreate}>
                    <Button type="primary" title="Add category">
                      Add category
                    </Button>
                  </Link>
                </Col>
                <Col span={12}>
                  <CategoryTable config={categoryTableConfig} />
                </Col>
              </>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab={EMenuPageTabName.Items} key={EMenuPageTabKeys.Items}>
            {isItems && (
              <>
                <Col span={24} className="mt-4 mb-8">
                  <Link to={ERoutesPrivate.MenuItemCreate}>
                    <Button type="primary" title="New item">
                      New item
                    </Button>
                  </Link>
                </Col>
                <Col span={24}>
                  <MenuItemTable config={menuItemTableConfig} />
                </Col>
              </>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab={EMenuPageTabName.Assets} key={EMenuPageTabKeys.Assets}>
            {isAssets && (
              <>
                <Col span={24} className="mt-4 mb-8">
                  <Link to={ERoutesPrivate.AssetCreate}>
                    <Button type="primary" title="New asset">
                      New asset
                    </Button>
                  </Link>
                </Col>
                <Col span={12}>
                  <AssetTable config={assetTableConfig} />
                </Col>
              </>
            )}
          </Tabs.TabPane>
        </Tabs>
      </CommonLayout>
    );
  }
  onTabChange = (tab: string) => {
    this.setState({ pageState: { pageIndex: 1, tab: this.selectTab(tab as EMenuPageTabKeys) } });
    this.props.history.replace({ search: objectToQuery({ pageIndex: 1, tab: this.selectTab(tab as EMenuPageTabKeys) }) });
  };

  selectTab = (parsedQueryTab?: EMenuPageTabKeys) => {
    switch (parsedQueryTab) {
      case EMenuPageTabKeys.Categories:
        return EMenuPageTabKeys.Categories;

      case EMenuPageTabKeys.Items:
        return EMenuPageTabKeys.Items;

      case EMenuPageTabKeys.Assets:
        return EMenuPageTabKeys.Assets;

      default:
        return EMenuPageTabKeys.Categories;
    }
  };
}

export const MenuPage = MenuPageComponent;
