import React from 'react';
import { Image, Typography } from 'antd';
import { IBasePathParams } from 'common/models/requestModels';

export interface IMenuItemCollection extends Components.Schemas.MenuItemCollectionDto {}
export interface IMenuItemCollectionFilter extends Paths.MenuItemControllerGetList.QueryParameters {}
export interface IMenuItemModel extends Components.Schemas.MenuItemDto {}
export interface IMenuItemCreateData extends Components.Schemas.MenuItemCreateDto {}
export interface IMenuItemUpdateData extends Components.Schemas.MenuItemUpdateDto {
  id: string;
}
export interface IMenuItemParamsModel extends IMenuItemCreateData, IMenuItemUpdateData, IBasePathParams {}
export interface IMenuItemsNotOnMenuParams extends Paths.MenuItemControllerIntersection.QueryParameters {}

export enum EMenuItemSuccessMessage {
  Delete = 'Item has been successfully deleted',
  Add = 'Item has been successfully created',
  Edit = 'Item has been successfully edited'
}

export const menuItemTableConfig = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (item: string) => (
      <Typography.Paragraph ellipsis={{ rows: 3 }} title={item}>
        {item}
      </Typography.Paragraph>
    )
  },
  {
    title: 'Category',
    dataIndex: 'categoryName',
    key: 'categoryName',
    ellipsis: true
  },
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    ellipsis: true,
    width: '120px',
    render: (imageId: string) => (imageId ? <Image src={`/api/images/${imageId}`} /> : null)
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: (item: string) =>
      item ? (
        <Typography.Paragraph ellipsis={{ rows: 3 }} title={item}>
          {item}
        </Typography.Paragraph>
      ) : null
  }
];
