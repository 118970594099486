import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EPromoCriteriaType, EPromoCriteriaTypeText } from 'entities/Promo/Promo.models';

interface IComponentProps {
  onChange?: (value: EPromoCriteriaType) => void;
  placeholder?: string;
  allowClear?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class PromoCriteriaTypeSelector extends React.PureComponent<AllProps> {
  handleChange = (value: EPromoCriteriaType) => {
    const { onChange } = this.props;
    onChange && onChange(value);
  };

  render() {
    const { value, defaultValue, placeholder, allowClear } = this.props;

    return (
      <Select
        className="width-full"
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        showArrow
        allowClear={allowClear}
        onChange={(value: EPromoCriteriaType) => this.handleChange(value)}
      >
        <Select.Option
          key={EPromoCriteriaType.MenuItem}
          value={EPromoCriteriaType.MenuItem}
          label={EPromoCriteriaTypeText.MenuItem}
        >
          {EPromoCriteriaTypeText.MenuItem}
        </Select.Option>
        <Select.Option
          key={EPromoCriteriaType.Category}
          value={EPromoCriteriaType.Category}
          label={EPromoCriteriaTypeText.Category}
        >
          {EPromoCriteriaTypeText.Category}
        </Select.Option>
        <Select.Option key={EPromoCriteriaType.Price} value={EPromoCriteriaType.Price} label={EPromoCriteriaTypeText.Price}>
          {EPromoCriteriaTypeText.Price}
        </Select.Option>
        <Select.Option
          key={EPromoCriteriaType.EveryXPesos}
          value={EPromoCriteriaType.EveryXPesos}
          label={EPromoCriteriaTypeText.EveryXPesos}
        >
          {EPromoCriteriaTypeText.EveryXPesos}
        </Select.Option>
      </Select>
    );
  }
}
