import React, { ChangeEvent } from 'react';
import { Col, Input, Row } from 'antd';
import debounce from 'lodash.debounce';
import { RouteComponentProps, withRouter } from 'react-router';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { RatingTable } from 'entities/Rating/components/RatingTable';
import { ERatingStatus, IRatingCollectionFilter, ratingTableConfig } from 'entities/Rating/Rating.models';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { UserSelector } from 'entities/User/components/Selector/UserSelector';
import { EUserRole, IUserModel } from 'entities/User/User.models';
import { RatingStatusSelector } from 'entities/Rating/components/RatingStatusSelector';

type AllProps = IUIConnectedProps & RouteComponentProps;

interface IComponentState {
  filter: IRatingCollectionFilter;
}

class RatingPageComponent extends React.PureComponent<AllProps, IComponentState> {
  public debounceSearch: any;
  state: IComponentState = {
    filter: {
      pageIndex: 1,
      storeSearch: undefined,
      franchiseeValue: undefined,
      isEnable: undefined
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<IRatingCollectionFilter>(this.state.filter) };
    this.debounceSearch = debounce(this.autocompleteSearch, 300);
  }

  render() {
    const { filter } = this.state;
    const { storeSearch, isEnable, franchiseeValue } = filter;
    const statusValue = isEnable !== undefined ? (isEnable ? ERatingStatus.Visible : ERatingStatus.NotVisible) : undefined;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Row className="mb-10 width-full" justify="space-between" align="middle">
          <Col span={18}>
            <Row justify="space-between" align="middle">
              <Col span={8}>
                <Input.Search
                  placeholder="Search by store name / code"
                  defaultValue={storeSearch}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => this.debounceSearch(e.target.value)}
                />
              </Col>
              <Col span={6}>
                <Row className="flex-noWrap" justify="space-between" align="middle">
                  <span className="mr-4">Franchisee</span>
                  <UserSelector
                    onChange={this.onChangeSelector}
                    value={franchiseeValue}
                    placeholder="Enter franchisee name"
                    role={EUserRole.Franchisee}
                  />
                </Row>
              </Col>
              <Col span={6}>
                <RatingStatusSelector onChangeStatus={this.onChangeStatus} value={statusValue} />
              </Col>
            </Row>
          </Col>
        </Row>
        <RatingTable config={ratingTableConfig} filter={{ ...filter, franchisee: franchiseeValue?.id }} />
      </CommonLayout>
    );
  }

  setFilter = (partialFilter: Partial<IRatingCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };
    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  autocompleteSearch = (text: string) => {
    const storeSearch = text === '' ? undefined : text;
    this.setFilter({ storeSearch });
  };

  onChangeSelector = (item?: IUserModel) => {
    const franchiseeValue = item ? { id: item?.id, lastName: item?.lastName, firstName: item?.firstName } : undefined;

    this.setFilter({ franchiseeValue });
  };

  onChangeStatus = (status?: ERatingStatus) => {
    const isEnable = status !== undefined ? status === ERatingStatus.Visible : undefined;
    this.setFilter({ isEnable });
  };
}

export const RatingTablePage = communicationUI.injector(withRouter(RatingPageComponent));
