import React from 'react';
import { Col, Row, Skeleton } from 'antd';
import { SkeletonFormItem } from 'common/components/Skeleton/SkeletonFormItem';

export const UserFormSkeleton: React.FC = () => {
  return (
    <div>
      <Row className="mb-5" align="middle">
        <span className="basic__text_title" title="Personal info">
          Personal info
        </span>
      </Row>
      <Row gutter={[28, 10]}>
        <Col span={12}>
          <SkeletonFormItem label="First name">
            <Skeleton.Input active />
          </SkeletonFormItem>
        </Col>
        <Col span={12}>
          <SkeletonFormItem label="Last name">
            <Skeleton.Input active />
          </SkeletonFormItem>
        </Col>
        <Col span={12}>
          <SkeletonFormItem label="Phone number">
            <Skeleton.Input active />
          </SkeletonFormItem>
        </Col>
      </Row>
      <Row className="mb-8" gutter={[28, 0]}>
        <Col className="pb-2" span={24}>
          <span className="fs-xxs font-family__default" title="Email">
            Email
          </span>
        </Col>
        <Col span={12}>
          <SkeletonFormItem>
            <Skeleton.Input active />
          </SkeletonFormItem>
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col span={16} className="mb-0">
          <Skeleton.Button className="mr-8" active />
          <Skeleton.Button active />
        </Col>
        <Skeleton.Button active />
      </Row>
    </div>
  );
};
