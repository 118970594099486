import React from 'react';
import { Col, Row, DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { RouteComponentProps, withRouter } from 'react-router';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { DATE_FORMAT } from 'common/const/common.const';
import { FranchiseeCenterLayout } from 'common/components/Layouts/FranchiseeCenterLayout';
import { rangeConfig } from 'common/helpers/date.helper';
import { IStoreModel, selectorStoreAllKeyId } from 'entities/Store/Store.models';
import { StoreSelector } from 'entities/Store/components/Selector/StoreSelector';
import { StatisticPage } from 'entities/Statistic/components/StatisticPage';
import { EUserRole, IUserModel } from 'entities/User/User.models';
import { UserSelector } from 'entities/User/components/Selector/UserSelector';

interface IFilter {
  franchisee?: string;
  store?: Partial<IStoreModel>;
  dateFrom?: string;
  dateTo?: string;
}

type AllProps = RouteComponentProps;

interface IComponentState {
  filter: IFilter;
}

class StatisticSalesOpsPageComponent extends React.PureComponent<AllProps, IComponentState> {
  state: IComponentState = {
    filter: {
      franchisee: undefined,
      store: undefined,
      dateFrom: undefined,
      dateTo: undefined
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<IFilter>(this.state.filter) };
  }

  render() {
    const { filter } = this.state;
    const { franchisee: franchiseeId, store, dateFrom, dateTo } = filter;
    const defaultDateValue: [Moment, Moment] | undefined = dateFrom && dateTo ? [moment(dateFrom), moment(dateTo)] : undefined;

    return (
      <FranchiseeCenterLayout>
        <Row justify="center" align="middle">
          <span className="franchisee__text_title" title="Statistics">
            Statistics
          </span>
        </Row>
        <Col span={24} className="mt-5 mb-5">
          <div className="fs-xxs font-family__default mb-3">Franchisee</div>
          <UserSelector
            placeholder="Enter franchisee name"
            role={EUserRole.Franchisee}
            disabled={false}
            onChange={this.onChangeFranchisee}
          />
        </Col>
        <Col span={24} className="mt-5 mb-5">
          <div className="fs-xxs font-family__default mb-3">Store</div>
          <StoreSelector
            onChange={this.onChangeStore}
            value={store}
            franchiseeId={franchiseeId}
            defaultOpt={{ id: selectorStoreAllKeyId }}
            placeholder="Enter store name"
            allowClear={false}
          />
        </Col>
        <Col span={24}>
          <div className="fs-xxs font-family__default mb-3">Time range</div>
          <DatePicker.RangePicker
            className="width-full franchisee__date-picker"
            defaultValue={defaultDateValue}
            ranges={rangeConfig}
            disabledDate={current => current && current > moment().endOf('day')}
            getPopupContainer={triggerNode => triggerNode as HTMLElement}
            format={DATE_FORMAT}
            onChange={this.onChangeDate}
            showTime
          />
        </Col>
        <StatisticPage dateFrom={dateFrom} dateTo={dateTo} storeId={store?.id} franchiseeId={franchiseeId} isSalesOps />
      </FranchiseeCenterLayout>
    );
  }

  onChangeFranchisee = (item?: IUserModel) => {
    this.setFilter({ franchisee: item?.id, store: undefined });
  };

  onChangeStore = (item?: IStoreModel) => {
    const isAll = item?.id === selectorStoreAllKeyId;
    const store = item && !isAll ? { id: item?.id, storeName: item?.storeName } : undefined;

    this.setFilter({ store });
  };

  setFilter = (partialFilter: Partial<IFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  onChangeDate = (value: any[] | null) => {
    if (value?.length) {
      const dateFrom = moment(value[0]).format();
      const dateTo = moment(value[1]).format();
      this.setFilter({ dateFrom, dateTo });
    } else {
      this.setFilter({ dateFrom: undefined, dateTo: undefined });
    }
  };
}

export const StatisticSalesOpsPage = withRouter(StatisticSalesOpsPageComponent);
