import React from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { nameBuilder } from 'common/helpers/name.helper';
import { communicationCustomer, ICustomerConnectedProps } from 'entities/Customer/Customer.communication';
import { CustomerForm } from 'entities/Customer/components/Form/CustomerForm';
import { CustomerFormSkeleton } from 'entities/Customer/components/Skeleton/CustomerFormSkeleton';

interface IParams {
  id?: string;
}

type AllProps = ICustomerConnectedProps & RouteComponentProps<IParams>;

class CustomerPageComponent extends React.PureComponent<AllProps> {
  componentDidMount(): void {
    const { getCustomerModel, match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      getCustomerModel(id);
    }
  }
  componentWillUnmount() {
    const { clearCustomerModel } = this.props;

    clearCustomerModel();
  }

  render() {
    const { customerModel, updateStatusCustomerModel } = this.props;
    const { data: customerModelData, loading } = customerModel;
    const customerName = nameBuilder(customerModelData?.firstName, customerModelData?.lastName);
    const status = customerModelData?.status;
    const title = (
      <>
        {customerName} (<span className="t-transform-c">{status}</span>)
      </>
    );

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
        <Col span={12}>
          <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
            <Breadcrumb.Item href={ERoutesPrivate.Customers}>Customer list</Breadcrumb.Item>
            <Breadcrumb.Item>
              {!loading ? title : <Skeleton.Input active style={{ width: '200px' }} size="small" />}
            </Breadcrumb.Item>
          </Breadcrumb>
          {!loading ? (
            <CustomerForm customerModel={customerModel} updateCustomer={updateStatusCustomerModel} />
          ) : (
            <CustomerFormSkeleton />
          )}
        </Col>
      </CommonLayout>
    );
  }
}

export const CustomerPage = communicationCustomer.injector(CustomerPageComponent);
