import React, { ReactNode } from 'react';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { EUserRole } from 'entities/User/User.models';

interface IComponentProps {
  franchisorRoutes: ReactNode;
  franchiseeRoutes: ReactNode;
  salesOpsRoutes: ReactNode;
}
type AllProps = IAuthConnectedProps & IComponentProps;

class RoleCheckLayoutComponent extends React.Component<AllProps> {
  render() {
    const { authUser } = this.props;
    const { loading: authUserLoading } = authUser;

    const routes = this.getRoutesByRole();

    return authUserLoading ? <LoadingSpin /> : routes;
  }

  getRoutesByRole = () => {
    const { authUser, franchisorRoutes, franchiseeRoutes, salesOpsRoutes } = this.props;

    switch (authUser.data?.role) {
      case EUserRole.Franchisee:
        return franchiseeRoutes;
      case EUserRole.SalesOps:
        return salesOpsRoutes;
      case EUserRole.Franchisor:
      default:
        return franchisorRoutes;
    }
  };
}

export const RoleCheckLayout = communicationAuth.injector(RoleCheckLayoutComponent);
