import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { ERoutesPrivate } from 'common/models/routesModel';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { dateFormatter } from 'common/helpers/date.helper';
import { DATE_FORMAT_WITH_TIME } from 'common/const/common.const';
import {
  EPromoBonusType,
  EPromoCriteriaType,
  EPromoStatusText,
  IPromoCollection,
  IPromoCollectionFilter,
  IPromoModel
} from 'entities/Promo/Promo.models';
import { communicationPromo, IPromoConnectedProps } from 'entities/Promo/Promo.communication';
import { selectBonus, selectCriteria, selectPromoType } from 'entities/Promo/Promo.helper';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & IPromoConnectedProps & RouteComponentProps;

class PromoTableComponent extends TableCommon<IPromoCollection, IPromoModel, ColumnsType<any>, AllProps, IPromoCollectionFilter> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getPromoCollection, filter } = this.props;
    const filterParams: IPromoCollectionFilter = { ...params };

    if (filter?.name) {
      filterParams.name = filter?.name;
    }
    if (filter?.type) {
      filterParams.type = filter?.type;
    }
    if (filter?.bonusType) {
      filterParams.bonusType = filter?.bonusType;
    }
    if (filter?.criteria) {
      filterParams.criteria = filter?.criteria;
    }
    if (filter?.isEnable !== undefined) {
      filterParams.isEnable = filter?.isEnable;
    }

    getPromoCollection(filterParams);
  };

  getCollection = () => {
    const { promoCollection } = this.props;

    if (promoCollection?.data?.data) {
      const data: IPromoModel[] = promoCollection.data.data.map((item: IPromoModel) => {
        return {
          ...item,
          startDate: dateFormatter(item.startDate, DATE_FORMAT_WITH_TIME),
          endDate: dateFormatter(item.endDate, DATE_FORMAT_WITH_TIME),
          typeText: selectPromoType(item?.type),
          status: item.isEnable ? EPromoStatusText.Enable : EPromoStatusText.Disable,
          bonusType: selectBonus(item.bonus.bonusType as EPromoBonusType),
          criteria: selectCriteria(item.criteria as EPromoCriteriaType)
        };
      });
      return {
        ...promoCollection,
        data: {
          ...promoCollection.data,
          data
        }
      };
    } else {
      return promoCollection;
    }
  };

  addQuery = (pageIndex: number) => {
    const searchObject = queryToObject<IPromoCollectionFilter>({
      pageIndex: 1,
      name: undefined,
      type: undefined,
      criteria: undefined,
      customerTarget: undefined,
      bonusType: undefined,
      isEnable: undefined
    });
    const search = objectToQuery({ ...searchObject, pageIndex });
    return this.props.history.replace({ search });
  };

  onRowClick = (item: IPromoModel) => {
    const { history } = this.props;
    history.push(`${ERoutesPrivate.Promo}/${item.id}`);
  };

  clearCollection = () => {
    return this.props.clearPromoCollection();
  };
}

export const PromoTable = communicationPromo.injector(withRouter(PromoTableComponent));
