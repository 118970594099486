import React from 'react';
import { InfiniteList } from 'common/components/List/InfiniteList';
import { IBaseFilterModel } from 'common/models/requestModels';
import { communicationStatistic, IStatisticConnectedProps } from 'entities/Statistic/Statistic.communication';
import {
  EStatisticRange,
  IStatisticCollection,
  IStatisticCollectionFilter,
  IStatisticModel
} from 'entities/Statistic/Statistic.models';
import { StatisticStoreListItem } from 'entities/Statistic/components/StatisticStoreListItem';

interface IComponentProps {
  isSalesOps?: boolean;
  filter?: IStatisticCollectionFilter;
}

type AllProps = IComponentProps & IStatisticConnectedProps;

class StatisticStoreListComponent extends InfiniteList<
  IStatisticCollection,
  IStatisticModel,
  AllProps,
  IStatisticCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getStatisticCollection, isSalesOps, filter } = this.props;

    if (isSalesOps) {
      getStatisticCollection({ ...params, ...filter });
    } else {
      getStatisticCollection({ ...params, range: EStatisticRange.Today });
    }
  };

  renderListItem = (item: IStatisticModel) => {
    return <StatisticStoreListItem item={item} />;
  };

  getCollection = () => {
    return this.props.statisticCollection;
  };

  clearCollection = () => {
    this.props.clearStatisticCollection();
  };
}

export const StatisticStoreList = communicationStatistic.injector(StatisticStoreListComponent);
