import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Menu } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import { MenuMode } from 'antd/lib/menu';
import { ERoutesFranchiseePrivate } from 'common/models/routesModel';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';
import { communicationUser, IUserConnectedProps } from 'entities/User/User.communication';
import { EUserRole, EUserRoleText } from 'entities/User/User.models';

type AllProps = IAuthConnectedProps & RouteComponentProps & IUserConnectedProps & IComponentProps;

interface IComponentProps {
  mode?: MenuMode;
  mobile?: boolean;
  close?: () => void;
}

class HeaderMenuComponent extends React.Component<AllProps> {
  render() {
    const { history, mobile, authModel, authUser } = this.props;
    const { data } = authModel;
    const authorized = data && Object.keys(data).length !== 0;
    const visibleLogout = authorized && mobile;

    const path = history.location.pathname;
    const currentPath = path.split(/(^\/[a-z-A-Z]*)/)[1];
    const showLogo = !mobile;
    const mobileClass = mobile ? 'header-franchisee-menu_mobile' : '';
    const mode = mobile ? 'vertical' : 'horizontal';
    const isSalesOp = authUser.data?.role === EUserRole.SalesOps;
    const logoRole = isSalesOp ? EUserRoleText.SalesOps : EUserRoleText.Franchisee;

    return (
      <Menu selectedKeys={[currentPath]} mode={mode} className={`header-franchisee-menu ${mobileClass}`}>
        {showLogo && (
          <Menu.Item key="logo" onClick={this.redirectToMain}>
            <span className="header-franchisee__title">MB</span>
            <span className="header-franchisee__title_role"> — {logoRole}</span>
          </Menu.Item>
        )}

        <Menu.Item
          key={ERoutesFranchiseePrivate.StoreList}
          onClick={this.redirect}
          className="header-franchisee-menu__activilable-item"
        >
          Stores
        </Menu.Item>
        <Menu.Item
          key={ERoutesFranchiseePrivate.Statistics}
          onClick={this.redirect}
          className="header-franchisee-menu__activilable-item"
        >
          Statistics
        </Menu.Item>
        <Menu.Item
          key={ERoutesFranchiseePrivate.ChangePassword}
          onClick={this.redirect}
          className="header-franchisee-menu__activilable-item"
        >
          Change Password
        </Menu.Item>

        {visibleLogout && (
          <Menu.Item key="logout" onClick={this.logout} className="header-franchisee-menu__activilable-item">
            Log out
          </Menu.Item>
        )}
      </Menu>
    );
  }

  logout = () => {
    const { deleteAuthModel, close } = this.props;
    close && close();
    deleteAuthModel();
  };

  redirect = (e: MenuInfo) => {
    const { history, close } = this.props;
    close && close();
    history.push(e.key.toString());
  };

  redirectToMain = () => {
    const { history, close } = this.props;
    close && close();
    history.push(ERoutesFranchiseePrivate.StoreList);
  };
}

export const HeaderMenu = communicationUser.injector(communicationAuth.injector(withRouter(HeaderMenuComponent)));
