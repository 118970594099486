import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import {
  ICategoryCollection,
  ICategoryCollectionFilter,
  ICategoryCreateData,
  ICategoryModel,
  ICategoryUpdateData
} from 'entities/Category/Category.models';

const basePath = '/categories';

class CategoryTransport extends BaseHttpTransport<
  AxiosStatic,
  ICategoryModel,
  ICategoryCollection,
  ICategoryCreateData,
  ICategoryUpdateData,
  ICategoryCollectionFilter
> {}

export const categoryTransport = new CategoryTransport(basePath, axios);
