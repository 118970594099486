import React from 'react';
import { IBasePathParams } from 'common/models/requestModels';
import { QRCodeBlock } from 'entities/QR/components/QRCodeBlock';
import { IStoreModel } from 'entities/Store/Store.models';

export interface IQRModel extends Components.Schemas.QRCodeDto {}
export interface IQRCollection extends Components.Schemas.QRCodeCollectionDto {}
export interface IQRCreateData extends Components.Schemas.CreateQRCodeDto {}
export interface IQRUpdateData extends Components.Schemas.UpdateQRCodeDto {}

export interface IQRCollectionFilter extends Paths.QRCodeControllerList.QueryParameters {}

export interface IQRParams {
  path: IBasePathParams;
  data: IQRUpdateData;
}

export enum EQRSuccessMessage {
  Delete = 'QR-code has been successfully deleted',
  Create = 'QR-code has been successfully created',
  Edit = 'QR-code has been successfully edited'
}

export const tableQRConfig = [
  {
    title: 'QR name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true
  },
  {
    title: 'Store',
    dataIndex: 'store',
    key: 'store',
    ellipsis: true,
    render: (store: IStoreModel) => store?.storeName
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'created',
    ellipsis: true
  },
  {
    title: 'Actions',
    key: 'actions',
    dataIndex: 'name',
    render: (name: string) => <QRCodeBlock qrName={name} isImageHidden={true} />
  }
];
