import React from 'react';
import { Collapse, Row } from 'antd';
import { dateFormatter } from 'common/helpers/date.helper';
import { DATE_FORMAT_PICKUP } from 'common/const/common.const';
import { ReactComponent as StarIcon } from 'app/assets/icons/star.svg';
import { IRatingModel } from 'entities/Rating/Rating.models';
import { DetailedRatingBlock } from 'entities/Rating/components/DetailedRatingBlock';

interface IComponentProps {
  item: IRatingModel;
  showCreateDate?: boolean;
}

export const StoreFeedbackListItem: React.FC<IComponentProps> = props => {
  const { rate, customer, createdAt, feedback, friendly, quality, serve } = props.item || {};
  const { firstName, phone } = customer || {};
  const formattedCreatedAt = createdAt && dateFormatter(createdAt, DATE_FORMAT_PICKUP);
  const roundedRate = rate?.toFixed(1) || 0;

  return (
    <Collapse>
      <Collapse.Panel
        key={1}
        header={
          <Row className="flex-noWrap" align="middle" justify="space-between">
            <Row justify="start" className="flex-noWrap" align="middle">
              <StarIcon />
              <div className="ml-2">{roundedRate}</div>
            </Row>
            <Row justify="end" className="flex-noWrap" align="middle">
              {formattedCreatedAt}
            </Row>
          </Row>
        }
      >
        <DetailedRatingBlock serve={serve} quality={quality} friendly={friendly} />
        <span className="franchisee__text_default font-weight-7">Customer:</span> <br />
        <span className="franchisee__text_default font-weight-4">{firstName}</span> <br />
        <span className="franchisee__text_default font-weight-4">{phone}</span>
        {feedback && (
          <div className="mt-2">
            <span className="franchisee__text_default font-weight-7" title="Feedback">
              Feedback:
            </span>
            <br />
            <div title={feedback}>{feedback}</div>
          </div>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};
