import React from 'react';
import { IStatisticModel } from 'entities/Statistic/Statistic.models';

interface IComponentProps {
  item: Partial<IStatisticModel>;
}

export const OrderBody: React.FC<IComponentProps> = ({ item }) => {
  const { finishedCount, rejectedByCustomerCount, rejectedByStoreCount, abandonedCount, deviceUnattendedCount } = item;
  return (
    <>
      <span className="franchisee__text_default font-weight-4">— Completed:</span>
      <span className="franchisee__text_default font-weight-7"> {finishedCount || 0}</span> <br />
      <span className="franchisee__text_default font-weight-4">— Cancelled (Customer):</span>
      <span className="franchisee__text_default font-weight-7"> {rejectedByCustomerCount || 0}</span> <br />
      <span className="franchisee__text_default font-weight-4">— Cancelled (Store):</span>
      <span className="franchisee__text_default font-weight-7"> {rejectedByStoreCount || 0}</span> <br />
      <span className="franchisee__text_default font-weight-4">— Abandoned (Customer):</span>
      <span className="franchisee__text_default font-weight-7"> {abandonedCount || 0}</span> <br />
      <span className="franchisee__text_default font-weight-4">— Auto-Cancelled:</span>
      <span className="franchisee__text_default font-weight-7"> {deviceUnattendedCount || 0}</span>
    </>
  );
};
