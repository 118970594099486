import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { EBaseOrderDirection, IBaseFilterModel } from 'common/models/requestModels';
import { ERoutesPrivate } from 'common/models/routesModel';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { locationFormatter } from 'common/helpers/location.helper';
import {
  EStoreStatus,
  EStoreStatusText,
  IStoreCollection,
  IStoreCollectionFilter,
  IStoreModel
} from 'entities/Store/Store.models';
import { communicationStore, IStoreConnectedProps } from 'entities/Store/Store.communication';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & IStoreConnectedProps & IUIConnectedProps & RouteComponentProps;

class StoreTableComponent extends TableCommon<IStoreCollection, IStoreModel, ColumnsType<any>, AllProps, IStoreCollectionFilter> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getStoreCollection, filter } = this.props;
    const filterParams: IStoreCollectionFilter = { ...params, orderField: 'storeCode', orderDirection: EBaseOrderDirection.ASC };

    if (filter?.search) {
      filterParams.search = filter?.search;
    }
    if (filter?.statuses) {
      filterParams.statuses = filter?.statuses;
    }
    if (filter?.franchisee?.id) {
      filterParams.franchiseeId = filter?.franchisee.id;
    }
    getStoreCollection(filterParams);
  };

  getCollection = () => {
    const { storeCollection } = this.props;

    if (storeCollection?.data?.data) {
      const data: IStoreModel[] = storeCollection.data.data.map(item => {
        return {
          ...item,
          franchiseeName: `${item?.franchisee?.firstName || ''} ${item?.franchisee?.lastName || ''}`,
          addressName: locationFormatter(item.address),
          status: this.selectStatus(item?.status)
        };
      });
      return {
        ...storeCollection,
        data: {
          ...storeCollection.data,
          data
        }
      };
    } else {
      return storeCollection;
    }
  };

  selectStatus = (status?: string): any => {
    switch (status) {
      case EStoreStatus.New:
        return EStoreStatusText.New;

      case EStoreStatus.Unavailable:
        return EStoreStatusText.Unavailable;

      case EStoreStatus.Active:
        return EStoreStatusText.Active;

      case EStoreStatus.Archived:
        return EStoreStatusText.Archived;

      default:
        return EStoreStatusText.Unavailable;
    }
  };

  addQuery = (pageIndex: number) => {
    const searchObject = queryToObject<IStoreCollectionFilter>({
      pageIndex: 1,
      search: undefined,
      statuses: undefined,
      franchisee: undefined
    });
    const search = objectToQuery({ ...searchObject, pageIndex });
    return this.props.history.replace({ search });
  };

  onRowClick = (item: IStoreModel) => {
    const { history } = this.props;
    history.push(`${ERoutesPrivate.Stores}/${item.shortStoreId}`);
  };

  clearCollection = () => {
    return this.props.clearStoreCollection();
  };
}

export const StoreTable = communicationStore.injector(communicationUI.injector(withRouter(StoreTableComponent)));
