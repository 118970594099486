import React from 'react';
import { Button, Col, Row } from 'antd';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { communicationStatistic, IStatisticConnectedProps } from 'entities/Statistic/Statistic.communication';

type AllProps = IStatisticConnectedProps;

const StatisticFranchisorPageComponent: React.FC<AllProps> = props => {
  const { getStatisticCSV } = props;

  const handleSubmit = async () => {
    getStatisticCSV();
  };
  return (
    <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
      <Row className="mb-10 width-full" justify="space-between" align="middle">
        <Col span={18}>
          <Row justify="space-between" align="middle">
            <Col span={8}>
              <Button block type="primary" title="Download CSV" onClick={handleSubmit}>
                Download CSV
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </CommonLayout>
  );
};

export const StatisticFranchisorPage = communicationStatistic.injector(StatisticFranchisorPageComponent);
