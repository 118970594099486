import React from 'react';
import { Card, Typography } from 'antd';
import { IStatisticModel } from 'entities/Statistic/Statistic.models';
import { MenuItemsListForStatistic } from 'entities/Statistic/components/MenuItemsListForStatistic';

interface IComponentProps {
  item: Partial<IStatisticModel>;
}

type AllProps = IComponentProps;

export const StatsCard: React.FC<AllProps> = props => {
  const { item } = props;
  const { acceptanceRate, averageCheck, offlineIncidentCount, ordersCount, outOfStockProducts, productMix, revenue } = item;

  return (
    <Card
      bordered
      className="mt-8"
      title={
        <Typography.Paragraph className="m-0 p-0 franchisee__text_default font-weight-7" ellipsis={{ rows: 1 }} title="Stats">
          Stats
        </Typography.Paragraph>
      }
    >
      <span className="franchisee__text_default font-weight-4">— Revenue:</span>
      <span className="franchisee__text_default font-weight-7">{` P ${revenue || 0}`}</span> <br />
      <span className="franchisee__text_default font-weight-4">— Successful orders:</span>
      <span className="franchisee__text_default font-weight-7"> {ordersCount || 0}</span> <br />
      <span className="franchisee__text_default font-weight-4">— Avg. check:</span>
      <span className="franchisee__text_default font-weight-7">{` P ${averageCheck || 0}`}</span> <br />
      <span className="franchisee__text_default font-weight-4">— Acceptance Rate:</span>
      <span className="franchisee__text_default font-weight-7"> {acceptanceRate || 0}</span> <br />
      <span className="franchisee__text_default font-weight-4">— Incidents of Offline:</span>
      <span className="franchisee__text_default font-weight-7"> {offlineIncidentCount || 0}</span> <br />
      {!!productMix?.length && (
        <div className="mt-5">
          <span className="franchisee__text_default font-weight-4">— Product Mix:</span> <br />
          <MenuItemsListForStatistic items={productMix} />
        </div>
      )}
      {!!outOfStockProducts?.length && (
        <div className="mt-5">
          <span className="franchisee__text_default font-weight-4">— Incidents of Out of Stock:</span> <br />
          <MenuItemsListForStatistic items={outOfStockProducts} />
        </div>
      )}
    </Card>
  );
};
