import { APIProvider, BaseStrategy, Branch, buildCommunication, getStartType, StoreBranch } from '@axmit/redux-communications';
import { put } from 'redux-saga/effects';
import { IModalModel } from 'entities/UI/UI.models';

const namespace = 'UI';

export interface IUIConnectedProps {
  UICommonModal: StoreBranch<IModalModel>;

  openUICommonModal(): void;
  closeUICommonModal(): void;
}

const CommonModalApiProvider = [
  new APIProvider('open', (): Promise<IModalModel> => Promise.resolve({ isVisible: true })),
  new APIProvider('close', (): Promise<IModalModel> => Promise.resolve({ isVisible: false }))
];

const branches = [new Branch('commonModal', CommonModalApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationUI = buildCommunication<IUIConnectedProps>(strategy);

communicationUI.sagas.push();

export function* closeCommonModal() {
  yield put({ type: getStartType(namespace, 'commonModal', 'close') });
}
