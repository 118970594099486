import React, { ChangeEvent, KeyboardEvent } from 'react';
import { Checkbox, Col, Input, Row, Table, Typography } from 'antd';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import debounce from 'lodash.debounce';
import { DragHandle } from 'entities/Asset/components/DnD/AssetDnDCommon';
import { IAssetCategories, IAssetMenuItem } from 'entities/Asset/Asset.models';

const SortableItem = SortableElement(({ index, ...props }: any) => <tr {...props} key={index} />);
const SortableContainerComponent = SortableContainer(({ index, ...props }: any) => <tbody {...props} key={index} />);

interface IComponentProps {
  onItemChange: (categoryItem: IAssetCategories) => void;
  item: IAssetCategories;
}

export class AssetChildSortableTable extends React.Component<IComponentProps> {
  public debounceOnChangeToggle: any;
  constructor(props: IComponentProps) {
    super(props);
    this.debounceOnChangeToggle = debounce(this.isInMenuToggle, 300);
  }
  render() {
    const { item } = this.props;
    const { name: categoryName, menuItemsToAsset, isDeleted: isCategoryDeleted } = item;
    const titleForCategory = isCategoryDeleted ? 'Category was removed' : categoryName;

    const DraggableContainer = (props: any) => {
      return <SortableContainerComponent {...props} useDragHandle helperClass="row-dragging" onSortEnd={this.onSortEnd} />;
    };

    const columns = [
      {
        title: <DragHandle />,
        dataIndex: 'sort',
        render: () => <DragHandle />,
        width: '40px'
      },
      {
        title: (
          <Typography.Paragraph
            ellipsis={{ rows: 1 }}
            title={titleForCategory}
            className="mb-0"
            disabled={isCategoryDeleted}
            delete={isCategoryDeleted}
          >
            {categoryName}
          </Typography.Paragraph>
        ),
        render: (item: IAssetMenuItem) => {
          const { name, isDeleted, isInMenu, id, price } = item;
          const title = isDeleted ? 'Menu item was removed' : name;

          return (
            <Row justify="space-between" align="middle">
              <Typography.Paragraph
                ellipsis={{ rows: 1 }}
                title={title}
                className="mb-0"
                disabled={isDeleted}
                delete={isDeleted}
                style={{ width: '180px' }}
              >
                {name}
              </Typography.Paragraph>
              <Col span={4}>
                {!isDeleted && (
                  <Input
                    type="number"
                    prefix="P"
                    key={id}
                    defaultValue={price}
                    onBlur={(e: ChangeEvent<HTMLInputElement>) => this.onPriceChange(e.target.value, id)}
                    onPressEnter={(e: KeyboardEvent<HTMLInputElement>) => e.preventDefault()}
                  />
                )}
              </Col>
              <Col span={6}>
                {!isDeleted && (
                  <Checkbox key={id} checked={isInMenu} onChange={e => this.debounceOnChangeToggle(e, id)} disabled={isDeleted}>
                    Add to menu
                  </Checkbox>
                )}
              </Col>
            </Row>
          );
        }
      }
    ];

    return (
      <Table
        pagination={false}
        dataSource={menuItemsToAsset}
        columns={columns}
        rowKey="orderNumber"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: this.DraggableBodyRow
          }
        }}
      />
    );
  }

  onPriceChange = (priceValue: string, id: string) => {
    const { onItemChange, item } = this.props;
    const price = parseInt(priceValue);
    const newMenuItemsArray = item.menuItemsToAsset.map(item => (item.id === id ? { ...item, price } : item));

    onItemChange({ ...item, menuItemsToAsset: newMenuItemsArray });
  };

  isInMenuToggle = (e: CheckboxChangeEvent, id: string) => {
    const { onItemChange, item } = this.props;
    const isInMenu = e.target.checked;
    const newMenuItemsArray = item.menuItemsToAsset.map(item => (item.id === id ? { ...item, isInMenu } : item));

    onItemChange({ ...item, menuItemsToAsset: newMenuItemsArray });
  };

  onSortEnd = ({ oldIndex, newIndex }: any) => {
    const { onItemChange, item } = this.props;

    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(item?.menuItemsToAsset as any), oldIndex, newIndex)
        .filter(el => !!el)
        .map((item: IAssetMenuItem, index) => ({ ...item, orderNumber: index }));

      onItemChange({ ...item, menuItemsToAsset: newData });
    }
  };

  DraggableBodyRow = (props: any) => {
    const { item } = this.props;
    const index = item.menuItemsToAsset.findIndex(x => x.orderNumber === props['data-row-key']);

    return <SortableItem {...props} key={index} index={index} />;
  };
}
