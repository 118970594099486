import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { EUserRole, IUserCollection, IUserCollectionFilter, IUserModel, roleDict } from 'entities/User/User.models';
import { communicationUser, IUserConnectedProps } from 'entities/User/User.communication';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & IUserConnectedProps & IUIConnectedProps & RouteComponentProps;

class UserTableComponent extends TableCommon<IUserCollection, IUserModel, ColumnsType<any>, AllProps, IUserCollectionFilter> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getUserCollection, filter } = this.props;
    const filterParams: IUserCollectionFilter = { ...params };

    if (filter?.search) {
      filterParams.search = filter?.search;
    }
    if (filter?.roles?.length) {
      filterParams.roles = filter?.roles;
    } else {
      filterParams.roles = [EUserRole.Franchisor, EUserRole.Franchisee, EUserRole.SalesOps];
    }
    if (filter?.statuses) {
      filterParams.statuses = filter?.statuses;
    }
    getUserCollection(filterParams);
  };

  getCollection = () => {
    const { userCollection } = this.props;
    if (userCollection?.data?.data) {
      const data: IUserModel[] = userCollection.data.data.map(item => {
        return { ...item, name: `${item.firstName || ''} ${item.lastName || ''}`, mappedRole: roleDict[item.role] };
      });
      return {
        ...userCollection,
        data: {
          ...userCollection.data,
          data
        }
      };
    } else {
      return userCollection;
    }
  };

  addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<IUserCollectionFilter>({
      pageIndex: 1,
      search: undefined,
      roles: undefined,
      statuses: undefined
    });
    const search = objectToQuery({ ...searchPath, pageIndex });
    return this.props.history.replace({ search });
  };

  onRowClick = (item: IUserModel) => {
    const { history } = this.props;
    history.push(`${ERoutesPrivate.Users}/${item.id}`);
  };

  clearCollection = () => {
    return this.props.clearUserCollection();
  };
}

export const UserTable = communicationUser.injector(communicationUI.injector(withRouter(UserTableComponent)));
