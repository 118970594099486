import React from 'react';
import { Card, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { ERoutesFranchiseePrivate } from 'common/models/routesModel';
import { ReactComponent as StarIcon } from 'app/assets/icons/star.svg';
import { IStatisticModel } from 'entities/Statistic/Statistic.models';
import { MenuItemsListForStatistic } from 'entities/Statistic/components/MenuItemsListForStatistic';
import { OrderBody } from 'entities/Statistic/components/Card/OrderBody';
import { DetailedRatingBlock } from 'entities/Rating/components/DetailedRatingBlock';

interface IComponentProps {
  item: IStatisticModel;
}

type AllProps = IComponentProps;

export const StatisticStoreListItem: React.FC<AllProps> = props => {
  const { item } = props;
  const {
    storeName = 'Unknown store',
    acceptanceRate,
    averageCheck,
    offlineIncidentCount,
    ordersCount,
    outOfStockProducts,
    productMix,
    revenue,
    storeId,
    storePhone,
    storeState,
    rate,
    rateCount,
    friendly,
    quality,
    serve
  } = item;

  const hasRating = !!rateCount;
  const formattedRate = rate?.toFixed(1) || 0;

  const rating = hasRating ? `${formattedRate} (${rateCount})` : 'No rating yet';

  return (
    <Card
      bordered
      className="mt-7"
      title={
        <Row className="flex-noWrap" align="middle" justify="space-between">
          <Row justify="start" className="flex-noWrap">
            <Typography.Paragraph
              className="m-0 p-0 franchisee__text_default font-weight-7 mr-2"
              ellipsis={{ rows: 1 }}
              title={storeName || 'Unknown store'}
            >
              {storeName}
            </Typography.Paragraph>
            <span className="franchisee__text_default font-weight-4 t-transform-c"> — {storeState}</span>
          </Row>
          <Row justify="end" className="flex-noWrap" align="middle">
            <StarIcon />
            {hasRating ? (
              <Link
                to={`${ERoutesFranchiseePrivate.StoreFeedback}/${storeId}`}
                className="font-family__default fs-xxs basic__link ellipsis ml-2"
                title={rating}
              >
                {rating}
              </Link>
            ) : (
              <span className="font-family__default fs-xxs color-brown ml-2 cursor-pointer" title={rating}>
                {rating}
              </span>
            )}
          </Row>
        </Row>
      }
    >
      {hasRating && <DetailedRatingBlock friendly={friendly} quality={quality} serve={serve} />}
      <span className="franchisee__text_default font-weight-7">Store number</span> <br />
      <a href={`tel:${storePhone}`} className="franchisee__text_default font-weight-4 basic__link">
        {storePhone}
      </a>
      <br />
      <div className="mt-5">
        <span className="franchisee__text_default font-weight-7"> Daily stats</span> <br />
        <span className="franchisee__text_default font-weight-4">— Revenue:</span>
        <span className="franchisee__text_default font-weight-7">{` P ${revenue || 0}`}</span> <br />
        <span className="franchisee__text_default font-weight-4">— Successful orders:</span>
        <span className="franchisee__text_default font-weight-7"> {ordersCount || 0}</span> <br />
        <span className="franchisee__text_default font-weight-4">— Avg. check:</span>
        <span className="franchisee__text_default font-weight-7">{` P ${averageCheck || 0}`}</span> <br />
        <span className="franchisee__text_default font-weight-4">— Acceptance Rate:</span>
        <span className="franchisee__text_default font-weight-7"> {acceptanceRate || 0}</span> <br />
        <span className="franchisee__text_default font-weight-4">— Incidents of Offline:</span>
        <span className="franchisee__text_default font-weight-7"> {offlineIncidentCount || 0}</span> <br />
      </div>
      {!!productMix.length && (
        <div className="mt-5">
          <span className="franchisee__text_default font-weight-4">— Product Mix:</span> <br />
          <MenuItemsListForStatistic items={productMix} />
        </div>
      )}
      {!!outOfStockProducts.length && (
        <div className="mt-5">
          <span className="franchisee__text_default font-weight-4">— Incidents of Out of Stock:</span> <br />
          <MenuItemsListForStatistic items={outOfStockProducts} />
        </div>
      )}
      <div className="mt-5">
        <OrderBody item={item} />
      </div>
      <Row justify="center" align="middle">
        <Link
          to={`${ERoutesFranchiseePrivate.StorePage}/${storeId}`}
          className="font-family__default fs-xxs basic__link ellipsis mt-5"
          title="Go to store orders"
        >
          Go to store orders
        </Link>
      </Row>
    </Card>
  );
};
