import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { dateFormatter } from 'common/helpers/date.helper';
import { DATE_FORMAT } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { nameBuilder } from 'common/helpers/name.helper';
import { ICustomerCollection, ICustomerCollectionFilter, ICustomerModel } from 'entities/Customer/Customer.models';
import { communicationCustomer, ICustomerConnectedProps } from 'entities/Customer/Customer.communication';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = ICustomerConnectedProps & RouteComponentProps & IComponentProps;

class CustomerTableComponent extends TableCommon<
  ICustomerCollection,
  ICustomerModel,
  ColumnsType<any>,
  AllProps,
  ICustomerCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getCustomerCollection, filter } = this.props;
    const filterParams: ICustomerCollectionFilter = { ...params };

    if (filter?.search) {
      filterParams.search = filter?.search;
    }
    if (filter?.statuses) {
      filterParams.statuses = filter?.statuses;
    }
    getCustomerCollection(filterParams);
  };

  getCollection = () => {
    const { customerCollection } = this.props;
    if (customerCollection?.data?.data) {
      const data: ICustomerModel[] = customerCollection.data.data.map(item => {
        return {
          ...item,
          id: item.userId,
          birthDate: item.birthDate ? dateFormatter(item.birthDate, DATE_FORMAT) : '',
          name: nameBuilder(item?.firstName, item?.lastName)
        };
      });
      return {
        ...customerCollection,
        data: {
          ...customerCollection.data,
          data
        }
      };
    } else {
      return customerCollection;
    }
  };

  addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<ICustomerCollectionFilter>({
      pageIndex: 1,
      search: undefined,
      statuses: undefined
    });
    const search = objectToQuery({ ...searchPath, pageIndex });
    return this.props.history.replace({ search });
  };

  onRowClick = (item: ICustomerModel) => {
    const { history } = this.props;
    history.push(`${ERoutesPrivate.Customers}/${item.userId}`);
  };

  clearCollection = () => {
    return this.props.clearCustomerCollection();
  };
}

export const CustomerTable = communicationCustomer.injector(withRouter(CustomerTableComponent));
