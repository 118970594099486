import { ColumnsType } from 'antd/es/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { ERoutesPrivate } from 'common/models/routesModel';
import { dateFormatter } from 'common/helpers/date.helper';
import { DATE_FORMAT_WITH_TIME } from 'common/const/common.const';
import { communicationQR, IQRConnectedProps } from 'entities/QR/QR.communication';
import { IQRCollection, IQRCollectionFilter, IQRModel } from 'entities/QR/QR.models';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & IQRConnectedProps & RouteComponentProps;

class QRTableComponent extends TableCommon<IQRCollection, IQRModel, ColumnsType<any>, AllProps, IQRCollectionFilter> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getQrCollection, filter } = this.props;
    const filterParams: IQRCollectionFilter = { ...params };

    if (filter?.name) {
      filterParams.name = filter.name;
    }
    if (filter?.storeId) {
      filterParams.storeId = filter.storeId;
    }
    getQrCollection(filterParams);
  };

  getCollection = () => {
    const { qrCollection } = this.props;

    if (qrCollection?.data?.data) {
      const data: IQRModel[] = qrCollection.data.data.map((item: IQRModel) => {
        return {
          ...item,
          createdAt: dateFormatter(item.createdAt, DATE_FORMAT_WITH_TIME)
        };
      });
      return {
        ...qrCollection,
        data: {
          ...qrCollection.data,
          data
        }
      };
    } else {
      return qrCollection;
    }
  };

  onRowClick = (item: IQRModel) => {
    const { history } = this.props;
    history.push(`${ERoutesPrivate.QR}/${item.id}`);
  };

  clearCollection = () => {
    return this.props.clearQrCollection();
  };
}

export const QRTable = communicationQR.injector(withRouter(QRTableComponent));
