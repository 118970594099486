import React from 'react';
import { Row, Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EUserRole, EUserRoleText } from 'entities/User/User.models';

interface IComponentProps {
  onChangeRole: (value?: EUserRole) => void;
}

type AllProps = SelectProps<any> & IComponentProps;

export class UserRoleSelector extends React.PureComponent<AllProps> {
  handleChange = (value: EUserRole) => {
    const { onChangeRole } = this.props;

    onChangeRole(value);
  };

  render() {
    const { value } = this.props;

    return (
      <Row className="flex-noWrap" justify="space-between" align="middle">
        <span className="mr-4">Role</span>
        <Select
          allowClear
          className="width-full"
          placeholder="Select role"
          value={value}
          defaultActiveFirstOption={false}
          showArrow
          onChange={(value: EUserRole) => this.handleChange(value)}
        >
          <Select.Option key={EUserRole.Franchisee} value={EUserRole.Franchisee} label={EUserRoleText.Franchisee}>
            {EUserRoleText.Franchisee}
          </Select.Option>
          <Select.Option key={EUserRole.Franchisor} value={EUserRole.Franchisor} label={EUserRoleText.Franchisor}>
            {EUserRoleText.Franchisor}
          </Select.Option>
          <Select.Option key={EUserRole.SalesOps} value={EUserRole.SalesOps} label={EUserRoleText.SalesOps}>
            {EUserRoleText.SalesOps}
          </Select.Option>
        </Select>
      </Row>
    );
  }
}
