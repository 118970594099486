import React, { ChangeEvent } from 'react';
import { Button, Col, Input, Row } from 'antd';
import debounce from 'lodash.debounce';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { UserTable } from 'entities/User/components/UserTable';
import { EUserRole, EUserStatus, IUserCollectionFilter, tableUserConfig } from 'entities/User/User.models';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { UserRoleSelector } from 'entities/User/components/Selector/UserRoleSelector';
import { UserStatusSelector } from 'entities/User/components/Selector/UserStatusSelector';

type AllProps = IUIConnectedProps & RouteComponentProps;

interface IComponentState {
  filter: IUserCollectionFilter;
}

class UserPageComponent extends React.PureComponent<AllProps, IComponentState> {
  public debounceSearch: any;
  state: IComponentState = {
    filter: {
      pageIndex: 1,
      search: undefined,
      roles: undefined,
      statuses: undefined
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<IUserCollectionFilter>(this.state.filter) };
    this.debounceSearch = debounce(this.autocompleteSearch, 300);
  }

  render() {
    const { filter } = this.state;
    const roleValue = filter.roles?.length ? filter.roles[0] : undefined;
    const statusValue = filter.statuses?.length ? filter.statuses[0] : undefined;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Row className="mb-10 width-full" justify="space-between" align="middle">
          <Col span={18}>
            <Row justify="space-between" align="middle">
              <Col span={8}>
                <Input.Search
                  placeholder="Search by name / email"
                  defaultValue={filter.search}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => this.debounceSearch(e.target.value)}
                />
              </Col>
              <Col span={6}>
                <UserRoleSelector onChangeRole={this.onChangeRole} value={roleValue} />
              </Col>
              <Col span={6}>
                <UserStatusSelector onChangeStatus={this.onChangeStatus} value={statusValue} />
              </Col>
            </Row>
          </Col>
          <Col span={4}>
            <Link to={ERoutesPrivate.UsersInvite}>
              <Button block type="primary" title="Invite user">
                Invite user
              </Button>
            </Link>
          </Col>
        </Row>
        <UserTable config={tableUserConfig} filter={filter} />
      </CommonLayout>
    );
  }

  setFilter = (partialFilter: Partial<IUserCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  autocompleteSearch = (text: string) => {
    const search = text === '' ? undefined : text;
    this.setFilter({ search });
  };

  onChangeRole = (role?: EUserRole) => {
    const roles = role ? [role] : [];
    this.setFilter({ roles });
  };

  onChangeStatus = (status?: EUserStatus) => {
    const statuses = status ? [status] : [];
    this.setFilter({ statuses });
  };
}

export const UserTablePage = communicationUI.injector(withRouter(UserPageComponent));
