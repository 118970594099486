import React from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ECommonFormType } from 'common/models/commonModel';
import { communicationStore, IStoreConnectedProps } from 'entities/Store/Store.communication';
import { IStoreCreateData, IStoreUpdateData } from 'entities/Store/Store.models';
import { StoreForm } from 'entities/Store/components/Form/StoreForm';
import { StoreFormSkeleton } from 'entities/Store/components/Skeleton/StoreFormSkeleton';

interface IParams {
  id?: string;
}

type AllProps = IStoreConnectedProps & RouteComponentProps<IParams>;

class StorePageComponent extends React.PureComponent<AllProps> {
  componentDidMount(): void {
    const { getByShortIdStoreModel, match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      getByShortIdStoreModel({ id });
    }
  }

  componentWillUnmount() {
    const { clearStoreModel } = this.props;

    clearStoreModel();
  }

  render() {
    const { storeModel, match } = this.props;
    const { params } = match;
    const { id } = params;
    const { data: storeModelData, loading } = storeModel;
    const storeName = storeModelData?.storeName;
    const isEdit = !!id;
    const typeForm = isEdit ? ECommonFormType.Update : ECommonFormType.Add;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
        <Col span={16}>
          <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
            <Breadcrumb.Item href={ERoutesPrivate.Stores}>Store list</Breadcrumb.Item>
            <Breadcrumb.Item>
              {isEdit ? (
                !loading ? (
                  storeName
                ) : (
                  <Skeleton.Input active style={{ width: '200px' }} size="small" />
                )
              ) : (
                'Create new store'
              )}
            </Breadcrumb.Item>
          </Breadcrumb>
          {!loading || !isEdit ? (
            <StoreForm
              storeModel={storeModel}
              typeForm={typeForm}
              createStore={this.createStore}
              updateStore={this.updateStore}
            />
          ) : (
            <StoreFormSkeleton typeForm={typeForm} />
          )}
        </Col>
      </CommonLayout>
    );
  }

  updateStore = (data: IStoreUpdateData | IStoreCreateData) => {
    const { updateStoreModel, storeModel } = this.props;
    const id = storeModel.data?.id;

    if (id) {
      updateStoreModel({ path: { id }, data });
    }
  };

  createStore = (data: IStoreCreateData) => {
    const { addStoreModel } = this.props;
    addStoreModel({ data, path: { id: '' } });
  };
}

export const StorePage = communicationStore.injector(StorePageComponent);
