import React from 'react';
import { Col, Row, Typography, Collapse } from 'antd';
import moment from 'moment';
import { DATE_FORMAT, DATE_FORMAT_PICKUP } from 'common/const/common.const';
import { dateFormatter } from 'common/helpers/date.helper';
import { nameBuilder } from 'common/helpers/name.helper';
import { EStatisticStoreOrderStatus } from 'entities/Statistic/Statistic.models';
import { EOrderRejectReason, EOrderRejectReasonText, IOrderItemsModel, IOrderModel } from 'entities/Order/Order.models';

interface IComponentProps {
  item: IOrderModel;
  showCreateDate?: boolean;
}
export const OrderListItem: React.FC<IComponentProps> = (props: IComponentProps) => {
  const { item, showCreateDate } = props;
  const {
    code,
    total,
    id,
    pickUpTime,
    customerData,
    items,
    status,
    rejectReason,
    createdAt,
    rejectDetails,
    discountTotal
  } = item;
  const customerName = nameBuilder(customerData?.firstName, customerData?.lastName);
  const customerPhone = customerData?.phone || 'Unknown number';
  const canceledByStore = status === EStatisticStoreOrderStatus.CanceledByStore;
  const canceledByCustomer = status === EStatisticStoreOrderStatus.CanceledByCustomer;
  const isCanceled = canceledByStore || canceledByCustomer;
  const time = moment(pickUpTime).format(DATE_FORMAT_PICKUP);
  const createDate = dateFormatter(createdAt, DATE_FORMAT);
  const canceledType = canceledByStore
    ? 'Canceled by store'
    : canceledByCustomer
    ? 'Canceled by customer'
    : 'Unknown canceled type';

  const renderOrderItems = (items: IOrderItemsModel[]) =>
    items.map(item => {
      const { quantity, assetItemData, addOns, id } = item;
      const itemName = assetItemData.name;

      return (
        <React.Fragment key={id}>
          <br />
          <span className="franchisee__text_default font-weight-7" title={`${quantity} x ${itemName}`}>
            {quantity} x {itemName}
          </span>
          <br />
          {addOns?.map(item => (
            <React.Fragment key={item.id}>
              <span className="franchisee__text_default font-weight-4" title={`${item.quantity} x ${item.assetItemData.name}`}>
                {item.quantity} x {item.assetItemData.name}
              </span>
              <br />
            </React.Fragment>
          ))}
        </React.Fragment>
      );
    });

  const rejectReasonText = (rejectReason: string): EOrderRejectReasonText | string => {
    switch (rejectReason) {
      case EOrderRejectReason.Abandoned:
        return EOrderRejectReasonText.Abandoned;

      case EOrderRejectReason.ChangeProduct:
        return EOrderRejectReasonText.ChangeProduct;

      case EOrderRejectReason.ChangeQuantity:
        return EOrderRejectReasonText.ChangeQuantity;

      case EOrderRejectReason.ChangeTime:
        return EOrderRejectReasonText.ChangeTime;

      case EOrderRejectReason.ProductsUnavailable:
        return EOrderRejectReasonText.ProductsUnavailable;

      case EOrderRejectReason.StoreTooBusy:
        return EOrderRejectReasonText.StoreTooBusy;

      case EOrderRejectReason.WaitedTooLong:
        return EOrderRejectReasonText.WaitedTooLong;

      case EOrderRejectReason.WrongLocation:
        return EOrderRejectReasonText.WrongLocation;
      case EOrderRejectReason.DeviceUnattended:
        return EOrderRejectReasonText.DeviceUnattended;

      default:
        return rejectDetails || 'Unknown reason';
    }
  };
  const rejectReasonValue = rejectReasonText(rejectReason);

  return (
    <Collapse>
      <Collapse.Panel
        header={
          <Row justify="space-between" align="middle">
            <Col>
              <Row justify="start" className="flex-noWrap">
                <Typography.Paragraph className="m-0 p-0 franchisee__text_paragraph" ellipsis={{ rows: 1 }} title={code}>
                  {code}
                </Typography.Paragraph>
              </Row>
              <Row justify="start" className="flex-noWrap">
                <span className="franchisee__text_paragraph white-space-nowrap" title={time}>
                  {time}
                </span>
              </Row>
              {showCreateDate && (
                <Row>
                  <span className="franchisee__text_paragraph mr-2" title={createDate}>
                    {createDate}
                  </span>
                </Row>
              )}
            </Col>
            <Col>
              <Row justify="end" className="flex-noWrap">
                <span className="franchisee__text_paragraph mr-2" title="TOTAL:">
                  TOTAL:
                </span>
                <Typography.Paragraph className="m-0 p-0 franchisee__text_paragraph" ellipsis={{ rows: 1 }} title={`${total}`}>
                  P{discountTotal || total}
                </Typography.Paragraph>
              </Row>
            </Col>
          </Row>
        }
        key={id}
      >
        <span className="franchisee__text_default font-weight-4" title={`${customerName}: ${customerPhone}`}>
          {customerName}: {customerPhone}
        </span>
        <br />
        {renderOrderItems(items)}
        {isCanceled && (
          <>
            <br />
            <span className="franchisee__text_default font-weight-7" title={canceledType}>
              {canceledType}:
            </span>
            <br />
            <span className="franchisee__text_default font-weight-4" title={rejectReasonValue}>
              {rejectReasonValue}
            </span>
          </>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};
