import React from 'react';
import { ELayoutSize } from 'common/const/common.const';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { UserProfileForm } from 'entities/User/components/Form/UserProfileForm';

class UserProfilePageComponent extends React.PureComponent {
  render() {
    return (
      <CommonLayout typeLayout={ELayoutSize.Small} classStyle="basic__layout_m-t">
        <UserProfileForm />
      </CommonLayout>
    );
  }
}

export const UserProfilePage = UserProfilePageComponent;
