import axios from 'axios';
import { IBasePathParams } from 'common/models/requestModels';
import { objectToQuery } from 'common/helpers/filters.helper';
import { ICheckTokenData } from 'common/models/commonModel';
import { IAuthModel } from 'entities/Auth/Auth.models';
import {
  IUserCollection,
  IUserCollectionFilter,
  IUserConfirmData,
  IUserInviteData,
  IUserModel,
  IUserUpdateData,
  IUserUpdatePasswordData
} from 'entities/User/User.models';

const basePath = '/users';

export const userTransport = {
  get: (path: IBasePathParams): Promise<IUserModel> => axios.get(`${basePath}/${path.id}`),
  update: (params: { path: IBasePathParams; data: IUserUpdateData }): Promise<IUserModel> =>
    axios.put(`${basePath}/${params.path.id}`, params.data),
  invite: (params: { data: IUserInviteData }): Promise<IUserModel> => axios.post(basePath, params.data),
  updatePassword: (params: { path: IBasePathParams; data: IUserUpdatePasswordData }): Promise<IUserModel> =>
    axios.put(`${basePath}/${params.path.id}/password`, params.data),
  reInvite: (path: IBasePathParams): Promise<IUserModel> => axios.patch(`${basePath}/${path.id}/invite`),

  checkInviteToken: (data: ICheckTokenData): Promise<void> => axios.post(`${basePath}/check-token`, data),
  delete: (path: IBasePathParams): Promise<void> => axios.delete(`${basePath}/${path.id}`),

  confirm: (data: IUserConfirmData): Promise<IAuthModel> => axios.post(`${basePath}/join`, data),

  getCollection: (filter?: IUserCollectionFilter): Promise<IUserCollection> => axios.get(`${basePath}${objectToQuery(filter)}`)
};
