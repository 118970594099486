import { SelectProps } from 'antd/es/select';
import React from 'react';
import { Row, Select } from 'antd';
import { ECustomerStatus, ECustomerStatusText } from 'entities/Customer/Customer.models';

interface IComponentProps {
  onChangeStatus: (value?: ECustomerStatus) => void;
}

type AllProps = SelectProps<any> & IComponentProps;

export class CustomerStatusSelector extends React.PureComponent<AllProps> {
  handleChange = (value: ECustomerStatus) => {
    const { onChangeStatus } = this.props;
    onChangeStatus(value);
  };

  render() {
    const { value } = this.props;
    return (
      <Row className="flex-noWrap" justify="space-between" align="middle">
        <span className="mr-4">Status</span>
        <Select
          allowClear
          className="width-full"
          placeholder="Select status"
          value={value}
          defaultActiveFirstOption={false}
          showArrow
          onChange={(val: ECustomerStatus) => this.handleChange(val)}
        >
          <Select.Option key={ECustomerStatus.Active} value={ECustomerStatus.Active} label={ECustomerStatusText.Active}>
            {ECustomerStatusText.Active}
          </Select.Option>
          <Select.Option key={ECustomerStatus.Blocked} value={ECustomerStatus.Blocked} label={ECustomerStatusText.Blocked}>
            {ECustomerStatusText.Blocked}
          </Select.Option>
          <Select.Option key={ECustomerStatus.Pending} value={ECustomerStatus.Pending} label={ECustomerStatusText.Pending}>
            {ECustomerStatusText.Pending}
          </Select.Option>
        </Select>
      </Row>
    );
  }
}
