import { Col, Row, Skeleton } from 'antd';
import React from 'react';
import { SkeletonFormItem } from 'common/components/Skeleton/SkeletonFormItem';
import { ECommonFormType } from 'common/models/commonModel';

interface IComponentProps {
  typeForm: ECommonFormType;
}
export const CategoryFormSkeleton: React.FC<IComponentProps> = (props: IComponentProps) => {
  const { typeForm } = props;
  const isCreateForm = typeForm === ECommonFormType.Add;
  return (
    <div className="mt-5">
      <SkeletonFormItem label="Category name">
        <Skeleton.Input active />
      </SkeletonFormItem>
      {isCreateForm && (
        <SkeletonFormItem>
          <Skeleton.Input active />
        </SkeletonFormItem>
      )}
      <Row className="flex-noWrap" justify="space-between" align="middle">
        <Col span={6}>
          <Row className="flex-noWrap" justify="space-between">
            <Col span={10}>
              <SkeletonFormItem>
                <Skeleton.Button active />
              </SkeletonFormItem>
            </Col>
            <Col span={10}>
              <SkeletonFormItem>
                <Skeleton.Button active />
              </SkeletonFormItem>
            </Col>
          </Row>
        </Col>
        {!isCreateForm && (
          <SkeletonFormItem>
            <Skeleton.Button active />
          </SkeletonFormItem>
        )}
      </Row>
    </div>
  );
};
