import { AbstractSearchableSelector } from 'common/components/Input/AbstractSearchableSelector';
import { EBaseOrderDirection } from 'common/models/requestModels';
import { IStoreCollectionFilter, IStoreModel } from 'entities/Store/Store.models';
import { storeTransport } from 'entities/Store/Store.transport';

interface IComponentProps {
  franchiseeId?: string;
}

export class StoreSelector extends AbstractSearchableSelector<IStoreModel, IComponentProps> {
  getItemLabel = (item: IStoreModel): string => {
    return item.storeName || item.id || 'Unknown store';
  };

  loadData = (value?: string): Promise<{ data: IStoreModel[] }> => {
    const { franchiseeId } = this.props;
    const filter: IStoreCollectionFilter = {
      franchiseeId,
      orderDirection: EBaseOrderDirection.ASC
    };
    if (value) {
      filter.search = value;
    }
    return storeTransport.getCollection(filter);
  };
}
