import { IBasePathParams } from 'common/models/requestModels';

export interface ICategoryModel extends Components.Schemas.CategoryDto {
  categoryName?: string;
}
export interface ICategoryCollection extends Components.Schemas.CategoryCollectionDto {}
export interface ICategoryUpdateData extends Components.Schemas.CategoryUpdateDto {
  id: string;
}
export interface ICategoryCreateData extends Components.Schemas.CategoryCreateDto {}
export interface ICategoryCollectionFilter extends Paths.CategoryControllerGetList.QueryParameters {}

export interface ICategoryParamsModel extends ICategoryCreateData, ICategoryUpdateData, IBasePathParams {}

export enum ECategorySuccessMessage {
  Delete = 'Category has been successfully deleted',
  Create = 'Category has been successfully created',
  Edit = 'Category has been successfully edited'
}

export const categoryTableConfig = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true
  }
];
export enum ECategoryType {
  IsAddOns = 'isAddOns',
  EnableAddOns = 'isAddOnsEnable',
  DisableAddOns = 'isAddOnsDisable'
}
export enum ECategoryTypeText {
  IsAddOns = 'Make items of this category add-ons',
  EnableAddOns = 'Enable add-ons selection for this category\n',
  DisableAddOns = 'None'
}
