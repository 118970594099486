import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { message } from 'antd';
import { IBasePathParams } from 'common/models/requestModels';
import { ERoutesPrivate } from 'common/models/routesModel';
import { storeTransport } from 'entities/Store/Store.transport';
import {
  EStoreSuccessMessage,
  IStoreCollection,
  IStoreCollectionFilter,
  IStoreModel,
  IStoreParams
} from 'entities/Store/Store.models';

const namespace = 'store';

export interface IStoresStoreProps {
  collection: StoreBranch<IStoreCollection, IStoreCollectionFilter>;
}

export interface IStoreConnectedProps {
  storeModel: StoreBranch<IStoreModel, IStoreParams>;
  storeCollection: StoreBranch<IStoreCollection, IStoreCollectionFilter>;

  getStoreModel(path: IBasePathParams): void;
  getByShortIdStoreModel(path: IBasePathParams): void;
  updateStoreModel(params: IStoreParams): void;
  addStoreModel(data: IStoreParams): void;
  deleteStoreModel(path: IBasePathParams): void;

  getStoreCollection(filter?: IStoreCollectionFilter): void;

  clearStoreModel(): void;
  clearStoreCollection(): void;
}

const StoreModelAPIProviders = [
  new APIProvider(EActionsTypes.get, storeTransport.get),
  new APIProvider('getByShortId', storeTransport.getByShortId),
  new APIProvider(EActionsTypes.update, storeTransport.update, {
    onSuccess: function*() {
      message.success(EStoreSuccessMessage.Edit);
      yield put(push(ERoutesPrivate.Stores));
    },
    clearParams: true
  }),
  new APIProvider(EActionsTypes.delete, storeTransport.delete, {
    onSuccess: function() {
      message.success(EStoreSuccessMessage.Delete);
    }
  }),
  new APIProvider(EActionsTypes.add, storeTransport.create, {
    onSuccess: function*() {
      message.success(EStoreSuccessMessage.Create);
      yield put(push(ERoutesPrivate.Stores));
    }
  })
];

const StoreCollectionAPIProviders = [new APIProvider(EActionsTypes.get, storeTransport.getCollection)];

const branches = [new Branch('model', StoreModelAPIProviders), new Branch('collection', StoreCollectionAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationStore = buildCommunication<IStoreConnectedProps>(strategy);

communicationStore.sagas.push();
