import React from 'react';
import window from 'global/window';
import { SelectProps } from 'antd/lib/select';
import { ELocationType, parseLocation } from 'common/helpers/location.helper';
import { COORDINATE_DEFAULT, mapZoom } from 'common/const/common.const';
import { IStoreLocation } from 'entities/Store/Store.models';

interface IComponentProps {
  onChange?: (value?: IStoreLocation) => void;
  value?: IStoreLocation;
}

type AllProps = IComponentProps & SelectProps<IStoreLocation>;

class MapPlacePickerComponent extends React.Component<AllProps> {
  componentDidMount() {
    this.initMap();
  }

  componentDidUpdate(prevProps: AllProps) {
    if (!prevProps.value && this.props.value) {
      this.updateMap(this.props.value);
    }
  }

  map: google.maps.Map | null = null;
  locationInput: HTMLElement | null = null;
  marker: google.maps.Marker | null = null;

  render() {
    return (
      <>
        <input id="search-box" className="map__input ant-input" />
        <div id="map" className="map__place-picker" />
      </>
    );
  }

  onChange = (value?: IStoreLocation) => {
    const { onChange } = this.props;

    if (onChange && value) {
      onChange(value);
    }
  };

  private initMap() {
    const { value } = this.props;
    const baseCoords = value
      ? new google.maps.LatLng(Number(value.lat), Number(value.lon))
      : new google.maps.LatLng(COORDINATE_DEFAULT.lat, COORDINATE_DEFAULT.lon);
    const mapOptions = {
      zoom: value ? mapZoom.specifiedLocation : mapZoom.default,
      center: baseCoords,
      panControl: false,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      overviewMapControl: false,
      rotateControl: false,
      clickableIcons: false
    };

    this.locationInput = document.getElementById('search-box');
    this.map = new google.maps.Map(window.document.getElementById('map'), mapOptions);
    this.marker = new google.maps.Marker({
      position: baseCoords,
      map: this.map
    });

    if (this.locationInput) {
      const types = ['geocode'];
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.locationInput);
      this.locationInput.addEventListener('keydown', event => {
        // Check "Enter" key. We need to avoid submitting form with map autocomplete
        if (event.keyCode === 13) {
          event.preventDefault();
        }
      });

      const autocomplete = new google.maps.places.Autocomplete(this.locationInput as HTMLInputElement, { types });
      autocomplete.bindTo('bounds', this.map);
      autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();

        if (!place.geometry) {
          return;
        }

        if (place.geometry.viewport) {
          this.map?.fitBounds(place.geometry.viewport);
        } else {
          this.map?.setCenter(place.geometry.location);
        }

        this.marker?.setPosition(place.geometry.location);

        const lat = this.marker?.getPosition()?.lat();
        const lng = this.marker?.getPosition()?.lng();

        if (lat && lng) {
          this.getLocationData(lat, lng);
        }
      });
    }

    // start listen on map click (user put new marker and pick place)
    this.listenOnClick(this.map, this.marker);
  }

  private updateMap(value: IStoreLocation) {
    const latLng = new google.maps.LatLng(Number(value.lat), Number(value.lon));
    this.map?.setOptions({ center: latLng, zoom: mapZoom.specifiedLocation });
    this.marker?.setPosition(latLng);
  }

  private listenOnClick(map: google.maps.Map, marker: google.maps.Marker | null) {
    google.maps.event.addListener(map, 'click', (e: google.maps.MapMouseEvent) => {
      marker?.setPosition(e.latLng);

      // get marker position and set center map on marker
      const lat = marker?.getPosition()?.lat();
      const lng = marker?.getPosition()?.lng();

      if (lat && lng) {
        map.setCenter({ lat, lng });
        // get location geo data by marker coords
        this.getLocationData(lat, lng);
      }
    });
  }

  private getLocationData(lat: number, lng: number) {
    const latLng = new google.maps.LatLng(lat, lng);
    const geoCoder = new google.maps.Geocoder();

    return geoCoder.geocode(
      { location: latLng },
      (response: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus): void => {
        if (status === google.maps.GeocoderStatus.OK) {
          const locationDataResponse = response[0];
          const areaLevelOne = parseLocation(locationDataResponse, ELocationType.RegionLevelOne);
          const areaLevelTwo = parseLocation(locationDataResponse, ELocationType.RegionLevelTwo);
          const placeId = locationDataResponse.place_id;
          const country = parseLocation(locationDataResponse, ELocationType.Country);
          const region = areaLevelOne || areaLevelTwo;
          const house = parseLocation(locationDataResponse, ELocationType.HouseNumber);
          const street = parseLocation(locationDataResponse, ELocationType.Street);
          const city = parseLocation(locationDataResponse, ELocationType.City);
          const locationValue: IStoreLocation = {
            lat: lat.toString(),
            lon: lng.toString(),
            placeId,
            country,
            region
          };

          if (house) {
            locationValue.house = house;
          }

          if (street && street !== 'Unnamed Road') {
            locationValue.street = street;
          }

          if (city) {
            locationValue.city = city;
          }

          this.onChange(locationValue);
        }
      }
    );
  }
}

export const MapPlacePicker = MapPlacePickerComponent;
