import { all, takeEvery, put } from 'redux-saga/effects';
import { message } from 'antd';
import { replace } from 'connected-react-router';
import { ECommonErrorCode, EErrorStatus } from 'common/models/requestModels';
import { ERoutesCommon } from 'common/models/routesModel';
import { communicationCategory } from 'entities/Category/Category.communication';
import { clearAuth, communicationAuth } from 'entities/Auth/Auth.communication';
import { communicationUser } from 'entities/User/User.communication';
import { communicationUI } from 'entities/UI/UI.communication';
import { communicationStore } from 'entities/Store/Store.communication';
import { communicationAsset } from 'entities/Asset/Asset.communication';
import { EAuthErrorMessage } from 'entities/Auth/Auth.models';
import { communicationMenuItem } from 'entities/MenuItem/MenuItem.communication';
import { communicationStatistic } from 'entities/Statistic/Statistic.communication';
import { communicationOrder } from 'entities/Order/Order.communication';
import { communicationCustomer } from 'entities/Customer/Customer.communication';
import { communicationRating } from 'entities/Rating/Rating.communication';
import { communicationPromo } from 'entities/Promo/Promo.communication';
import { communicationQR } from 'entities/QR/QR.communication';

function* errorWatcher() {
  yield takeEvery('*', function* logger(action: any) {
    if (action.type.match('FAIL')) {
      const status = action.payload && action.payload.status;

      switch (status) {
        case EErrorStatus.Unauthorized:
          yield clearAuth();
          break;
        case EErrorStatus.Forbidden:
          if (action?.payload?.data?.code === 'error.userInvalidCredentials') {
            message.error(EAuthErrorMessage.InvalidCreds);
          }
          if (action?.payload?.data?.code === 'error.userBlockedException') {
            message.error(EAuthErrorMessage.UserBlocked);
          }
          break;
        case EErrorStatus.NotConfirmedEmail:
          message.warning('Please check your email and confirm account!');
          break;
        case EErrorStatus.InternalServerError:
          message.warning('Something went wrong');
          break;
        case EErrorStatus.Validation:
          break;
        case EErrorStatus.PreconditionFailed:
          if (action?.payload?.data?.code === ECommonErrorCode.ReleaseVersionNotSupported) {
            message.warning('Something went wrong, reload page and try again');
          }
          if (action?.payload?.data?.code === ECommonErrorCode.ApiIsUnderMaintenance) {
            const { pathname: currentPath } = window.location;
            yield currentPath !== ERoutesCommon.TechnicalWork && put(replace(ERoutesCommon.TechnicalWork));
          }
          break;
        default:
          break;
      }
      console.log('ERROR', action.payload);
    }
  });
}

export default function* rootSaga(): any {
  yield all([
    errorWatcher(),
    ...communicationAuth.sagas,
    ...communicationUser.sagas,
    ...communicationUI.sagas,
    ...communicationStore.sagas,
    ...communicationAsset.sagas,
    ...communicationCategory.sagas,
    ...communicationMenuItem.sagas,
    ...communicationStatistic.sagas,
    ...communicationCustomer.sagas,
    ...communicationOrder.sagas,
    ...communicationRating.sagas,
    ...communicationPromo.sagas,
    ...communicationQR.sagas
  ]);
}
