import React from 'react';
import { Col, Row } from 'antd';

export const PublicLayout: React.FC = props => {
  const { children } = props;

  return (
    <Row justify="center" className="width-full basic__layout_m-t">
      <Col xs={18} sm={14} md={10} lg={5}>
        {children}
      </Col>
    </Row>
  );
};
