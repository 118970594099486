import React from 'react';
import { Col, Row, Skeleton } from 'antd';
import { SkeletonFormItem } from 'common/components/Skeleton/SkeletonFormItem';

interface IComponentProps {
  isEdit: boolean;
}

export const MenuItemFormSkeleton: React.FC<IComponentProps> = props => {
  const { isEdit } = props;

  return (
    <div className="mt-5">
      <Row gutter={[28, 0]}>
        <Col span={12}>
          <SkeletonFormItem label="Category">
            <Skeleton.Input active />
          </SkeletonFormItem>
        </Col>
        <Col span={12}>
          <SkeletonFormItem label="Item name">
            <Skeleton.Input active />
          </SkeletonFormItem>
        </Col>
        <Col span={24}>
          <SkeletonFormItem label="Description">
            <Skeleton.Input active />
          </SkeletonFormItem>
        </Col>
      </Row>

      <SkeletonFormItem label="Image">
        <Skeleton.Image />
      </SkeletonFormItem>

      <Row className="flex-noWrap" justify="space-between" align="middle">
        <Col span={16}>
          <Skeleton.Button className="mr-5" />
          <Skeleton.Button />
        </Col>
        {isEdit && (
          <Col>
            <Skeleton.Button />
          </Col>
        )}
      </Row>
    </div>
  );
};
