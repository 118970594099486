import React from 'react';
import { Col, Row } from 'antd';
import MbGo from 'app/assets/images/mbGo.png';
import TechWork from 'app/assets/images/techWork.png';

export const TechnicalWorkPage: React.FC = () => {
  return (
    <Row className="tech-work-page" justify="center">
      <Col xl={10} className="tech-work-page__text-block">
        <img src={MbGo} alt="Logo" className="tech-work-page__logo" />
        <div className="tech-work-page__header">is temporarily unavailable.</div>
        <div className="tech-work-page__subheader">
          We are working on this problem and will be back soon. Thank you for your understanding.
        </div>
      </Col>
      <Col xl={14}>
        <img src={TechWork} alt="Technical work" className="tech-work-page__tech-work-img" />
      </Col>
    </Row>
  );
};
