export interface IOrderModel extends Components.Schemas.OrderDto {}
export interface IOrderItemsModel extends Components.Schemas.OrderItemDto {}
export interface IOrderCollection extends Components.Schemas.OrderCollectionDto {}
export interface IOrderCollectionFilter extends Paths.OrderControllerGetList.QueryParameters {}

export enum EOrderRejectReason {
  ChangeProduct = 'changeProduct',
  ChangeQuantity = 'changeQuantity',
  ChangeTime = 'changeTime',
  WrongLocation = 'wrongLocation',
  WaitedTooLong = 'waitedTooLong',
  StoreTooBusy = 'storeTooBusy',
  ProductsUnavailable = 'productsUnavailable',
  Abandoned = 'abandoned',
  DeviceUnattended = 'deviceUnattended',
  Other = 'other'
}
export enum EOrderRejectReasonText {
  ChangeProduct = 'Will change product',
  ChangeQuantity = 'Will change quantity',
  ChangeTime = 'Will change pickup time',
  WrongLocation = 'Set wrong location',
  WaitedTooLong = 'Waited too long',
  StoreTooBusy = 'Store too Busy',
  ProductsUnavailable = 'Product(s) Unavailable',
  DeviceUnattended = 'Device Unattended',
  Abandoned = 'Abandoned'
}
