import { APIProvider, BaseStrategy, Branch, buildCommunication, EActionsTypes, StoreBranch } from '@axmit/redux-communications';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { message } from 'antd';
import { ERoutesPrivate } from 'common/models/routesModel';
import { qrTransport } from 'entities/QR/QR.transport';
import { IQRCollection, IQRCollectionFilter, IQRCreateData, IQRModel, IQRParams, EQRSuccessMessage } from 'entities/QR/QR.models';

const namespace = 'qr';

export interface IQRConnectedProps {
  qrModel: StoreBranch<IQRModel>;
  addQrModel(data: IQRCreateData): void;
  updateQrModel(params: IQRParams): void;
  getQrModel(id: string): void;
  deleteQrModel(id: string): void;
  clearQrModel(): void;

  qrCollection: StoreBranch<IQRCollection>;
  getQrCollection(params?: IQRCollectionFilter): void;
  clearQrCollection(): void;
}

const modelApiProviders = [
  new APIProvider(EActionsTypes.get, qrTransport.get),
  new APIProvider(EActionsTypes.update, qrTransport.update, {
    onSuccess: function*() {
      message.success(EQRSuccessMessage.Edit);
      yield put(push(ERoutesPrivate.QR));
    }
  }),
  new APIProvider(EActionsTypes.delete, qrTransport.delete, {
    onSuccess: function*() {
      message.success(EQRSuccessMessage.Delete);
      yield put(push(ERoutesPrivate.QR));
    }
  }),
  new APIProvider(EActionsTypes.add, qrTransport.add, {
    onSuccess: function*() {
      message.success(EQRSuccessMessage.Create);
      yield put(push(ERoutesPrivate.QR));
    }
  })
];

const collectionApiProviders = [new APIProvider(EActionsTypes.get, qrTransport.getCollection)];

const branches = [new Branch('model', modelApiProviders), new Branch('collection', collectionApiProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationQR = buildCommunication<IQRConnectedProps>(strategy);
