import React from 'react';
import { InfiniteList } from 'common/components/List/InfiniteList';
import { EBaseOrderDirection, IBaseFilterModel } from 'common/models/requestModels';
import { communicationOrder, IOrderConnectedProps } from 'entities/Order/Order.communication';
import { IOrderCollection, IOrderCollectionFilter, IOrderModel } from 'entities/Order/Order.models';
import { OrderListItem } from 'entities/Order/components/OrderListItem';

interface IComponentProps {
  showCreateDate?: boolean;
  filter: IOrderCollectionFilter;
}

type AllProps = IOrderConnectedProps & IComponentProps;

class OrderListComponent extends InfiniteList<IOrderCollection, IOrderModel, AllProps, IOrderCollectionFilter> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getOrderCollection, filter } = this.props;
    const filterParams: IOrderCollectionFilter = {
      recentOnly: true,
      orderField: 'pickUpTime',
      orderDirection: EBaseOrderDirection.ASC,
      ...filter,
      ...params
    };
    getOrderCollection(filterParams);
  };

  renderListItem = (item: IOrderModel) => {
    const { showCreateDate = false } = this.props;
    return <OrderListItem item={item} showCreateDate={showCreateDate} />;
  };

  getCollection = () => {
    return this.props.orderCollection;
  };
  clearCollection = () => {
    this.props.clearOrderCollection();
  };
}

export const OrderList = communicationOrder.injector(OrderListComponent);
