import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { put } from 'redux-saga/effects';
import { message } from 'antd';
import { push } from 'connected-react-router';
import { IBasePathParams } from 'common/models/requestModels';
import { ERoutesPrivate } from 'common/models/routesModel';
import { userTransport } from 'entities/User/User.transport';
import {
  EUserSuccessMessage,
  IUserCollection,
  IUserCollectionFilter,
  IUserInviteData,
  IUserModel,
  IUserModelParams,
  IUserUpdateData,
  IUserUpdatePasswordData
} from 'entities/User/User.models';

const namespace = 'user';

export interface IUserStoreProps {
  collection: StoreBranch<IUserCollection, IUserCollectionFilter>;
}

export interface IUserConnectedProps {
  userModel: StoreBranch<IUserModel, IUserModelParams>;
  userCollection: StoreBranch<IUserCollection, IUserCollectionFilter>;

  getUserModel(path: IBasePathParams): void;
  updateUserModel(params: { path: IBasePathParams; data: IUserUpdateData }): void;
  inviteUserModel(params: { data: IUserInviteData }): void;
  updatePasswordUserModel(params: { path: IBasePathParams; data: IUserUpdatePasswordData }): void;
  reInviteUserModel(path: IBasePathParams): void;
  deleteUserModel(path: IBasePathParams): void;

  getUserCollection(filter?: IUserCollectionFilter): void;
  clearUserModel(): void;
  clearUserCollection(): void;
}

const UserModelAPIProviders = [
  new APIProvider(EActionsTypes.get, userTransport.get),
  new APIProvider(EActionsTypes.update, userTransport.update, {
    preRequestDataMapper: function(
      response: null,
      params: IUserModelParams | undefined,
      branch: StoreBranch<IUserModel | null, IUserModelParams | undefined>
    ) {
      return branch?.data;
    },
    onSuccess: function() {
      message.success(EUserSuccessMessage.Edit);
    }
  }),
  new APIProvider(EActionsTypes.delete, userTransport.delete, {
    onSuccess: function*() {
      message.success(EUserSuccessMessage.Delete);
      yield put(push(ERoutesPrivate.Users));
    }
  }),
  new APIProvider('invite', userTransport.invite, {
    onSuccess: function*() {
      message.success(EUserSuccessMessage.Invite);
      yield put(push(ERoutesPrivate.Users));
    }
  }),
  new APIProvider('updatePassword', userTransport.updatePassword, {
    onSuccess: function() {
      message.success(EUserSuccessMessage.ChangePassword);
    }
  }),
  new APIProvider('reInvite', userTransport.reInvite, {
    onSuccess: function*() {
      message.success(EUserSuccessMessage.ReInviteSend);
      yield put(push(ERoutesPrivate.Users));
    }
  })
];

const UserCollectionAPIProviders = [new APIProvider(EActionsTypes.get, userTransport.getCollection)];

const branches = [new Branch('model', UserModelAPIProviders), new Branch('collection', UserCollectionAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationUser = buildCommunication<IUserConnectedProps>(strategy);
