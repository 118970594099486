import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import debounce from 'lodash.debounce';
import { ItemsNotFound } from 'common/components/Text/ItemsNotFound';

interface IComponentProps<Model> {
  onChange?: (item?: Model) => void;
  placeholder?: string;
  disabled?: boolean;
  defaultOpt?: Partial<Model>;
  allowClear?: boolean;
}

type AllProps<Model> = SelectProps<any> & IComponentProps<Model>;

interface IComponentState<Model> {
  data: Model[];
  value: Model | undefined;
}

export abstract class AbstractSearchableSelector<Model extends { id: string }, IProps> extends React.PureComponent<
  AllProps<Model> & IProps
> {
  state: IComponentState<Model> = {
    data: [],
    value: this.props.value
  };

  readonly searchDebounced: any;

  protected constructor(props: AllProps<Model> & IProps) {
    super(props);
    this.searchDebounced = debounce(this.handleSearch, 200);
  }

  static getDerivedStateFromProps<Model, IProps>(props: AllProps<Model> & IProps, state: IComponentState<Model>) {
    if (!props.value?.id && state.value) {
      return { value: undefined };
    }

    if (props.value && !state.value) {
      return { value: props.value };
    }

    return null;
  }

  async componentDidMount() {
    await this.getCollection();
  }

  getCollection = async () => {
    const { data } = await this.loadData();
    this.setState({ data });
  };

  handleSearch = async (value?: string) => {
    const { data } = await this.loadData(value);

    this.setState({ data });
  };

  handleChange = async (id: string | number) => {
    const { onChange } = this.props;
    const value = this.state.data.find(el => el.id === id);
    this.setState({ value }, () => onChange && onChange(value));
  };

  render() {
    const { placeholder, disabled, defaultOpt, allowClear = true } = this.props;
    const { value, data } = this.state;
    const defaultOptId = defaultOpt?.id;
    const showDefaultOpt = value?.id !== defaultOptId;

    const exist = data.find(el => el.id === value?.id);

    if (!exist && value) {
      data.unshift(value);
    }

    const options = data.map(option => (
      <Select.Option key={option.id} value={option.id}>
        {this.getItemLabel(option)}
      </Select.Option>
    ));

    return data ? (
      <Select
        allowClear={allowClear}
        className="width-full"
        placeholder={placeholder}
        disabled={disabled}
        showSearch
        value={value?.id || defaultOpt?.id}
        defaultActiveFirstOption={false}
        showArrow={true}
        filterOption={false}
        onFocus={() => this.searchDebounced('')}
        onSearch={this.searchDebounced}
        onChange={this.handleChange}
        notFoundContent={<ItemsNotFound />}
        getPopupContainer={triggerNode => triggerNode as HTMLElement}
      >
        {!!defaultOptId && showDefaultOpt && (
          <Select.Option key={defaultOptId} value={defaultOptId}>
            {defaultOptId}
          </Select.Option>
        )}
        {options}
      </Select>
    ) : (
      <div />
    );
  }

  abstract getItemLabel: (item: Model) => string;
  abstract loadData: (value?: string) => Promise<{ data: Model[] }>;
}
