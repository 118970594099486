import {
  EPromoBonusType,
  EPromoBonusTypeText,
  EPromoCriteriaType,
  EPromoCriteriaTypeText,
  EPromoCustomerSelectType,
  EPromoCustomerSelectTypeText,
  EPromoType,
  EPromoTypeText
} from 'entities/Promo/Promo.models';

export const selectBonus = (bonus?: EPromoBonusType): any => {
  switch (bonus) {
    case EPromoBonusType.DiscountAllPercent:
      return EPromoBonusTypeText.DiscountAllPercent;

    case EPromoBonusType.DiscountAllPeso:
      return EPromoBonusTypeText.DiscountAllPeso;

    case EPromoBonusType.DiscountItem:
      return EPromoBonusTypeText.DiscountItem;

    case EPromoBonusType.Additional:
      return EPromoBonusTypeText.Additional;

    case EPromoBonusType.AdditionalNotOnMenu:
      return EPromoBonusTypeText.AdditionalNotOnMenu;

    default:
      return 'Unknown bonus';
  }
};
export const selectCriteria = (bonus?: EPromoCriteriaType): any => {
  switch (bonus) {
    case EPromoCriteriaType.MenuItem:
      return EPromoCriteriaTypeText.MenuItem;

    case EPromoCriteriaType.Category:
      return EPromoCriteriaTypeText.Category;

    case EPromoCriteriaType.Price:
      return EPromoCriteriaTypeText.Price;
    case EPromoCriteriaType.EveryXPesos:
      return EPromoCriteriaTypeText.EveryXPesos;
    default:
      return 'Unknown criteria';
  }
};
export const selectPromoType = (promoType?: string): EPromoTypeText =>
  promoType === EPromoType.Code ? EPromoTypeText.Code : EPromoTypeText.Action;
export const selectCustomerType = (customerType?: string): EPromoCustomerSelectTypeText => {
  switch (customerType) {
    case EPromoCustomerSelectType.New:
      return EPromoCustomerSelectTypeText.New;
    case EPromoCustomerSelectType.All:
      return EPromoCustomerSelectTypeText.All;
    default:
    case EPromoCustomerSelectType.Specific:
      return EPromoCustomerSelectTypeText.Specific;
  }
};
