import React from 'react';
import { configureAxiosJWTInterseptors, ICreds } from '@axmit/axios-patch-jwt';
import axios from 'axios';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { IAsyncStorage, WebStorageDecorator } from 'universal-storage';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import window from 'global/window';
import { envConfig } from 'common/config/env.config';
import createStore from 'app/store/createStore';
import 'app/assets/sass/index.scss';
import { errorMapper } from '@axmit/error-helper';
import { App } from 'app/App';
import { EActionsTypes, getSuccessType } from '@axmit/redux-communications';

// Sentry initialization
Sentry.init({
  dsn: envConfig.SENTRY_DSN || envConfig.REACT_APP_SENTRY_DSN,
  environment: envConfig.NODE_ENV,
  ignoreErrors: ['ResizeObserver loop limit exceeded']
});

window.checkSentry = (text?: string) => {
  const msg = `Sentry successfully connected. Config: ${JSON.stringify(envConfig)} ${new Date()}. Test string: ${text}`;
  console.log('Go to slack channel and check an error with message: ');
  console.log(msg);
  Sentry.captureException(msg);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState = (window as any).___INITIAL_STATE__;
const history = createBrowserHistory();
const store = createStore(initialState, history);

// Axios initialization

const storage: IAsyncStorage = new WebStorageDecorator(localStorage);

export const axiosConfig = {
  storage,
  axios,
  refreshTokenEndpoint: '/auth',
  onSaveCreds: (creds: ICreds) => {
    store.dispatch({ type: getSuccessType('auth', 'model', EActionsTypes.add), payload: creds });
  }
};

configureAxiosJWTInterseptors(axiosConfig);

axios.defaults.baseURL = `/api`;
axios.interceptors.request.use(config => ({
  ...config,
  headers: {
    'Source-Service': 'manager',
    'x-client-version': envConfig.RELEASE_VERSION || envConfig.REACT_APP_RELEASE_VERSION || '',
    ...config.headers
  }
}));
axios.interceptors.response.use(
  response => response.data,
  error => errorMapper(error)
);
// Render Setup

const MOUNT_NODE = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  MOUNT_NODE
);
