import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EPromoBonusType, EPromoBonusTypeText } from 'entities/Promo/Promo.models';

interface IComponentProps {
  isEveryXPesos?: boolean;
  onChange?: (value: EPromoBonusType) => void;
  placeholder?: string;
  allowClear?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class PromoBonusTypeSelector extends React.PureComponent<AllProps> {
  handleChange = (value: EPromoBonusType) => {
    const { onChange } = this.props;
    onChange && onChange(value);
  };

  render() {
    const { value, defaultValue, placeholder, allowClear, isEveryXPesos } = this.props;

    return (
      <Select
        className="width-full"
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        showArrow
        allowClear={allowClear}
        onChange={(value: EPromoBonusType) => this.handleChange(value)}
      >
        {!isEveryXPesos && (
          <>
            <Select.Option
              key={EPromoBonusType.DiscountAllPeso}
              value={EPromoBonusType.DiscountAllPeso}
              label={EPromoBonusTypeText.DiscountAllPeso}
            >
              {EPromoBonusTypeText.DiscountAllPeso}
            </Select.Option>
            <Select.Option
              key={EPromoBonusType.DiscountAllPercent}
              value={EPromoBonusType.DiscountAllPercent}
              label={EPromoBonusTypeText.DiscountAllPercent}
            >
              {EPromoBonusTypeText.DiscountAllPercent}
            </Select.Option>
          </>
        )}
        <Select.Option
          key={EPromoBonusType.DiscountItem}
          value={EPromoBonusType.DiscountItem}
          label={EPromoBonusTypeText.DiscountItem}
        >
          {EPromoBonusTypeText.DiscountItem}
        </Select.Option>
        <Select.Option key={EPromoBonusType.Additional} value={EPromoBonusType.Additional} label={EPromoBonusTypeText.Additional}>
          {EPromoBonusTypeText.Additional}
        </Select.Option>
        <Select.Option
          key={EPromoBonusType.AdditionalNotOnMenu}
          value={EPromoBonusType.AdditionalNotOnMenu}
          label={EPromoBonusTypeText.AdditionalNotOnMenu}
        >
          {EPromoBonusTypeText.AdditionalNotOnMenu}
        </Select.Option>
      </Select>
    );
  }
}
