import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { AssetChildSortableTable } from 'entities/Asset/components/DnD/AssetChildSortableTable';
import { IAssetCategories } from 'entities/Asset/Asset.models';

interface IComponentProps {
  value?: IAssetCategories[];
  onChange?: (collection?: IAssetCategories[]) => void;
}

type AllProps = IComponentProps;

const CategoryTable = SortableElement(
  ({ item, onItemChange }: { item: IAssetCategories; onItemChange: (categoryItem: IAssetCategories) => void }) => {
    return (
      <li key={item.id}>
        <AssetChildSortableTable item={item} onItemChange={onItemChange} />
      </li>
    );
  }
);

const CategoryList = SortableContainer(({ items, onItemChange }: any) => {
  return (
    <ul className="p-0">
      {items?.map((item: any, index: number) => (
        <CategoryTable key={item.id} index={index} item={item} onItemChange={onItemChange} />
      ))}
    </ul>
  );
});

export class AssetSortableParent extends React.PureComponent<AllProps> {
  render() {
    const { value } = this.props;

    return <CategoryList items={value} onSortEnd={this.onSortEnd} onItemChange={this.onItemChange} />;
  }
  onSortEnd = ({ oldIndex, newIndex }: any) => {
    const { onChange, value } = this.props;

    if (oldIndex !== newIndex) {
      const newData: any = arrayMove([].concat(value as any), oldIndex, newIndex)
        .filter(el => !!el)
        .map((item: IAssetCategories, index) => ({ ...item, orderNumber: index }));

      onChange && onChange(newData);
    }
  };
  onItemChange = (categoryItem: IAssetCategories) => {
    const { onChange, value } = this.props;
    const newDataState: IAssetCategories[] | undefined = value?.map(item => (item.id === categoryItem.id ? categoryItem : item));

    onChange && onChange(newDataState);
  };
}
