import React, { useState } from 'react';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, message, Row, Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { EFileStatus } from 'common/components/Image/Image.models';
import { EErrorStatus } from 'common/models/requestModels';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

interface IComponentProps {
  disabled?: boolean;
  onChange?: (file: UploadFile | null) => void;
  value?: UploadFile;
}

type AllProps = IAuthConnectedProps & IComponentProps;

const CSVUploaderComponent: React.FC<AllProps> = props => {
  const [file, setFile] = useState<UploadFile | null>(null);
  const { authModel, value } = props;
  const token = authModel.data?.access.token;
  const csvFileName = value?.name || file?.name || null;

  const handleDelete = () => {
    const { onChange } = props;
    setFile(null);
    onChange && onChange(null);
  };

  const handleChange = (info: UploadChangeParam<UploadFile>) => {
    const { file } = info;
    const { status, response } = file;
    const { onChange } = props;

    if (file.status === EFileStatus.Removed) {
      setFile(null);
      onChange && onChange(null);
      return;
    }

    if (status === EFileStatus.Done) {
      const imageId = response?.id;
      file.uid = imageId;

      setFile(file);
      onChange && onChange(file);
      return;
    }

    if (status === EFileStatus.Error && file.error.status === EErrorStatus.Unauthorized) {
      message.warning('Something went wrong, reload page and try again');
    }
  };

  return (
    <>
      <Upload
        accept=".csv"
        action="/api/files"
        headers={{ Authorization: `Bearer ${token}` }}
        onChange={handleChange}
        showUploadList={false}
      >
        <Button icon={<UploadOutlined />} className="width-full">
          {' '}
          Upload CSV
        </Button>
      </Upload>
      {csvFileName && (
        <Row className="mt-5">
          <Row align="middle">
            <CloseOutlined type="close" className="mr-3" onClick={handleDelete} />
            <span className="basic__text_paragraph">{csvFileName}</span>
          </Row>
        </Row>
      )}
    </>
  );
};

export const CSVUploader = communicationAuth.injector(CSVUploaderComponent);
