import { IBasePathParams } from 'common/models/requestModels';

export interface IUserModel extends Components.Schemas.UserDto {
  name?: string;
  mappedRole?: string;
}
export interface IUserCollection extends Components.Schemas.UserCollection {}
export interface IUserUpdateData extends Components.Schemas.UpdateUserDto {}
export interface IUserInviteData extends Components.Schemas.CreateUserDto {}
export interface IUserUpdatePasswordData extends Components.Schemas.UpdatePasswordDto {}
export interface IUserConfirmData extends Components.Schemas.JoinUserDto {}
export interface IFranchiseeData extends Components.Schemas.ShortFranchiseeDto {}
export interface IUserCollectionFilter extends Paths.UserControllerList.QueryParameters {
  pageIndex?: number;
}

export interface IUserModelParams {
  path?: IBasePathParams;
  data?: IUserInviteData | IUserUpdateData | IUserUpdatePasswordData;
}

export enum EUserRole {
  Franchisor = 'franchisor',
  Franchisee = 'franchisee',
  Manager = 'manager',
  SalesOps = 'salesop',
  Customer = 'customer'
}
export enum EUserRoleText {
  Franchisor = 'Franchisor',
  Franchisee = 'Franchisee',
  Manager = 'Manager',
  SalesOps = 'Sales Ops',
  Customer = 'Customer'
}

export const roleDict = {
  [EUserRole.Franchisor]: EUserRoleText.Franchisor,
  [EUserRole.Franchisee]: EUserRoleText.Franchisee,
  [EUserRole.SalesOps]: EUserRoleText.SalesOps,
  [EUserRole.Manager]: EUserRoleText.Manager,
  [EUserRole.Customer]: EUserRoleText.Customer
};

export enum EUserStatus {
  Active = 'active',
  Blocked = 'blocked',
  Pending = 'pending'
}
export enum EUserStatusText {
  Active = 'Active',
  Blocked = 'Blocked',
  Pending = 'Pending'
}

export const tableUserConfig = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    ellipsis: true
  },
  {
    title: 'Phone Number',
    dataIndex: 'phone',
    key: 'phone',
    ellipsis: true
  },
  {
    title: 'Role',
    dataIndex: 'mappedRole',
    key: 'mappedRole',
    ellipsis: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true
  }
];

export enum EUserSuccessMessage {
  ChangePassword = 'Password successfully changed',
  ReInviteSend = 'Invitation has been sent to the specified email',
  Delete = 'Invitation has been successfully cancelled',
  Invite = 'Employee has been successfully invited',
  Edit = 'Employee has been successfully edited'
}
