import React, { useEffect } from 'react';
import { Button, Form, Input, Row } from 'antd';
import { useFormValidation } from '@axmit/antd4-helpers';
import { communicationUser, IUserConnectedProps } from 'entities/User/User.communication';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

interface IComponentProps {
  isFranchisee?: boolean;
}
type AllProps = IUserConnectedProps & IAuthConnectedProps & IComponentProps;

const UserProfileFormComponent: React.FunctionComponent<AllProps> = props => {
  const { updatePasswordUserModel, userModel, authModel, clearUserModel, isFranchisee = false } = props;
  const { loading, errors, params } = userModel;
  const { data: authData } = authModel;
  const userId = authData?.access?.userId;

  const { fields } = useFormValidation(['oldPassword', 'password'], params?.data, errors);

  useEffect(() => {
    return () => clearUserModel();
  }, [clearUserModel]);

  const changePassword = (values: any) => {
    if (userId) {
      updatePasswordUserModel({ path: { id: userId }, data: { oldPassword: values.oldPassword, password: values.password } });
    }
  };

  return (
    <>
      <Row justify="center" className="mb-4">
        <h1 className={`${isFranchisee ? 'franchisee__text_title' : 'basic__text_title'} fs-md`} title="Change password">
          Change password
        </h1>
      </Row>
      <Form onFinish={changePassword} fields={fields}>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Old password is required'
            }
          ]}
          name="oldPassword"
          label="Old password"
        >
          <Input.Password name="oldPassword" type="password" disabled={loading} placeholder="Old password" />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: 'New password is required'
            },
            {
              min: 8,
              message: 'New password must be at least 8 characters'
            },
            {
              max: 16,
              message: 'New password must be shorter than or equal 16 characters'
            }
          ]}
          name="password"
          label="New password"
        >
          <Input.Password name="newPassword" type="password" disabled={loading} placeholder="New password" />
        </Form.Item>

        <Form.Item className="mb-0">
          <Button htmlType="submit" type="primary" className="button_default" disabled={loading} loading={loading} title="Change">
            Change
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export const UserProfileForm = communicationAuth.injector(communicationUser.injector(UserProfileFormComponent));
