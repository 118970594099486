import React from 'react';
import { Row } from 'antd';
import { ReactComponent as StarIcon } from 'app/assets/icons/star.svg';

interface IComponentProps {
  friendly?: number | null;
  quality?: number | null;
  serve?: number | null;
}

export const DetailedRatingBlock: React.FC<IComponentProps> = props => {
  const { friendly, quality, serve } = props;
  const formattedFriendly = friendly?.toFixed(1) || 0;
  const formattedQuality = quality?.toFixed(1) || 0;
  const formattedServe = serve?.toFixed(1) || 0;

  return (
    <div className="mb-5">
      <Row align="middle" className="fs-xs">
        <StarIcon /> <span className="font-weight-7 ml-2 mr-3">{formattedQuality}</span>
        <span>Food quality</span>
      </Row>
      <Row align="middle" className="fs-xs">
        <StarIcon /> <span className="font-weight-7 ml-2 mr-3">{formattedFriendly}</span>
        <span>Responsiveness</span>
      </Row>
      <Row align="middle" className="fs-xs">
        <StarIcon /> <span className="font-weight-7 ml-2 mr-3">{formattedServe}</span>
        <span>Service</span>
      </Row>
    </div>
  );
};
