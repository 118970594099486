import React from 'react';
import { Button, Col, Form, Input, message, Popconfirm, Row } from 'antd';
import { useFormMapper } from '@axmit/antd4-helpers';
import { Link } from 'react-router-dom';
import { StoreBranch } from '@axmit/redux-communications';
import { ERoutesPrivate } from 'common/models/routesModel';
import { EMenuPageTabKeys } from 'app/pages/Franchisor/MenuPage';
import { IAssetCreateData, IAssetModel, IAssetParamsModel, IAssetUpdateData } from 'entities/Asset/Asset.models';
import { AssetSortableParent } from 'entities/Asset/components/DnD/AssetSortableParent';

interface IComponentProps {
  createAsset?: (data: IAssetCreateData) => void;
  updateAsset?: (data: IAssetUpdateData) => void;
  deleteAsset?: (id: string) => void;
  assetModel: StoreBranch<IAssetModel, IAssetParamsModel>;
  assetId?: string;
}

type AllProps = IComponentProps;

const AssetFormComponent: React.FC<AllProps> = props => {
  const { assetModel, assetId, createAsset, updateAsset, deleteAsset } = props;
  const isUpdateForm = !!assetId;
  const { data: assetData, params, errors, loading } = assetModel;
  const { fields } = useFormMapper(['name', 'categoriesToAsset'], assetData, params, errors);
  const submitButtonText = isUpdateForm ? 'Save' : 'Create asset';

  const addAsset = (values: any) => {
    const { name } = values;
    const vals: IAssetCreateData = {
      name
    };

    createAsset && createAsset(vals);
  };

  const editAsset = (values: any) => {
    const { name, categoriesToAsset } = values;

    const isEmptyPriceExists = (values as IAssetModel).categoriesToAsset.some(item =>
      item.menuItemsToAsset.some(assetMenuItem => isNaN(assetMenuItem.price))
    );

    if (isEmptyPriceExists) {
      message.error('Please fill in all the prices');
      return;
    }

    if (assetId) {
      const vals: IAssetUpdateData = {
        name,
        id: assetId,
        categoriesToAsset
      };

      updateAsset && updateAsset(vals);
    }
  };
  const removeAsset = () => {
    if (assetId) {
      deleteAsset && deleteAsset(assetId);
    }
  };

  return (
    <Form onFinish={isUpdateForm ? editAsset : addAsset} fields={fields}>
      <Form.Item
        rules={[
          {
            required: true,
            message: 'Asset name is required'
          },
          {
            min: 2,
            message: 'Asset name must be longer than or equal 2 characters'
          },
          {
            max: 100,
            message: 'Asset name must be shorter than or equal 100 characters'
          }
        ]}
        name="name"
        label="Asset name"
      >
        <Input name="assetName" type="text" placeholder="Asset Name" disabled={loading} />
      </Form.Item>
      {isUpdateForm && (
        <Form.Item name="categoriesToAsset">
          <AssetSortableParent />
        </Form.Item>
      )}
      <Row justify="space-between" align="middle">
        <Col span={16}>
          <Form.Item className="mb-0">
            <Button
              className="mr-8"
              htmlType="submit"
              type="primary"
              disabled={loading}
              loading={loading}
              title={submitButtonText}
            >
              {submitButtonText}
            </Button>
            <Link to={`${ERoutesPrivate.Menu}?tab=${EMenuPageTabKeys.Assets}`}>
              <Button disabled={loading} loading={loading} title="Cancel">
                Cancel
              </Button>
            </Link>
          </Form.Item>
        </Col>
        {isUpdateForm && (
          <Popconfirm
            disabled={loading}
            title="Are you sure you want to delete the asset?"
            okType="danger"
            onConfirm={removeAsset}
            okText="Delete"
          >
            <Button danger disabled={loading} loading={loading} title="Delete">
              Delete
            </Button>
          </Popconfirm>
        )}
      </Row>
    </Form>
  );
};

export const AssetForm = AssetFormComponent;
