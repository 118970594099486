import React, { useMemo } from 'react';
import { Button, Col, Form, Input, Popconfirm, Row } from 'antd';
import { useFormMapper } from '@axmit/antd4-helpers';
import { Link } from 'react-router-dom';
import { StoreBranch } from '@axmit/redux-communications';
import { formRules } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ImageUpload } from 'common/components/Image/ImageUpload';
import { EMenuPageTabKeys } from 'app/pages/Franchisor/MenuPage';
import { CategorySelector } from 'entities/Category/components/Selector/CategorySelector';
import {
  IMenuItemCreateData,
  IMenuItemModel,
  IMenuItemParamsModel,
  IMenuItemUpdateData
} from 'entities/MenuItem/MenuItem.models';

interface IComponentProps {
  createMenuItem?: (data: IMenuItemCreateData) => void;
  updateMenuItem?: (data: IMenuItemUpdateData) => void;
  deleteMenuItem?: () => void;
  menuItemModel: StoreBranch<IMenuItemModel, IMenuItemParamsModel>;
  menuItemId?: string;
}

const MenuItemFormComponent: React.FC<IComponentProps> = props => {
  const { menuItemModel, createMenuItem, updateMenuItem, menuItemId, deleteMenuItem } = props;
  const { errors, loading, data, params } = menuItemModel;
  const isUpdateForm = !!menuItemId;
  const submitButtonText = isUpdateForm ? 'Save' : ' Add';

  const memoizedValue = useMemo(() => ({ ...params, categoryData: params?.category ? { id: params?.category } : undefined }), [
    params
  ]);
  const paramsValue = params?.name ? memoizedValue : params;

  const { fields } = useFormMapper(['categoryData', 'name', 'description', 'image'], data, paramsValue, errors);

  const addMenuItem = (values: any) => {
    const { name, description, categoryData, image } = values;
    const newData: IMenuItemCreateData = {
      name,
      description,
      image,
      category: categoryData?.id
    };

    createMenuItem && createMenuItem(newData);
  };

  const editMenuItem = (values: any) => {
    const { name, description, image } = values;

    if (menuItemId && updateMenuItem) {
      const newData: IMenuItemUpdateData = {
        id: menuItemId,
        name,
        description,
        image
      };

      updateMenuItem(newData);
    }
  };

  return (
    <Form className="mt-5" onFinish={isUpdateForm ? editMenuItem : addMenuItem} fields={fields}>
      <Row gutter={[28, 0]}>
        <Col span={12}>
          <Form.Item rules={[formRules.required]} name="categoryData" label="Category">
            <CategorySelector placeholder="Choose category" disabled={loading || isUpdateForm} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[
              formRules.required,
              {
                min: 2,
                message: 'Item name must be longer than or equal 2 characters'
              },
              {
                max: 100,
                message: 'Item name must be shorter than or equal 100 characters'
              }
            ]}
            name="name"
            label="Item name"
          >
            <Input placeholder="Item name" name="name" type="text" disabled={loading} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            rules={[
              {
                min: 2,
                message: 'Description must be longer than or equal 2 characters'
              },
              {
                max: 400,
                message: 'Description must be shorter than or equal 400 characters'
              }
            ]}
            name="description"
            label="Description"
          >
            <Input.TextArea placeholder="Description" name="description" disabled={loading} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item rules={[formRules.required]} name="image" label="Image">
        <ImageUpload />
      </Form.Item>
      <Row justify="space-between" align="middle">
        <Col span={16}>
          <Form.Item className="mb-0">
            <Button
              className="mr-8"
              htmlType="submit"
              type="primary"
              disabled={loading}
              loading={loading}
              title={submitButtonText}
            >
              {submitButtonText}
            </Button>
            <Link to={`${ERoutesPrivate.Menu}?tab=${EMenuPageTabKeys.Items}`}>
              <Button disabled={loading} loading={loading} title="Cancel">
                Cancel
              </Button>
            </Link>
          </Form.Item>
        </Col>
        {isUpdateForm && (
          <Popconfirm
            disabled={loading}
            title="Are you sure you want to delete the menu item?"
            okType="danger"
            onConfirm={deleteMenuItem}
            okText="Delete"
          >
            <Button danger disabled={loading} loading={loading} title="Delete">
              Delete
            </Button>
          </Popconfirm>
        )}
      </Row>
    </Form>
  );
};

export const MenuItemForm = MenuItemFormComponent;
