import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  buildCollectionResponseFormatter,
  buildCollectionPreRequestDataMapper
} from '@axmit/redux-communications';
import { message } from 'antd';
import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ratingTransport } from 'entities/Rating/Rating.transport';
import {
  ERatingSuccessMessage,
  IRatingCollection,
  IRatingCollectionFilter,
  IRatingModel,
  IRatingParamsModel,
  IRatingUpdateData
} from 'entities/Rating/Rating.models';

const namespace = 'rating';

export interface IRatingConnectedProps {
  ratingModel: StoreBranch<IRatingModel, IRatingParamsModel>;
  ratingCollection: StoreBranch<IRatingCollection, IRatingCollectionFilter>;

  getRatingModel(id: string): void;
  updateRatingModel(params: IRatingUpdateData): void;
  getRatingCollection(filter?: IRatingCollectionFilter): void;

  clearRatingModel(): void;
  clearRatingCollection(): void;
}

const RatingModelAPIProviders = [
  new APIProvider(EActionsTypes.get, ratingTransport.get),
  new APIProvider(EActionsTypes.update, ratingTransport.update, {
    onSuccess: function*() {
      message.success(ERatingSuccessMessage.Edit);
      yield put(push(ERoutesPrivate.Rating));
    }
  })
];

const RatingCollectionAPIProviders = [
  new APIProvider(EActionsTypes.get, ratingTransport.getCollection, {
    // todo fix any
    mapSuccess: buildCollectionResponseFormatter<any, any>(),
    preRequestDataMapper: buildCollectionPreRequestDataMapper<IRatingCollection, IRatingCollectionFilter>()
  })
];

const branches = [new Branch('model', RatingModelAPIProviders), new Branch('collection', RatingCollectionAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationRating = buildCommunication<IRatingConnectedProps>(strategy);
