import { SelectProps } from 'antd/es/select';
import React from 'react';
import { Select } from 'antd';
import { EBaseOrderDirection } from 'common/models/requestModels';
import { EStoreFeedbackOrder } from 'entities/Rating/Rating.models';

interface IComponentProps {
  onChange: (value?: EBaseOrderDirection) => void;
}

type AllProps = SelectProps<any> & IComponentProps;

export class StoreFeedbackOrderSelector extends React.PureComponent<AllProps> {
  handleChange = (value: EBaseOrderDirection) => {
    const { onChange } = this.props;
    onChange(value);
  };

  render() {
    const { value } = this.props;
    return (
      <Select
        allowClear
        className="width-full"
        placeholder="Select direction"
        value={value}
        defaultActiveFirstOption={false}
        showArrow
        onChange={(val: EBaseOrderDirection) => this.handleChange(val)}
      >
        <Select.Option
          key={EStoreFeedbackOrder.HighestTop}
          value={EBaseOrderDirection.DESC}
          label={EStoreFeedbackOrder.HighestTop}
        >
          {EStoreFeedbackOrder.HighestTop}
        </Select.Option>
        <Select.Option key={EStoreFeedbackOrder.LowestTop} value={EBaseOrderDirection.ASC} label={EStoreFeedbackOrder.LowestTop}>
          {EStoreFeedbackOrder.LowestTop}
        </Select.Option>
      </Select>
    );
  }
}
