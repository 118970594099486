import { AnyAction, Reducer } from 'redux';
import { RouterState } from 'react-router-redux';
import { communicationCategory, ICategoryStoreProps } from 'entities/Category/Category.communication';
import { communicationAuth, IAuthStoreProps } from 'entities/Auth/Auth.communication';
import { communicationUser, IUserStoreProps } from 'entities/User/User.communication';
import { communicationUI } from 'entities/UI/UI.communication';
import { communicationStore, IStoresStoreProps } from 'entities/Store/Store.communication';
import { communicationAsset, IAssetStoreProps } from 'entities/Asset/Asset.communication';
import { communicationMenuItem } from 'entities/MenuItem/MenuItem.communication';
import { communicationStatistic } from 'entities/Statistic/Statistic.communication';
import { communicationCustomer } from 'entities/Customer/Customer.communication';
import { communicationOrder } from 'entities/Order/Order.communication';
import { communicationRating } from 'entities/Rating/Rating.communication';
import { communicationPromo } from 'entities/Promo/Promo.communication';
import { communicationQR } from 'entities/QR/QR.communication';

type RoutingReducer = Reducer<RouterState, AnyAction>;

export interface IApplicationState {
  routing?: RoutingReducer | null;
  auth: IAuthStoreProps;
  user: IUserStoreProps;
  store: IStoresStoreProps;
  asset: IAssetStoreProps;
  category: ICategoryStoreProps;
  [key: string]: any;
}

const reducers = {
  ...communicationAuth.reducers,
  ...communicationUser.reducers,
  ...communicationUI.reducers,
  ...communicationStore.reducers,
  ...communicationAsset.reducers,
  ...communicationCategory.reducers,
  ...communicationMenuItem.reducers,
  ...communicationStatistic.reducers,
  ...communicationOrder.reducers,
  ...communicationCustomer.reducers,
  ...communicationRating.reducers,
  ...communicationPromo.reducers,
  ...communicationQR.reducers
};

export default reducers;
