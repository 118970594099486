import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { message } from 'antd';
import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';
import { ERoutesPrivate } from 'common/models/routesModel';
import { assetTransport } from 'entities/Asset/Asset.transport';
import {
  EAssetSuccessMessage,
  IAssetCollection,
  IAssetCollectionFilter,
  IAssetCreateData,
  IAssetModel,
  IAssetParamsModel,
  IAssetUpdateData
} from 'entities/Asset/Asset.models';

const namespace = 'asset';

export interface IAssetStoreProps {
  collection: StoreBranch<IAssetCollection, IAssetCollectionFilter>;
}

export interface IAssetConnectedProps {
  assetModel: StoreBranch<IAssetModel, IAssetParamsModel>;
  assetCollection: StoreBranch<IAssetCollection, IAssetCollectionFilter>;

  getAssetModel(id: string): void;
  addAssetModel(params: IAssetCreateData): void;
  updateAssetModel(params: IAssetUpdateData): void;
  deleteAssetModel(id: string): void;
  getAssetCollection(filter?: IAssetCollectionFilter): void;

  clearAssetModel(): void;
  clearAssetCollection(): void;
}

const AssetModelAPIProviders = [
  new APIProvider(EActionsTypes.get, assetTransport.get),
  new APIProvider(EActionsTypes.update, assetTransport.update, {
    onSuccess: function*() {
      message.success(EAssetSuccessMessage.Edit);
      yield put(push(`${ERoutesPrivate.Menu}?tab=assets`));
    }
  }),
  new APIProvider(EActionsTypes.delete, assetTransport.delete, {
    onSuccess: function*() {
      message.success(EAssetSuccessMessage.Delete);
      yield put(push(`${ERoutesPrivate.Menu}?tab=assets`));
    }
  }),
  new APIProvider(EActionsTypes.add, assetTransport.add, {
    onSuccess: function*() {
      message.success(EAssetSuccessMessage.Add);
      yield put(push(`${ERoutesPrivate.Menu}?tab=assets`));
    }
  })
];

const AssetCollectionAPIProviders = [new APIProvider(EActionsTypes.get, assetTransport.getCollection)];

const branches = [new Branch('model', AssetModelAPIProviders), new Branch('collection', AssetCollectionAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationAsset = buildCommunication<IAssetConnectedProps>(strategy);

communicationAsset.sagas.push();
