import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { message } from 'antd';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { ERoutesPrivate } from 'common/models/routesModel';
import { menuItemTransport } from 'entities/MenuItem/MenuItem.transport';
import {
  EMenuItemSuccessMessage,
  IMenuItemCreateData,
  IMenuItemModel,
  IMenuItemUpdateData,
  IMenuItemCollectionFilter,
  IMenuItemCollection,
  IMenuItemParamsModel
} from 'entities/MenuItem/MenuItem.models';

const namespace = 'menuItem';

export interface IMenuItemConnectedProps {
  menuItemModel: StoreBranch<IMenuItemModel, IMenuItemParamsModel>;
  menuItemCollection: StoreBranch<IMenuItemCollection, IMenuItemCollectionFilter>;

  getMenuItemModel(id: string): void;
  addMenuItemModel(params: IMenuItemCreateData): void;
  updateMenuItemModel(params: IMenuItemUpdateData): void;
  deleteMenuItemModel(id: string): void;
  getMenuItemCollection(filter?: IMenuItemCollectionFilter): void;

  clearMenuItemModel(): void;
  clearMenuItemCollection(): void;
}

const MenuItemModelAPIProviders = [
  new APIProvider(EActionsTypes.get, menuItemTransport.get),
  new APIProvider(EActionsTypes.update, menuItemTransport.update, {
    onSuccess: function*() {
      message.success(EMenuItemSuccessMessage.Edit);

      yield put(push(`${ERoutesPrivate.Menu}?tab=items`));
    }
  }),
  new APIProvider(EActionsTypes.delete, menuItemTransport.deleteSoft, {
    onSuccess: function*() {
      message.success(EMenuItemSuccessMessage.Delete);

      yield put(push(`${ERoutesPrivate.Menu}?tab=items`));
    }
  }),
  new APIProvider(EActionsTypes.add, menuItemTransport.add, {
    onSuccess: function*() {
      message.success(EMenuItemSuccessMessage.Add);

      yield put(push(`${ERoutesPrivate.Menu}?tab=items`));
    }
  })
];

const MenuItemCollectionAPIProviders = [new APIProvider(EActionsTypes.get, menuItemTransport.getCollection)];

const branches = [new Branch('model', MenuItemModelAPIProviders), new Branch('collection', MenuItemCollectionAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationMenuItem = buildCommunication<IMenuItemConnectedProps>(strategy);

communicationMenuItem.sagas.push();
