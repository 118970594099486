import React from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { EMenuPageTabKeys } from 'app/pages/Franchisor/MenuPage';
import { communicationAsset, IAssetConnectedProps } from 'entities/Asset/Asset.communication';
import { AssetForm } from 'entities/Asset/components/Form/AssetForm';
import { communicationMenuItem, IMenuItemConnectedProps } from 'entities/MenuItem/MenuItem.communication';
import { AssetFormSkeleton } from 'entities/Asset/components/Skeleton/AssetFormSkeleton';

interface IParams {
  id?: string;
}

type AllProps = IAssetConnectedProps & IMenuItemConnectedProps & RouteComponentProps<IParams>;

class AssetPageComponent extends React.PureComponent<AllProps> {
  componentDidMount(): void {
    const { getAssetModel, match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      getAssetModel(id);
    }
  }
  componentWillUnmount() {
    const { clearAssetModel } = this.props;

    clearAssetModel();
  }

  render() {
    const { assetModel, match, updateAssetModel, addAssetModel, deleteAssetModel } = this.props;
    const { params } = match;
    const { id } = params;
    const { data: assetModelData, loading } = assetModel;
    const assetName = assetModelData?.name;
    const isEdit = !!id;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
        <Col span={12}>
          <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
            <Breadcrumb.Item href={`${ERoutesPrivate.Menu}?tab=${EMenuPageTabKeys.Assets}`}>Asset list</Breadcrumb.Item>
            <Breadcrumb.Item>
              {isEdit ? (
                !loading ? (
                  assetName
                ) : (
                  <Skeleton.Input active style={{ width: '200px' }} size="small" />
                )
              ) : (
                'Add new asset'
              )}
            </Breadcrumb.Item>
          </Breadcrumb>
          {!loading || !isEdit ? (
            <AssetForm
              assetModel={assetModel}
              createAsset={addAssetModel}
              updateAsset={updateAssetModel}
              deleteAsset={deleteAssetModel}
              assetId={id}
            />
          ) : (
            <AssetFormSkeleton isEdit={isEdit} />
          )}
        </Col>
      </CommonLayout>
    );
  }
}

export const AssetPage = communicationMenuItem.injector(communicationAsset.injector(AssetPageComponent));
