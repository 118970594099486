import React from 'react';
import { Col, Row, Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import debounce from 'lodash.debounce';
import { ItemsNotFound } from 'common/components/Text/ItemsNotFound';
import { IMultipleSelectorListItem, MultipleSelectorList } from 'common/components/List/StoreMultipleSelectorList';
import { IStoreModel } from 'entities/Store/Store.models';
import { storeTransport } from 'entities/Store/Store.transport';

interface IComponentProps {
  value?: IStoreModel[];
  defaultValue?: IStoreModel[];
  onChange?: (items: IStoreModel[]) => void;
  placeholder?: string;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

interface IComponentState {
  data: IStoreModel[];
  value?: IStoreModel;
  items: IStoreModel[];
}

export class StoreMultipleSelector extends React.PureComponent<AllProps> {
  state: IComponentState = {
    data: [],
    value: undefined,
    items: this.props.value || []
  };

  readonly searchDebounced: any;

  static getDerivedStateFromProps(props: AllProps, state: IComponentState) {
    if (!props.value?.length && state.items.length) {
      return { items: [] };
    }

    if (!state.items.length && props.value && props.value.length) {
      return { items: props.value };
    }

    return null;
  }

  constructor(props: AllProps) {
    super(props);
    this.searchDebounced = debounce(this.handleSearch, 200);
  }

  async componentDidMount() {
    const { data } = await storeTransport.getCollection();
    this.setState({ data });
  }

  handleSearch = async (value: string) => {
    if (value) {
      const { data } = await storeTransport.getCollection({ search: value });

      this.setState({ data });
    } else {
      const { data } = await storeTransport.getCollection();
      this.setState({ data });
    }
  };

  deleteItem = (item: IMultipleSelectorListItem) => {
    const { onChange } = this.props;
    const { items } = this.state;
    const filteredItems = items.filter(el => el.id !== item.id);
    this.setState({ items: filteredItems });
    if (onChange) {
      onChange(filteredItems);
    }
  };

  handleChange = async (value: string) => {
    const { onChange } = this.props;
    const { items, data } = this.state;
    const item = data.find(el => el.id === value);
    const itemExist = items.find(el => el.id === value);

    if (item && !itemExist) {
      items.push(item);
    }

    if (onChange) {
      onChange(items);
    }
    const { data: newData } = await storeTransport.getCollection();
    this.setState({ value: undefined, items, data: newData });
  };

  render() {
    const { value, items, data } = this.state;
    const { disabled } = this.props;
    const options = data.map(option => (
      <Select.Option key={option.id} value={option.id}>
        {option.storeName}
      </Select.Option>
    ));
    const label = items.length ? (value ? value.storeName : null) : undefined;

    return (
      <Col>
        <Row>
          <Select
            className="width-full"
            showSearch
            disabled={disabled}
            value={label as any}
            style={{ width: '100%' }}
            defaultActiveFirstOption={false}
            showArrow
            filterOption={false}
            onSearch={this.searchDebounced}
            onChange={this.handleChange}
            notFoundContent={<ItemsNotFound />}
            placeholder={items.length ? 'Select store' : 'All stores if not filled'}
          >
            {options}
          </Select>
        </Row>

        {!!items.length && (
          <Row className="mt-5">
            <MultipleSelectorList items={items.map(el => ({ id: el.id, title: el.storeName }))} onClick={this.deleteItem} />
          </Row>
        )}
      </Col>
    );
  }
}
