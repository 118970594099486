import { envConfig } from 'common/config/env.config';

export const colLayoutSize = {
  large: 21,
  medium: 13,
  small: 5
};
export enum ELayoutSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small'
}
export const requireText = 'Field is required';
export const correctPhoneText = 'Please input your mobile number (+63 9XX XXX XXXX)';

export const formRules = {
  required: {
    required: true,
    message: requireText
  },
  correctPhone: {
    pattern: envConfig.NODE_ENV === 'development' ? /\+(639|79)[0-9]{9}$/ : /\+639[0-9]{9}$/,
    message: correctPhoneText
  }
};

export const MEDIA_MOBILE = '(max-width: 425px)';
export const MODAL_MAX_WIDTH_DESKTOP = 375;
export const DATE_FORMAT = 'MM.DD.YYYY';
export const DATE_FORMAT_WITH_TIME = 'MM.DD.YYYY hh:mm A';
export const TIME_FORMAT = 'hh:mm A';
export const MILITARY_TIME_FORMAT = 'HH:mm';
export const DATE_FORMAT_PICKUP = 'MM/DD/YYYY; hh:mm A';

// manila
export const COORDINATE_DEFAULT = {
  lat: 14.5995124,
  lon: 120.9842195
};

export const mapZoom = {
  default: 12,
  specifiedLocation: 18
};
