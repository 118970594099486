import { IBasePathParams } from 'common/models/requestModels';

export interface IAssetCollection extends Components.Schemas.AssetCollectionDto {}
export interface IAssetCollectionFilter extends Paths.AssetControllerList.QueryParameters {}
export interface IAssetModel extends Components.Schemas.AssetDto {}
export interface IAssetCreateData extends Components.Schemas.CreateAssetDto {}
export interface IAssetUpdateData extends Components.Schemas.UpdateAssetDto {
  id: string;
}
export interface IAssetParamsModel extends IAssetCreateData, IAssetUpdateData, IBasePathParams {}
export interface IAssetCategories extends Components.Schemas.CategoryToAssetDto {}
export interface IAssetMenuItem extends Components.Schemas.MenuItemToAssetDto {}

export enum EAssetSuccessMessage {
  Delete = 'Asset has been successfully deleted',
  Add = 'Asset has been successfully added',
  Edit = 'Asset has been successfully edited'
}

export const assetTableConfig = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true
  }
];
