import React from 'react';
import { Card, Typography } from 'antd';
import { IStatisticModel } from 'entities/Statistic/Statistic.models';
import { OrderBody } from 'entities/Statistic/components/Card/OrderBody';

interface IComponentProps {
  item: Partial<IStatisticModel>;
}

type AllProps = IComponentProps;

export const OrderCard: React.FC<AllProps> = props => {
  const { item } = props;

  return (
    <Card
      bordered
      className="mt-8"
      title={
        <Typography.Paragraph className="m-0 p-0 franchisee__text_default font-weight-7" ellipsis={{ rows: 1 }} title="Orders">
          Orders
        </Typography.Paragraph>
      }
    >
      <OrderBody item={item} />
    </Card>
  );
};
