import React from 'react';
import { Breadcrumb, Col } from 'antd';
import { RouteComponentProps } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { communicationRating, IRatingConnectedProps } from 'entities/Rating/Rating.communication';
import { RatingForm } from 'entities/Rating/components/Form/RatingForm';
import { RatingFormSkeleton } from 'entities/Rating/components/Skeleton/RatingFormSkeleton';

interface IParams {
  id?: string;
}

type AllProps = IRatingConnectedProps & RouteComponentProps<IParams>;

class RatingPageComponent extends React.PureComponent<AllProps> {
  componentDidMount(): void {
    const { getRatingModel, match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      getRatingModel(id);
    }
  }
  componentWillUnmount() {
    const { clearRatingModel } = this.props;

    clearRatingModel();
  }

  render() {
    const { ratingModel, match, updateRatingModel } = this.props;
    const { params } = match;
    const { id } = params;
    const { loading } = ratingModel;
    const ratingPageTitle = 'Details';

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
        <Col span={12}>
          <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
            <Breadcrumb.Item href={ERoutesPrivate.Rating}>Customers feedback</Breadcrumb.Item>
            <Breadcrumb.Item>{ratingPageTitle}</Breadcrumb.Item>
          </Breadcrumb>
          {!loading ? (
            <RatingForm ratingModel={ratingModel} updateRating={updateRatingModel} ratingId={id} />
          ) : (
            <RatingFormSkeleton />
          )}
        </Col>
      </CommonLayout>
    );
  }
}

export const RatingPage = communicationRating.injector(RatingPageComponent);
