import { BaseHttpTransport } from '@axmit/transport';
import axios, { AxiosStatic } from 'axios';
import { ICustomerModel, ICustomerUpdateStatusParams } from 'entities/Customer/Customer.models';
import { ICustomerCollection, ICustomerCollectionFilter } from 'entities/Customer/Customer.models';

const basePath = '/customers';

class CustomerTransport extends BaseHttpTransport<
  AxiosStatic,
  ICustomerModel,
  ICustomerCollection,
  void,
  void,
  ICustomerCollectionFilter
> {
  public updateStatus = (params: ICustomerUpdateStatusParams) => axios.put(`${basePath}/${params.id}/change-status`, params);
}

export const customerTransport = new CustomerTransport(basePath, axios);
