export enum ERoutesCommon {
  Root = '/',
  TechnicalWork = '/tech-work'
}
export enum ERoutesPublic {
  Login = '/login',
  PasswordForgot = '/password-forgot',
  PasswordRestore = '/password-restore/token',
  SignUpConfirm = '/user/join'
}
export enum ERoutesPrivate {
  Promo = '/promo',
  PromoCreate = '/promo/new',
  Category = '/category',
  CategoryCreate = '/category/new',
  MenuItem = '/menu-item',
  MenuItemCreate = '/menu-item/new',
  Asset = '/asset',
  AssetCreate = '/asset/new',
  Users = '/users',
  UsersInvite = '/users/invite',
  Menu = '/menu',
  Stores = '/stores',
  StoresCreate = '/stores/new',
  Customers = '/customers',
  Profile = '/profile',
  Statistics = '/statistics',
  Rating = '/feedback',
  QR = '/qr',
  QRCreate = '/qr/new'
}

export enum ERoutesFranchiseePrivate {
  StoreList = '/store-list',
  StorePage = '/store-page',
  Statistics = '/statistics',
  ChangePassword = '/change-password',
  StoreFeedback = '/store-feedback'
}
