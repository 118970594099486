import React from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { Link } from 'react-router-dom';
import { useFormMapper } from '@axmit/antd4-helpers';
import { ERoutesPublic } from 'common/models/routesModel';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';
import { IAuthData } from 'entities/Auth/Auth.models';

type AllProps = IAuthConnectedProps;

const LoginFormComponent: React.FC<AllProps> = props => {
  const { addAuthModel, authModel } = props;
  const { loading, errors, params } = authModel;
  const { fields } = useFormMapper(['email', 'password'], null, params, errors);

  const login = (values: any) => {
    const { email, password } = values;
    const params: IAuthData = {
      email,
      password
    };

    addAuthModel(params);
  };

  return (
    <>
      <Row justify="center" className="mb-4">
        <h1 className="basic__text_title fs-md" title="Log in">
          Log in
        </h1>
      </Row>
      <Form onFinish={login} fields={fields}>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Email is required'
            }
          ]}
          name="email"
          label="Email"
        >
          <Input name="email" type="email" placeholder="Email" disabled={loading} />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: 'Password is required'
            }
          ]}
          name="password"
          label="Password"
        >
          <Input.Password type="password" placeholder="Password" disabled={loading} />
        </Form.Item>
        <Form.Item>
          <Row justify="space-between" align="middle">
            <Col span={10}>
              <Button htmlType="submit" block type="primary" title="Log in" disabled={loading} loading={loading}>
                Log in
              </Button>
            </Col>
            <Link
              to={ERoutesPublic.PasswordForgot}
              className="font-family__default fs-xs basic__link ellipsis"
              title="Forgot password?"
            >
              Forgot password?
            </Link>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export const LoginPage = communicationAuth.injector(LoginFormComponent);
