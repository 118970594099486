import React, { useMemo } from 'react';
import { Button, Col, Form, Input, Radio, Row } from 'antd';
import { useFormMapper } from '@axmit/antd4-helpers';
import { Link } from 'react-router-dom';
import { StoreBranch } from '@axmit/redux-communications';
import { formRules } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ECommonFormType } from 'common/models/commonModel';
import { EMenuPageTabKeys } from 'app/pages/Franchisor/MenuPage';
import {
  ECategoryType,
  ECategoryTypeText,
  ICategoryCreateData,
  ICategoryModel,
  ICategoryParamsModel,
  ICategoryUpdateData
} from 'entities/Category/Category.models';

interface IComponentProps {
  createCategory: (data: ICategoryCreateData) => void;
  updateCategory: (data: ICategoryUpdateData) => void;
  deleteCategory: () => void;
  categoryModel: StoreBranch<ICategoryModel, ICategoryParamsModel>;
  typeForm: ECommonFormType;
}

const CategoryFormComponent: React.FC<IComponentProps> = props => {
  const { categoryModel, createCategory, updateCategory, typeForm, deleteCategory } = props;
  const { errors, loading, data: categoryData, params } = categoryModel;
  const categoryId = categoryData?.id;
  const isCreateForm = typeForm === ECommonFormType.Add;
  const memoizedValue = useMemo(() => ({ type: ECategoryType.DisableAddOns, ...params }), [params]);
  const paramsValue = isCreateForm ? memoizedValue : params;

  const { fields } = useFormMapper(['name', 'type'], categoryData, paramsValue, errors);

  const addCategory = (values: any) => {
    const { name, type } = values;
    const data: ICategoryCreateData = {
      name,
      type
    };
    createCategory && createCategory(data);
  };

  const editCategory = (values: any) => {
    const { name } = values;

    if (categoryId) {
      const data: ICategoryUpdateData = {
        name,
        id: categoryId
      };

      updateCategory && updateCategory(data);
    }
  };

  return (
    <Form className="mt-5" onFinish={isCreateForm ? addCategory : editCategory} fields={fields}>
      <Form.Item
        rules={[
          formRules.required,
          {
            min: 2,
            message: 'Category name must be longer than or equal 2 characters'
          },
          {
            max: 128,
            message: 'Category name must be shorter than or equal 128 characters'
          }
        ]}
        name="name"
        label="Category name"
      >
        <Input name="name" type="text" placeholder="Category name" disabled={loading} />
      </Form.Item>
      <Form.Item name="type">
        <Radio.Group disabled={loading || !isCreateForm}>
          <Radio value={ECategoryType.DisableAddOns} className="d-block mb-3">
            {ECategoryTypeText.DisableAddOns}
          </Radio>
          <Radio value={ECategoryType.IsAddOns} className="d-block mb-3">
            {ECategoryTypeText.IsAddOns}
          </Radio>
          <Radio value={ECategoryType.EnableAddOns} className="d-block">
            {ECategoryTypeText.EnableAddOns}
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Row className="flex-noWrap" justify="space-between" align="middle">
        <Col span={12}>
          <Row className="flex-noWrap">
            <Form.Item className="mb-0 mr-5">
              <Button htmlType="submit" type="primary" disabled={loading} loading={loading} title={isCreateForm ? 'Add' : 'Save'}>
                {isCreateForm ? 'Add' : 'Save'}
              </Button>
            </Form.Item>

            <Link to={`${ERoutesPrivate.Menu}?tab=${EMenuPageTabKeys.Categories}`}>
              <Button disabled={loading} loading={loading} title="Cancel">
                Cancel
              </Button>
            </Link>
          </Row>
        </Col>
        {!isCreateForm && (
          <Button danger disabled={loading} loading={loading} title="Delete" onClick={deleteCategory}>
            Delete
          </Button>
        )}
      </Row>
    </Form>
  );
};

export const CategoryForm = CategoryFormComponent;
