import React, { ReactNode } from 'react';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

interface IComponentProps {
  privateRoutes: ReactNode;
  publicRoutes: ReactNode;
}
type AllProps = IAuthConnectedProps & IComponentProps;

class PermissionLayoutComponent extends React.Component<AllProps> {
  render() {
    const { authModel, privateRoutes, publicRoutes } = this.props;
    const { data, loading: authLoading } = authModel;
    const authorized = data && Object.keys(data).length !== 0;

    return authLoading ? <LoadingSpin /> : authorized ? privateRoutes : publicRoutes;
  }
}

export const PermissionLayout = communicationAuth.injector(PermissionLayoutComponent);
