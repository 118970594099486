import React from 'react';

interface IComponentProps {
  label?: string;
  boldTitle?: boolean;
}

export const SkeletonFormItem: React.FC<IComponentProps> = props => {
  const { children, label, boldTitle } = props;
  const boldClass = boldTitle ? 'font-weight-5' : undefined;
  return (
    <div className="ant-row ant-form-item">
      {label && (
        <div className={`ant-col ant-form-item-label ${boldClass}`}>
          <label htmlFor={label} title={label}>
            {label}
          </label>
        </div>
      )}
      {children}
    </div>
  );
};
