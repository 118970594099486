import React from 'react';
import { Col, Row } from 'antd';

export const FranchiseeCenterLayout: React.FC = props => (
  <Row justify="center" className="width-full mt-10 pb-10 pl-7 pr-7">
    <Col xs={24} sm={16} md={12} lg={8}>
      {props.children}
    </Col>
  </Row>
);
