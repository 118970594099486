import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { EBaseOrderDirection, IBaseFilterModel } from 'common/models/requestModels';
import { ERoutesPrivate } from 'common/models/routesModel';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { EMenuPageTabKeys, IMenuPageState } from 'app/pages/Franchisor/MenuPage';
import { ICategoryCollection, ICategoryCollectionFilter, ICategoryModel } from 'entities/Category/Category.models';
import { communicationCategory, ICategoryConnectedProps } from 'entities/Category/Category.communication';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & ICategoryConnectedProps & RouteComponentProps;

class CategoryTableComponent extends TableCommon<
  ICategoryCollection,
  ICategoryModel,
  ColumnsType<any>,
  AllProps,
  ICategoryCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getCategoryCollection } = this.props;
    const filterParams: ICategoryCollectionFilter = {
      ...params,
      orderField: 'name',
      orderDirection: EBaseOrderDirection.ASC,
      isDeleted: false
    };

    getCategoryCollection(filterParams);
  };

  getCollection = () => {
    return this.props.categoryCollection;
  };

  addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<IMenuPageState>({
      tab: EMenuPageTabKeys.Categories,
      pageIndex: 1
    });
    const search = objectToQuery({ ...searchPath, pageIndex });
    return this.props.history.replace({ search });
  };

  onRowClick = (item: ICategoryModel) => {
    const { history } = this.props;
    history.push(`${ERoutesPrivate.Category}/${item.id}`);
  };
  clearCollection = () => {
    return this.props.clearCategoryCollection();
  };
}

export const CategoryTable = communicationCategory.injector(withRouter(CategoryTableComponent));
