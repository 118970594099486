import axios from 'axios';
import { IBasePathParams } from 'common/models/requestModels';
import { objectToQuery } from 'common/helpers/filters.helper';
import { IStoreCollection, IStoreCollectionFilter, IStoreModel, IStoreParams } from 'entities/Store/Store.models';

const basePath = '/stores';

export const storeTransport = {
  get: (path: IBasePathParams): Promise<IStoreModel> => axios.get(`${basePath}/${path.id}`),
  getByShortId: (path: IBasePathParams): Promise<IStoreModel> => axios.get(`${basePath}/by-short-id/${path.id}`),
  update: (params: IStoreParams): Promise<IStoreModel> =>
    axios.put(`${basePath}/${params.path?.id}`, {
      ...params.data,
      franchisee: params.data?.franchisee?.id || null,
      asset: params.data?.asset?.id || null
    }),
  create: (params: IStoreParams): Promise<IStoreModel> =>
    axios.post(basePath, {
      ...params.data,
      franchisee: params.data?.franchisee?.id || undefined,
      asset: params.data?.asset?.id || undefined
    }),

  delete: (path: IBasePathParams): Promise<void> => axios.delete(`${basePath}/${path.id}`),

  getCollection: (filter?: IStoreCollectionFilter): Promise<IStoreCollection> => axios.get(`${basePath}${objectToQuery(filter)}`)
};
