import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  getStartType,
  StoreBranch
} from '@axmit/redux-communications';
import { call, put } from 'redux-saga/effects';
import { clearCreds, getCreds, saveCreds } from '@axmit/axios-patch-jwt';
import { message } from 'antd';
import { push } from 'connected-react-router';
import { IError, EErrorStatus } from '@axmit/error-helper';
import { IBasePathParams } from 'common/models/requestModels';
import { ICheckTokenData } from 'common/models/commonModel';
import { ERoutesPublic } from 'common/models/routesModel';
import {
  IAuthModel,
  IAuthData,
  IAuthPasswordRestoreParams,
  IAuthPasswordForgotParams,
  ITokenModel,
  EAuthErrorMessage,
  EAuthSuccessMessage,
  EBaseErrorMessage
} from 'entities/Auth/Auth.models';
import { authTransport } from 'entities/Auth/Auth.transport';
import { IUserConfirmData, IUserModel } from 'entities/User/User.models';
import { userTransport } from 'entities/User/User.transport';

const namespace = 'auth';

export interface IAuthStoreProps {
  model: StoreBranch<ITokenModel>;
}

export interface IAuthConnectedProps {
  authModel: StoreBranch<IAuthModel>;
  authUser: StoreBranch<IUserModel>;
  authPasswordRestore: StoreBranch<void>;
  authTokenCheck: StoreBranch<void>;

  addAuthModel(params: IAuthData): void;
  confirmAuthModel(data: IUserConfirmData): void;
  deleteAuthModel(): void;

  getAuthUser(params: IBasePathParams): void;

  inviteUserAuthTokenCheck(data: ICheckTokenData): void;
  passwordRestoreAuthTokenCheck(data: ICheckTokenData): void;

  forgotAuthPasswordRestore(params: IAuthPasswordForgotParams): void;
  updateAuthPasswordRestore(params: IAuthPasswordRestoreParams): void;

  initAuthModel(): void;
}

const successAuth = function*(response: IAuthModel) {
  const userId = response?.access?.userId;

  if (userId) {
    yield getAuthUser(userId);
  }
};
const modelApiProvider = [
  new APIProvider(EActionsTypes.init, (): Promise<ITokenModel> => getCreds(), { onSuccess: successAuth }),

  new APIProvider(EActionsTypes.add, authTransport.login, {
    onSuccess: function*(response: IAuthModel) {
      yield call(saveCreds, response);
      yield successAuth(response);
    }
  }),
  new APIProvider(EActionsTypes.delete, authTransport.logout, {
    onSuccess: function*() {
      yield clearAuth();
    }
  }),
  new APIProvider('confirm', userTransport.confirm, {
    onSuccess: function*(response) {
      message.success(EAuthSuccessMessage.AccountConfirmed);
      yield call(saveCreds, response);
      yield successAuth(response);
    }
  })
];

const passwordRestoreApiProvider = [
  new APIProvider('forgot', authTransport.passwordForgot, {
    onSuccess: function() {
      message.success(EAuthSuccessMessage.PasswordForgot);
    }
  }),
  new APIProvider(EActionsTypes.update, authTransport.passwordRestore, {
    onSuccess: function*() {
      message.success(EAuthSuccessMessage.ChangePasswordSuccess);
      yield put(push(ERoutesPublic.Login));
    },
    onFail: function(e: IError) {
      if (e.status === EErrorStatus.NotFound) {
        message.error(EAuthErrorMessage.InvalidRestoreCode);
      }
    }
  })
];
const authUserAPIProvider = [
  new APIProvider(EActionsTypes.get, userTransport.get, {
    onFail: function*() {
      message.error(EBaseErrorMessage.Default);
      yield clearAuth();
    }
  })
];

const tokenCheckAPIProviders = [
  new APIProvider('inviteUser', userTransport.checkInviteToken),
  new APIProvider('passwordRestore', authTransport.checkInviteToken)
];

const branches = [
  new Branch('model', modelApiProvider),
  new Branch('user', authUserAPIProvider, new StoreBranch<IUserModel>(null, null, null, true)),
  new Branch('passwordRestore', passwordRestoreApiProvider),
  new Branch('tokenCheck', tokenCheckAPIProviders, new StoreBranch<void>(null, null, null, true))
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export function* clearAuth() {
  yield clearAuthModel();
  yield clearAuthUser();
  yield call(clearCreds);
}

export function* getAuthUser(id: string) {
  yield put({ type: getStartType(namespace, 'user', EActionsTypes.get), payload: { id } });
}

export function* clearAuthUser() {
  yield put({ type: getStartType(namespace, 'user', EActionsTypes.clear) });
}

function* clearAuthModel() {
  yield put({ type: getStartType(namespace, 'model', EActionsTypes.clear) });
}

export const communicationAuth = buildCommunication<IAuthConnectedProps>(strategy);
