import React from 'react';
import { Row, Spin } from 'antd';

export const LoadingSpin: React.FunctionComponent = () => {
  return (
    <Row className="mt-5 width-full" justify="center" align="middle">
      <Spin size="large" />
    </Row>
  );
};
