import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { EBaseOrderDirection, IBaseFilterModel } from 'common/models/requestModels';
import { ERoutesPrivate } from 'common/models/routesModel';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { EMenuPageTabKeys, IMenuPageState } from 'app/pages/Franchisor/MenuPage';
import { IAssetCollection, IAssetCollectionFilter, IAssetModel } from 'entities/Asset/Asset.models';
import { communicationAsset, IAssetConnectedProps } from 'entities/Asset/Asset.communication';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & IAssetConnectedProps & RouteComponentProps;

class AssetTableComponent extends TableCommon<IAssetCollection, IAssetModel, ColumnsType<any>, AllProps, IAssetCollectionFilter> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getAssetCollection } = this.props;
    const filterParams: IAssetCollectionFilter = { ...params, orderField: 'name', orderDirection: EBaseOrderDirection.ASC };

    getAssetCollection(filterParams);
  };

  getCollection = () => {
    return this.props.assetCollection;
  };

  addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<IMenuPageState>({
      tab: EMenuPageTabKeys.Categories,
      pageIndex: 1
    });
    const search = objectToQuery({ ...searchPath, pageIndex });
    return this.props.history.replace({ search });
  };

  onRowClick = (item: IAssetModel) => {
    const { history } = this.props;
    history.push(`${ERoutesPrivate.Asset}/${item.id}`);
  };
  clearCollection = () => {
    return this.props.clearAssetCollection();
  };
}

export const AssetTable = communicationAsset.injector(withRouter(AssetTableComponent));
