import { AbstractSearchableSelector } from 'common/components/Input/AbstractSearchableSelector';
import { menuItemTransport } from 'entities/MenuItem/MenuItem.transport';
import { IMenuItemModel, IMenuItemCollectionFilter, IMenuItemsNotOnMenuParams } from 'entities/MenuItem/MenuItem.models';

interface IComponentProps {
  filter?: IMenuItemCollectionFilter;
  params?: IMenuItemsNotOnMenuParams;
  isNotOnMenu?: boolean;
}

export class MenuItemSelector extends AbstractSearchableSelector<IMenuItemModel, IComponentProps> {
  getItemLabel = (item: IMenuItemModel): string => {
    return item.name || item.id || 'Unknown menu item';
  };

  loadData = (value?: string): Promise<{ data: IMenuItemModel[] }> => {
    const { filter, isNotOnMenu, params } = this.props;

    if (isNotOnMenu) {
      return menuItemTransport.getNotOnMenuCollection({ name: value || '', stores: params?.stores || [], isInMenu: false });
    }

    return menuItemTransport.getCollection({ name: value || '', isDeleted: false, isAddOns: false, ...filter });
  };
}
