import React from 'react';
import { Col, Row, Typography, DatePicker } from 'antd';
import { RouteComponentProps } from 'react-router';
import moment, { Moment } from 'moment';
import { FranchiseeCenterLayout } from 'common/components/Layouts/FranchiseeCenterLayout';
import { rangeConfig } from 'common/helpers/date.helper';
import { DATE_FORMAT } from 'common/const/common.const';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { EBaseOrderDirection } from 'common/models/requestModels';
import backIcon from 'app/assets/icons/backIcon.svg';
import { communicationStore, IStoreConnectedProps } from 'entities/Store/Store.communication';
import { StoreFeedbackList } from 'entities/Rating/components/Franchisee/StoreFeedbackList';
import { StoreFeedbackOrderSelector } from 'entities/Rating/components/Franchisee/StoreFeedbackOrderSelector';

interface IParams {
  id?: string;
}

interface IFilter {
  order?: EBaseOrderDirection;
  dateFrom?: string;
  dateTo?: string;
  isEnable?: boolean;
}

interface IComponentProps {
  isSalesOps?: boolean;
}

type AllProps = IStoreConnectedProps & RouteComponentProps<IParams> & IComponentProps;

interface IComponentState {
  filter: IFilter;
}

class StoreFeedbackPageComponent extends React.PureComponent<AllProps> {
  state: IComponentState = {
    filter: {
      order: undefined,
      dateFrom: undefined,
      dateTo: undefined
    }
  };
  constructor(props: AllProps) {
    super(props);
    if (props.isSalesOps) {
      this.state.filter.isEnable = true;
    }
    this.state.filter = { ...this.state.filter, ...queryToObject<IFilter>(this.state.filter) };
  }
  componentDidMount(): void {
    const { getStoreModel, match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      getStoreModel({ id });
    }
  }
  componentWillUnmount() {
    const { clearStoreModel } = this.props;

    clearStoreModel();
  }
  render() {
    const { history, storeModel, match } = this.props;
    const { params } = match;
    const { id } = params;
    const { filter } = this.state;
    const storeName = storeModel?.data?.storeName || 'Unknown store';
    const { order, dateFrom, dateTo } = filter;
    const defaultDateValue: [Moment, Moment] | undefined = dateFrom && dateTo ? [moment(dateFrom), moment(dateTo)] : undefined;
    const storeNameValue = `${storeName} - Feedback`;

    return (
      <FranchiseeCenterLayout>
        <Col span={24}>
          <Row align="middle" className="flex-noWrap cursor-pointer" onClick={() => history.goBack()}>
            <img className="mr-3" src={backIcon} alt="close menu" />
            <Row className="width-full" justify="center">
              <Typography.Paragraph className="m-0 p-0 franchisee__text_title" ellipsis={{ rows: 1 }} title={storeNameValue}>
                {storeNameValue}
              </Typography.Paragraph>
            </Row>
          </Row>
          <Col span={24} className="mt-5 mb-5">
            <div className="fs-xxs font-family__default mb-3">Rating</div>
            <StoreFeedbackOrderSelector onChange={this.onChangeSelector} value={order} />
          </Col>
          <Col span={24} className="mb-7">
            <div className="fs-xxs font-family__default mb-3">Time range</div>
            <DatePicker.RangePicker
              className="width-full franchisee__date-picker"
              defaultValue={defaultDateValue}
              ranges={rangeConfig}
              disabledDate={current => current && current > moment().endOf('day')}
              getPopupContainer={triggerNode => triggerNode as HTMLElement}
              format={DATE_FORMAT}
              onChange={this.onChangeDate}
              showTime
            />
          </Col>
          <StoreFeedbackList filter={{ ...filter, store: id }} elementId="basicLayout" />
        </Col>
      </FranchiseeCenterLayout>
    );
  }
  onChangeSelector = (orderValue?: EBaseOrderDirection) => {
    const order = orderValue ? orderValue : undefined;

    this.setFilter({ order });
  };

  setFilter = (partialFilter: Partial<IFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  onChangeDate = (value: any[] | null) => {
    if (value?.length) {
      const dateFrom = moment(value[0]).format();
      const dateTo = moment(value[1]).format();
      this.setFilter({ dateFrom, dateTo });
    } else {
      this.setFilter({ dateFrom: undefined, dateTo: undefined });
    }
  };
}

export const StoreFeedbackPage = communicationStore.injector(StoreFeedbackPageComponent);
