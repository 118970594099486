import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, notification, Row, DatePicker, TimePicker, InputNumber } from 'antd';
import { useFormMapper } from '@axmit/antd4-helpers';
import { StoreBranch } from '@axmit/redux-communications';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { EErrorStatus, IError } from 'common/helpers/error.helper';
import {
  DATE_FORMAT,
  DATE_FORMAT_WITH_TIME,
  formRules,
  MILITARY_TIME_FORMAT,
  requireText,
  TIME_FORMAT
} from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { timeFormatter } from 'common/helpers/date.helper';
import {
  IPromoCreateData,
  IPromoModel,
  IPromoParamsModel,
  EPromoType,
  EPromoCriteriaType,
  EPromoBonusType,
  EPromoPeriod,
  EPromoCustomerSelectType
} from 'entities/Promo/Promo.models';
import { StoreMultipleSelector } from 'entities/Store/components/Selector/StoreMultipleSelector';
import { PromoTypeSelector } from 'entities/Promo/components/Selector/PromoTypeSelector';
import { PromoCustomerTypeSelector } from 'entities/Promo/components/Selector/PromoCustomerTypeSelector';
import { PromoBonusTypeSelector } from 'entities/Promo/components/Selector/PromoBonusTypeSelector';
import { PromoCriteriaTypeSelector } from 'entities/Promo/components/Selector/PromoCriteriaTypeSelector';
import { MenuItemSelector } from 'entities/MenuItem/components/Selector/MenuItemSelector';
import { CategorySelector } from 'entities/Category/components/Selector/CategorySelector';
import { PromoPeriodSelector } from 'entities/Promo/components/Selector/PromoPeriodSelector';
import { CSVUploader } from 'entities/Promo/components/CSVUploader';
import { IStoreModel } from 'entities/Store/Store.models';

interface IComponentProps {
  createPromo?: (data: IPromoCreateData) => void;
  promoModel: StoreBranch<IPromoModel, IPromoParamsModel, IError>;
}

const PromoCreateFormComponent: React.FC<IComponentProps> = props => {
  const { promoModel, createPromo } = props;
  const { errors, loading, params } = promoModel;
  const [showPromoCode, setShowPromoCode] = useState<boolean>();
  const [bonusTypeValue, setBonusTypeValue] = useState<string | undefined>();
  const [criteriaValue, setCriteriaValue] = useState<string | undefined>();
  const [periodValue, setPeriodValue] = useState<string | undefined>();
  const [stores, setStores] = useState<string[] | undefined>();
  const [customerTarget, setCustomerTarget] = useState<string | undefined>();
  const submitButtonText = 'Save';
  const [form] = Form.useForm();
  const isPriceType = criteriaValue === EPromoCriteriaType.Price;
  const isCategoryType = criteriaValue === EPromoCriteriaType.Category;
  const isMenuItemType = criteriaValue === EPromoCriteriaType.MenuItem;
  const isEveryXPesos = criteriaValue === EPromoCriteriaType.EveryXPesos;

  const isAdditionalBonusType = bonusTypeValue === EPromoBonusType.Additional;
  const isAdditionalNotOnMenuBonusType = bonusTypeValue === EPromoBonusType.AdditionalNotOnMenu;
  const isDiscountItemBonusType = bonusTypeValue === EPromoBonusType.DiscountItem;
  const isDiscountAllPesoBonusType = bonusTypeValue === EPromoBonusType.DiscountAllPeso;
  const isDiscountAllPercentBonusType = bonusTypeValue === EPromoBonusType.DiscountAllPercent;

  const { fields, setField } = useFormMapper(
    [
      'name',
      'type',
      'promoPeriod',
      'promoDates',
      'promoTimes',
      'code',
      'stores',
      'customerTarget',
      'criteriaMenuItems',
      'criteriaCategories',
      'criteria',
      'criteriaPrice',
      'criteriaEveryPesos',
      'bonusType',
      'discountPercent',
      'discountFix',
      'bonusMenuItem',
      'maxPesos',
      'itemQuantity',
      'maxRedemptions',
      'csvFile',
      'description'
    ],
    null,
    params,
    errors
  );
  useEffect(() => {
    const { type, criteria, bonusType, startDate, endDate, startTime, endTime, customerTarget } = params || {};
    if (type) {
      const showCodeFiled = type === EPromoType.Code;
      setShowPromoCode(showCodeFiled);
    }
    if (criteria) {
      setCriteriaValue(criteria);
    }
    if (bonusType) {
      setBonusTypeValue(bonusType);
    }
    if (startDate && endDate) {
      if (startTime && endTime) {
        setPeriodValue(EPromoPeriod.specificDateAndTime);
      } else {
        setPeriodValue(EPromoPeriod.specificDate);
      }
    }
    if (customerTarget) {
      setCustomerTarget(customerTarget);
    }
  }, [params]);

  // It needs for mapping errors with separate date values to one datepicker control
  useEffect(() => {
    const dateError = errors?.data?.errors?.['.startDate'] || errors?.data?.errors?.['.endDate'];
    const csvError = errors?.data?.errors?.['.csvFileId'];

    if (errors?.data?.statusCode === EErrorStatus.Validation) {
      if (dateError) {
        setField({ name: 'promoDates', errors: dateError.map(it => it.message) });
      }

      if (csvError) {
        setField({ name: 'csvFile', errors: csvError.map(it => it.message) });
      }
    }
  }, [errors]);

  const addPromo = (values: any) => {
    const { promoDates, promoTimes, stores, criteriaMenuItems, bonusMenuItem, criteriaCategories, csvFile } = values;
    const params: IPromoCreateData = {
      ...values,
      storesArrayValue: stores,
      startDate: promoDates[0],
      endDate: promoDates[1],
      startTime: promoTimes ? timeFormatter(promoTimes[0], MILITARY_TIME_FORMAT) : null,
      endTime: promoTimes ? timeFormatter(promoTimes[1], MILITARY_TIME_FORMAT) : null,
      criteriaMenuItemsValue: criteriaMenuItems,
      bonusMenuItemValue: bonusMenuItem,
      criteriaCategoriesValue: criteriaCategories,
      csvFile
    };

    if (csvFile) {
      params.csvFileId = csvFile.uid;
    }

    createPromo && createPromo(params);
  };

  const onFieldsChange = (changedValues: any[]) => {
    const itemPromoType = changedValues.find(item => item.name[0] === 'type');
    const itemCriteria = changedValues.find(item => item.name[0] === 'criteria');
    const itemBonusType = changedValues.find(item => item.name[0] === 'bonusType');
    const itemPromoPeriod = changedValues.find(item => item.name[0] === 'promoPeriod');
    const itemStores = changedValues.find(item => item.name[0] === 'stores');
    const customerTarget = changedValues.find(item => item.name[0] === 'customerTarget');

    if (itemPromoType) {
      const showCodeFiled = itemPromoType.value === EPromoType.Code;
      setShowPromoCode(showCodeFiled);
    }
    if (itemCriteria) {
      setCriteriaValue(itemCriteria.value);
    }
    if (itemBonusType) {
      if (itemBonusType.value !== bonusTypeValue) {
        form.setFieldsValue({ bonusMenuItem: undefined });
      }

      setBonusTypeValue(itemBonusType.value);
    }
    if (itemPromoPeriod) {
      setPeriodValue(itemPromoPeriod.value);
    }
    if (itemStores) {
      setStores(itemStores.value.map((it: IStoreModel) => it.id));
      if (isAdditionalNotOnMenuBonusType) {
        form.setFieldsValue({ bonusMenuItem: undefined });
      }
    }
    if (customerTarget) {
      setCustomerTarget(customerTarget.value);
    }
  };

  return (
    <Form className="mt-5" form={form} onFinish={addPromo} fields={fields} autoComplete="off" onFieldsChange={onFieldsChange}>
      <span className="basic__text_title" title="General info">
        General info
      </span>
      <Row gutter={[28, 10]}>
        <Col span={12}>
          <Form.Item rules={[formRules.required]} name="name" label="Promo name">
            <Input type="text" name="name" placeholder="Enter promo name" disabled={loading} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item rules={[formRules.required]} name="type" label="Promo type">
            <PromoTypeSelector placeholder="Select promo type" />
          </Form.Item>
        </Col>
        <Col span={12}>
          {showPromoCode && (
            <Form.Item
              rules={[
                formRules.required,
                {
                  min: 4,
                  max: 16,
                  message: 'Promo code should be equal to 4-16 digits'
                }
              ]}
              name="code"
              label="Promo code"
            >
              <Input name="code" type="text" placeholder="Promo code" disabled={loading} />
            </Form.Item>
          )}
        </Col>
      </Row>
      <Row gutter={[28, 10]}>
        <Col span={12}>
          <Form.Item rules={[formRules.required]} name="promoPeriod" label="Promo period">
            <PromoPeriodSelector placeholder="Select promo period" />
          </Form.Item>
        </Col>
        <Col span={12}>
          {(periodValue === EPromoPeriod.specificDate || periodValue === EPromoPeriod.specificDateAndTime) && (
            <Form.Item rules={[formRules.required]} name="promoDates" label="Promo dates">
              <DatePicker.RangePicker
                className="width-full"
                disabledDate={current => current && current < moment().startOf('day')}
                getPopupContainer={triggerNode => triggerNode as HTMLElement}
                format={periodValue === EPromoPeriod.specificDate ? DATE_FORMAT_WITH_TIME : DATE_FORMAT}
                showTime={periodValue === EPromoPeriod.specificDate}
              />
            </Form.Item>
          )}
          {periodValue === EPromoPeriod.specificDateAndTime && (
            <Form.Item rules={[formRules.required]} name="promoTimes" label="Promo time range">
              <TimePicker.RangePicker
                className="width-full"
                getPopupContainer={triggerNode => triggerNode as HTMLElement}
                format={TIME_FORMAT}
              />
            </Form.Item>
          )}
        </Col>
      </Row>
      <Row gutter={[28, 10]}>
        <Col span={12}>
          <Form.Item rules={[formRules.required]} name="description" label="Promo description">
            <Input.TextArea maxLength={300} autoSize={{ minRows: 3 }} showCount={true} />
          </Form.Item>
        </Col>
      </Row>
      <span className="basic__text_title" title="Eligibility">
        Eligibility
      </span>
      <Row gutter={[28, 10]}>
        <Col span={12}>
          <Form.Item label="Store" name="stores">
            <StoreMultipleSelector />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: requireText
              }
            ]}
            label="Customer"
            name="customerTarget"
          >
            <PromoCustomerTypeSelector />
          </Form.Item>
          {customerTarget === EPromoCustomerSelectType.Specific && (
            <Form.Item
              name="csvFile"
              rules={[
                {
                  required: true,
                  message: 'Please upload a CSV'
                }
              ]}
            >
              <CSVUploader />
            </Form.Item>
          )}
        </Col>
      </Row>

      <span className="basic__text_title" title="Redemption criteria">
        Redemption criteria
      </span>
      <Row gutter={[28, 10]}>
        <Col span={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: requireText
              }
            ]}
            label="Criterion"
            name="criteria"
          >
            <PromoCriteriaTypeSelector placeholder="Select criterion" />
          </Form.Item>
        </Col>
        <Col span={12}>
          {isMenuItemType && (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: requireText
                }
              ]}
              label="Menu item"
              name="criteriaMenuItems"
            >
              <MenuItemSelector placeholder="Select menu item" />
            </Form.Item>
          )}
          {isCategoryType && (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: requireText
                }
              ]}
              label="Category"
              name="criteriaCategories"
            >
              <CategorySelector placeholder="Select category" isAddOns={false} />
            </Form.Item>
          )}
          {isPriceType && (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: requireText
                },
                {
                  type: 'number',
                  min: 1,
                  max: 10000,
                  message: 'Value must be equal to 1-10000'
                }
              ]}
              label="Min order value (P)"
              name="criteriaPrice"
            >
              <InputNumber type="number" name="criteriaPrice" disabled={loading} />
            </Form.Item>
          )}
          {isEveryXPesos && (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: requireText
                },
                {
                  type: 'number',
                  min: 1,
                  max: 1500,
                  message: 'Value must be equal to 1-1500'
                }
              ]}
              label="X pesos (P)"
              name="criteriaEveryPesos"
            >
              <InputNumber type="number" name="criteriaEveryPesos" disabled={loading} max={1500} min={1} />
            </Form.Item>
          )}
        </Col>
      </Row>
      <span className="basic__text_title" title="Reward">
        Reward
      </span>
      <Row gutter={[28, 10]}>
        <Col span={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: requireText
              }
            ]}
            label="Reward option"
            name="bonusType"
          >
            <PromoBonusTypeSelector placeholder="Select reward option" isEveryXPesos={isEveryXPesos} />
          </Form.Item>
        </Col>
        <Col span={12}>
          {(isAdditionalBonusType || isAdditionalNotOnMenuBonusType || isDiscountItemBonusType) && (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: requireText
                }
              ]}
              label="Menu item"
              name="bonusMenuItem"
            >
              <MenuItemSelector
                placeholder="Select menu item"
                params={{ stores, isInMenu: false }}
                isNotOnMenu={isAdditionalNotOnMenuBonusType}
              />
            </Form.Item>
          )}
          {isDiscountAllPercentBonusType && (
            <Form.Item
              rules={[
                {
                  type: 'number',
                  min: 1,
                  message: 'Value must be more than 0'
                }
              ]}
              label="Max pesos (P)"
              name="maxPesos"
            >
              <InputNumber type="number" name="maxPesos" disabled={loading} min={1} />
            </Form.Item>
          )}
        </Col>
        {(isAdditionalBonusType || isAdditionalNotOnMenuBonusType || isDiscountItemBonusType) && (
          <>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: requireText
                  },
                  {
                    type: 'number',
                    min: 1,
                    message: 'Value must be greater than to 0'
                  }
                ]}
                label="Item quantity"
                name="itemQuantity"
              >
                <InputNumber
                  type="number"
                  name="itemQuantity"
                  disabled={loading}
                  min={1}
                  placeholder="Specify the number of items"
                />
              </Form.Item>
            </Col>
            {!isPriceType && (
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: requireText
                    },
                    {
                      type: 'number',
                      min: 1,
                      message: 'Value must be greater than to 0'
                    }
                  ]}
                  label="Max redemptions"
                  name="maxRedemptions"
                >
                  <InputNumber
                    type="number"
                    name="maxRedemptions"
                    disabled={loading}
                    min={1}
                    placeholder="Specify the max redemptions"
                  />
                </Form.Item>
              </Col>
            )}
          </>
        )}
        <Col span={12}>
          {(isDiscountAllPesoBonusType || isDiscountItemBonusType) && (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: requireText
                },
                {
                  type: 'number',
                  min: 1,
                  max: 10000,
                  message: 'Discount value must be equal to 1-10000'
                }
              ]}
              label="Discount (P)"
              name="discountFix"
            >
              <InputNumber
                type="number"
                name="discountFix"
                disabled={loading}
                max={10000}
                min={1}
                placeholder="Specify the discount"
              />
            </Form.Item>
          )}
          {isDiscountAllPercentBonusType && (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: requireText
                },
                {
                  type: 'number',
                  min: 1,
                  max: 100,
                  message: 'Discount value must be equal to 1-100'
                }
              ]}
              label="Discount (%)"
              name="discountPercent"
            >
              <InputNumber
                type="number"
                name="discountPercent"
                disabled={loading}
                max={100}
                min={1}
                placeholder="Specify the discount"
              />
            </Form.Item>
          )}
        </Col>
      </Row>

      <Col span={10} className="mt-4">
        <Row gutter={[28, 0]}>
          <Col span={12}>
            <Form.Item className="mb-0">
              <Button htmlType="submit" type="primary" block disabled={loading} loading={loading} title={submitButtonText}>
                {submitButtonText}
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Link to={ERoutesPrivate.Promo}>
              <Button block disabled={loading} loading={loading} title="Cancel" onClick={notification.destroy}>
                Cancel
              </Button>
            </Link>
          </Col>
        </Row>
      </Col>
    </Form>
  );
};

export const PromoCreateForm = PromoCreateFormComponent;
