import { Col, Row, Skeleton } from 'antd';
import React from 'react';
import { SkeletonFormItem } from 'common/components/Skeleton/SkeletonFormItem';

export const PromoEditFormSkeleton: React.FC = () => {
  return (
    <>
      <span className="basic__text_title" title="General info">
        General info
      </span>
      <div>
        <Row gutter={[28, 10]}>
          <Col span={12}>
            <SkeletonFormItem label="Promo type" boldTitle>
              <Skeleton.Input active size="small" />
            </SkeletonFormItem>
          </Col>
          <Col span={12}>
            <SkeletonFormItem label="Promo code" boldTitle>
              <Skeleton.Input active size="small" />
            </SkeletonFormItem>
          </Col>
          <Col span={12}>
            <SkeletonFormItem label="Start date" boldTitle>
              <Skeleton.Input active size="small" />
            </SkeletonFormItem>
          </Col>
          <Col span={12}>
            <SkeletonFormItem label="End date" boldTitle>
              <Skeleton.Input active size="small" />
            </SkeletonFormItem>
          </Col>
        </Row>

        <Row align="middle">
          <span className="basic__text_title" title="Eligibility">
            Eligibility
          </span>
        </Row>

        <Row gutter={[28, 10]}>
          <Col span={12}>
            <SkeletonFormItem label="Customer" boldTitle>
              <Skeleton.Input active size="small" />
            </SkeletonFormItem>
          </Col>
          <Col span={12}>
            <SkeletonFormItem label="Store" boldTitle>
              <Skeleton.Input active size="small" />
            </SkeletonFormItem>
          </Col>
        </Row>

        <Row align="middle">
          <span className="basic__text_title" title="Redemption criteria">
            Redemption criteria
          </span>
        </Row>
        <Row gutter={[28, 10]}>
          <Col span={12}>
            <SkeletonFormItem label="Criterion" boldTitle>
              <Skeleton.Input active size="small" />
            </SkeletonFormItem>
          </Col>
        </Row>
        <Row align="middle">
          <span className="basic__text_title" title="Reward">
            Reward
          </span>
        </Row>
        <Row gutter={[28, 10]}>
          <Col span={12}>
            <SkeletonFormItem label="Reward option" boldTitle>
              <Skeleton.Input active size="small" />
            </SkeletonFormItem>
          </Col>
        </Row>
        <Row align="middle">
          <span className="basic__text_title" title="Promo status">
            Promo status
          </span>
        </Row>
        <Row gutter={[28, 10]}>
          <Col span={12}>
            <SkeletonFormItem>
              <Skeleton.Input active />
            </SkeletonFormItem>
          </Col>
        </Row>

        <Row className="flex-noWrap">
          <Col span={10}>
            <Row gutter={[28, 0]}>
              <Col span={8}>
                <SkeletonFormItem>
                  <Skeleton.Button active />
                </SkeletonFormItem>
              </Col>
              <Col span={8}>
                <SkeletonFormItem>
                  <Skeleton.Button active />
                </SkeletonFormItem>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <SkeletonFormItem>
              <Skeleton.Button active />
            </SkeletonFormItem>
          </Col>
        </Row>
      </div>
    </>
  );
};
