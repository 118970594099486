import { SelectProps } from 'antd/es/select';
import React from 'react';
import { Row, Select } from 'antd';
import { ERatingStatus, ERatingStatusText } from 'entities/Rating/Rating.models';

interface IComponentProps {
  onChangeStatus: (value?: ERatingStatus) => void;
}

type AllProps = SelectProps<any> & IComponentProps;

export class RatingStatusSelector extends React.PureComponent<AllProps> {
  handleChange = (value: ERatingStatus) => {
    const { onChangeStatus } = this.props;
    onChangeStatus(value);
  };

  render() {
    const { value } = this.props;
    return (
      <Row className="flex-noWrap" justify="space-between" align="middle">
        <span className="mr-4">Visibility</span>
        <Select
          allowClear
          className="width-full"
          placeholder="Select"
          value={value}
          defaultActiveFirstOption={false}
          showArrow
          onChange={(val: ERatingStatus) => this.handleChange(val)}
        >
          <Select.Option key={ERatingStatus.NotVisible} value={ERatingStatus.NotVisible} label={ERatingStatusText.NotVisible}>
            {ERatingStatusText.NotVisible}
          </Select.Option>
          <Select.Option key={ERatingStatus.Visible} value={ERatingStatus.Visible} label={ERatingStatusText.Visible}>
            {ERatingStatusText.Visible}
          </Select.Option>
        </Select>
      </Row>
    );
  }
}
