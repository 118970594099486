import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import {
  IAssetCollection,
  IAssetCollectionFilter,
  IAssetCreateData,
  IAssetModel,
  IAssetUpdateData
} from 'entities/Asset/Asset.models';

const basePath = '/assets';

class AssetTransport extends BaseHttpTransport<
  AxiosStatic,
  IAssetModel,
  IAssetCollection,
  IAssetCreateData,
  IAssetUpdateData,
  IAssetCollectionFilter
> {}

export const assetTransport = new AssetTransport(basePath, axios);
