import React from 'react';
import { InfiniteList } from 'common/components/List/InfiniteList';
import { IBaseFilterModel } from 'common/models/requestModels';
import { communicationRating, IRatingConnectedProps } from 'entities/Rating/Rating.communication';
import { StoreFeedbackListItem } from 'entities/Rating/components/Franchisee/StoreFeedbackListItem';
import { IRatingCollection, IRatingCollectionFilter, IRatingModel } from 'entities/Rating/Rating.models';

type AllProps = IRatingConnectedProps;

class StoreFeedbackListComponent extends InfiniteList<IRatingCollection, IRatingModel, AllProps, IRatingCollectionFilter> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getRatingCollection, filter } = this.props;
    const { dateTo, dateFrom, order, store, isEnable } = filter || {};
    const filterParams: IRatingCollectionFilter = {
      ...params
    };
    if (dateTo && dateFrom) {
      filterParams.dateFrom = dateFrom;
      filterParams.dateTo = dateTo;
    }
    if (order) {
      filterParams.orderField = 'rate';
      filterParams.orderDirection = order;
    }
    if (store) {
      filterParams.store = store;
    }
    if (isEnable !== undefined) {
      filterParams.isEnable = isEnable;
    }
    getRatingCollection(filterParams);
  };

  renderListItem = (item: IRatingModel) => {
    return <StoreFeedbackListItem item={item} />;
  };

  getCollection = () => {
    return this.props.ratingCollection;
  };
  clearCollection = () => {
    this.props.clearRatingCollection();
  };
}

export const StoreFeedbackList = communicationRating.injector(StoreFeedbackListComponent);
