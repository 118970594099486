import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { statisticTransport } from 'entities/Statistic/Statistic.transport';
import {
  IStatisticCollection,
  IStatisticCollectionFilter,
  IStatisticGlobalParams,
  IStatisticGlobalModel,
  IStatisticModel,
  IStatisticStoreParams,
  IStatisticParams,
  IStatisticCSV,
  IStatisticSalesOpsParams
} from 'entities/Statistic/Statistic.models';

const namespace = 'statistic';

export interface IStatisticConnectedProps {
  statisticModel: StoreBranch<IStatisticModel, IStatisticStoreParams>;
  statisticCollection: StoreBranch<IStatisticCollection, IStatisticCollectionFilter>;
  statisticGlobalModel: StoreBranch<IStatisticGlobalModel, IStatisticGlobalParams>;
  statisticSalesOpsModel: StoreBranch<IStatisticGlobalModel, IStatisticSalesOpsParams>;

  getStatisticModel(params: IStatisticParams): void;

  getStatisticCollection(filter?: IStatisticCollectionFilter): void;
  getStatisticGlobalModel(params?: IStatisticGlobalParams): void;
  getStatisticSalesOpsModel(params?: IStatisticSalesOpsParams): void;

  clearStatisticModel(): void;
  clearStatisticCollection(): void;
  clearStatisticGlobalCollection(): void;
  getStatisticCSV(): void;
}

const StatisticModelAPIProviders = [new APIProvider(EActionsTypes.get, statisticTransport.getModel)];

const StatisticCollectionAPIProviders = [new APIProvider(EActionsTypes.get, statisticTransport.getCollection)];
const StatisticGlobalModelAPIProviders = [new APIProvider(EActionsTypes.get, statisticTransport.getGlobalModel)];
const StatisticSalesOpsModelAPIProviders = [new APIProvider(EActionsTypes.get, statisticTransport.getSalesOpsModel)];

const CSVApiProvider = [
  new APIProvider(EActionsTypes.get, statisticTransport.getStatisticCSV, {
    onSuccess: (response: IStatisticCSV) => {
      const file = new Blob([response], { type: 'application/zip' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  })
];

const branches = [
  new Branch('model', StatisticModelAPIProviders),
  new Branch('collection', StatisticCollectionAPIProviders),
  new Branch('globalModel', StatisticGlobalModelAPIProviders),
  new Branch('salesOpsModel', StatisticSalesOpsModelAPIProviders),
  new Branch('CSV', CSVApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationStatistic = buildCommunication<IStatisticConnectedProps>(strategy);
