import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import {
  IRatingCollection,
  IRatingCollectionFilter,
  IRatingCreateData,
  IRatingModel,
  IRatingUpdateData
} from 'entities/Rating/Rating.models';

const basePath = '/ratings';

class RatingTransport extends BaseHttpTransport<
  AxiosStatic,
  IRatingModel,
  IRatingCollection,
  IRatingCreateData,
  IRatingUpdateData,
  IRatingCollectionFilter
> {}

export const ratingTransport = new RatingTransport(basePath, axios);
