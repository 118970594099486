import React, { memo } from 'react';
import { Col, message, Row } from 'antd';
import QRCode from 'qrcode.react';
import copy from 'copy-to-clipboard';
import { envConfig } from 'common/config/env.config';
import { ReactComponent as DownloadIcon } from 'app/assets/icons/download.svg';
import { ReactComponent as CopyIcon } from 'app/assets/icons/copy.svg';
import { QR_CANVAS_SIZE, QR_DOWNLOAD_IMAGE_SIZE, QR_IMAGE_EXT, QR_IMAGE_TYPE } from 'entities/QR/QR.const';

interface IComponentProps {
  qrName: string;
  isImageHidden?: boolean;
}

const QRCodeBlockComponent: React.FC<IComponentProps> = props => {
  const { qrName, isImageHidden = false } = props;
  const encodedQRName = encodeURI(qrName);
  const currentHost = window.location.origin;
  const host = envConfig.NODE_ENV === 'development' ? `https://dev.mb.axmit.com` : currentHost.split('admin.')[1];
  const link = `${host}/scan-page?qr=${encodedQRName}`;

  const downloadQR = (e: Event) => {
    e.stopPropagation();
    const canvas = document.getElementById(qrName) as HTMLCanvasElement | null;
    if (!canvas || canvas?.toDataURL === undefined) {
      return;
    }
    const pngUrl = canvas.toDataURL(QR_IMAGE_TYPE);
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `${qrName}.${QR_IMAGE_EXT}`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    message.info('Download has been started');
  };

  const copyLink = (e: Event) => {
    e.stopPropagation();
    copy(link);
    message.info('Copied to the clipboard');
  };

  return (
    <Row>
      <Col>
        <QRCode
          style={{ width: QR_CANVAS_SIZE, height: QR_CANVAS_SIZE }}
          className={isImageHidden ? 'd-none' : ''}
          id={qrName}
          value={link}
          size={QR_DOWNLOAD_IMAGE_SIZE}
          level="H"
          includeMargin={false}
        />
        <Row justify="center">
          <DownloadIcon className="cursor-pointer" onClick={downloadQR} />
          <CopyIcon className="cursor-pointer ml-4" onClick={copyLink} />
        </Row>
      </Col>
    </Row>
  );
};

export const QRCodeBlock = memo(QRCodeBlockComponent);
