import React from 'react';
import { Col, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons/lib';

export interface IMultipleSelectorListItem {
  id: string;
  title: string;
}

interface IComponentProps {
  items: IMultipleSelectorListItem[];
  onClick: (item: IMultipleSelectorListItem) => void;
}

export class MultipleSelectorList extends React.Component<IComponentProps> {
  render() {
    const { items, onClick } = this.props;

    return (
      <Col>
        {items.map(item => (
          <Row key={item.id} align="middle">
            <CloseOutlined type="close" className="mr-3" onClick={() => onClick(item)} />
            <span className="basic__text_paragraph">{item.title}</span>
          </Row>
        ))}
      </Col>
    );
  }
}
