import { BaseHttpTransport } from '@axmit/transport';
import axios, { AxiosStatic } from 'axios';
import { IQRModel } from 'entities/QR/QR.models';

const basePath = 'qr-codes';

class QRTransport extends BaseHttpTransport<AxiosStatic, IQRModel> {
  public update = (params: any): Promise<IQRModel> =>
    axios.put(`${basePath}/${params.path?.id}`, {
      ...params.data
    });
}

export const qrTransport = new QRTransport(basePath, axios);
