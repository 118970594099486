import { AbstractSearchableSelector } from 'common/components/Input/AbstractSearchableSelector';
import { IAssetModel } from 'entities/Asset/Asset.models';
import { assetTransport } from 'entities/Asset/Asset.transport';

interface IComponentProps {}

export class AssetSelector extends AbstractSearchableSelector<IAssetModel, IComponentProps> {
  getItemLabel = (item: IAssetModel): string => {
    return item.name;
  };

  loadData = (value?: string): Promise<{ data: IAssetModel[] }> => {
    return assetTransport.getCollection({ search: value || '' });
  };
}
