import axios from 'axios';
import { ICheckTokenData } from 'common/models/commonModel';
import { IAuthModel, IAuthData, IAuthPasswordRestoreParams, IAuthPasswordForgotParams } from 'entities/Auth/Auth.models';

const basePath = '/auth';
const restorePassword = '/restore-password';

export const authTransport = {
  login: (data: IAuthData): Promise<IAuthModel> => axios.post(basePath, data),
  logout: (): Promise<void> => axios.delete(basePath),

  passwordForgot: (data: IAuthPasswordForgotParams): Promise<void> => axios.post(restorePassword, data),
  passwordRestore: (data: IAuthPasswordRestoreParams): Promise<void> => axios.put(restorePassword, data),

  checkInviteToken: (data: ICheckTokenData): Promise<void> => axios.post(`${restorePassword}/check-token`, data)
};
