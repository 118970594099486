import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Row, Menu } from 'antd';
import { ERoutesPrivate } from 'common/models/routesModel';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';

type AllProps = IAuthConnectedProps & RouteComponentProps;

class HeaderComponent extends React.Component<AllProps> {
  render() {
    const { authModel, history } = this.props;
    const { loading } = authModel;
    const path = history.location.pathname;
    const currentPath = path.split(/(^\/[a-zA-Z]*)/)[1];

    return (
      <Menu selectedKeys={[currentPath]} mode="horizontal" className="header-franchisor">
        <Menu.Item key={ERoutesPrivate.Users} title="Users" onClick={this.redirect} disabled={loading}>
          <Row justify="center" align="middle" className="width-full">
            Users
          </Row>
        </Menu.Item>
        <Menu.Item key={ERoutesPrivate.Menu} title="Menu" onClick={this.redirect} disabled={loading}>
          <Row justify="center" align="middle" className="width-full">
            Menu
          </Row>
        </Menu.Item>
        <Menu.Item key={ERoutesPrivate.Stores} title="Stores" onClick={this.redirect} disabled={loading}>
          <Row justify="center" align="middle" className="width-full">
            Stores
          </Row>
        </Menu.Item>
        <Menu.Item key={ERoutesPrivate.Customers} title="Customers" onClick={this.redirect} disabled={loading}>
          <Row justify="center" align="middle" className="width-full">
            Customers
          </Row>
        </Menu.Item>
        <Menu.Item key={ERoutesPrivate.Rating} title="Customers feedback" onClick={this.redirect} disabled={loading}>
          <Row justify="center" align="middle" className="width-full">
            Customers feedback
          </Row>
        </Menu.Item>
        <Menu.Item key={ERoutesPrivate.Statistics} title="Statistics" onClick={this.redirect} disabled={loading}>
          <Row justify="center" align="middle" className="width-full">
            Statistics
          </Row>
        </Menu.Item>
        <Menu.Item key={ERoutesPrivate.QR} title="QRs" onClick={this.redirect} disabled={loading}>
          <Row justify="center" align="middle" className="width-full">
            QRs
          </Row>
        </Menu.Item>
        <Menu.Item key={ERoutesPrivate.Promo} title="Promo" onClick={this.redirect} disabled={loading}>
          <Row justify="center" align="middle" className="width-full">
            Promo
          </Row>
        </Menu.Item>
        <Menu.Item key={ERoutesPrivate.Profile} title="Profile" onClick={this.redirect} disabled={loading}>
          <Row justify="center" align="middle" className="width-full">
            Profile
          </Row>
        </Menu.Item>
        <Menu.Item className="header__button_logout" key="logout" title="Log Out" onClick={this.logout} disabled={loading}>
          Log Out
        </Menu.Item>
      </Menu>
    );
  }

  logout = () => {
    const { deleteAuthModel } = this.props;
    deleteAuthModel();
  };

  redirect = (e: any) => {
    const { history } = this.props;
    history.push(e.key);
  };
}

export const Header = communicationAuth.injector(withRouter(HeaderComponent));
