import { IBasePathParams } from 'common/models/requestModels';

export interface ICustomerModel extends Components.Schemas.CustomerDto {}
export interface ICustomerUpdateStatusParams extends Components.Schemas.CustomerBlockDto, IBasePathParams {}
export interface ICustomerParamsModel extends ICustomerUpdateStatusParams, ICustomerUpdateStatusParams, IBasePathParams {}
export interface ICustomerCollection extends Components.Schemas.CustomerCollectionDto {}
export interface ICustomerCollectionFilter extends Paths.CustomerControllerGetList.QueryParameters {
  pageIndex?: number;
}

export enum ECustomerSuccessMessage {
  StatusUpdate = 'Customer status has been successfully updated'
}

export enum ECustomerStatus {
  Active = 'active',
  Blocked = 'blocked',
  Pending = 'pending'
}
export enum ECustomerStatusText {
  Active = 'Active',
  Blocked = 'Blocked',
  Pending = 'Pending'
}

export const tableCustomerConfig = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    ellipsis: true
  },
  {
    title: 'Phone Number',
    dataIndex: 'phone',
    key: 'phone',
    ellipsis: true
  },
  {
    title: 'Birthdate',
    dataIndex: 'birthDate',
    key: 'birthDate',
    ellipsis: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true
  }
];
