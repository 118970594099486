import { IBasePathParams } from 'common/models/requestModels';
import { IUserModel } from 'entities/User/User.models';
import { IAssetModel } from 'entities/Asset/Asset.models';

export interface IStoreModel extends Components.Schemas.StoreDto {
  franchiseeName?: string;
  addressName?: string;
}
export interface IStoreCollection extends Components.Schemas.StoreCollectionDto {}
export interface IStoreUpdateData extends Omit<Components.Schemas.UpdateStoreDto, 'franchisee' | 'asset'> {
  franchisee: Partial<IUserModel>;
  asset: Partial<IAssetModel>;
}
export interface IStoreCreateData extends Omit<Components.Schemas.CreateStoreDto, 'franchisee' | 'asset'> {
  franchisee: Partial<IUserModel>;
  asset: Partial<IAssetModel>;
}
export interface IStoreLocation extends Components.Schemas.CreateAddressDto {}
export interface IStoreCollectionFilter extends Paths.StoreControllerList.QueryParameters {
  pageIndex?: number;
  franchisee?: Partial<IUserModel>;
}

export interface IStoreParams {
  path: IBasePathParams;
  data: IStoreUpdateData | IStoreCreateData;
}

export enum EStoreStatus {
  New = 'new',
  Active = 'active',
  Archived = 'archive',
  Unavailable = 'temporarilyUnavailable'
}
export enum EStoreStatusText {
  New = 'New',
  Active = 'Active',
  Archived = 'Archived',
  Unavailable = 'Temporarily unavailable'
}

export enum EStoreSuccessMessage {
  Delete = 'Store has been successfully deleted',
  Create = 'Store has been successfully created',
  Edit = 'Store has been successfully edited'
}

export enum EStoreWorkSchedule {
  FullTime = '24/7',
  MonSat = 'Mon-Sat',
  MonSun = 'Mon-Sun'
}

export const selectorStoreAllKeyId = 'All';

export const tableStoreConfig = [
  {
    title: 'Store code',
    dataIndex: 'storeCode',
    key: 'storeCode',
    width: 100,
    ellipsis: true
  },
  {
    title: 'Store ID',
    dataIndex: 'shortStoreId',
    key: 'shortStoreId',
    width: 100,
    ellipsis: true
  },
  {
    title: 'Store name',
    dataIndex: 'storeName',
    key: 'storeName',
    ellipsis: true
  },
  {
    title: 'Address',
    dataIndex: 'addressName',
    key: 'addressName',
    ellipsis: true
  },
  {
    title: 'Phone Number',
    dataIndex: 'phone',
    key: 'phone',
    ellipsis: true
  },
  {
    title: 'Franchisee',
    dataIndex: 'franchiseeName',
    key: 'franchiseeName',
    ellipsis: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true
  }
];
