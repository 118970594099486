import React from 'react';
import { Row, Col, Button } from 'antd';
import { useMediaPredicate } from 'react-media-hook';
import { Link } from 'react-router-dom';
import { HeaderMenu } from 'common/components/Header/HeaderMenu';
import { HeaderMenuWrapperMob } from 'common/components/Header/HeaderMenuWrapperMob';
import { MEDIA_MOBILE } from 'common/const/common.const';
import { ERoutesPublic } from 'common/models/routesModel';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';

type AllProps = IAuthConnectedProps;

const HeaderFranchiseeComponent: React.FC<AllProps> = ({ authModel, deleteAuthModel }) => {
  const isMobile: boolean = useMediaPredicate(MEDIA_MOBILE);
  const { data } = authModel;
  const authorized = data && Object.keys(data).length !== 0;
  const visibleLogout = authorized && !isMobile;
  const visibleLogin = !authorized && !isMobile;

  const menuLayout = { lg: 20, md: 19, sm: 17, xs: 16 };

  return (
    <header>
      <div className="ant-menu header-franchisee">
        <Row className="basic__desktop-wrapper flex-noWrap height-full" justify="space-between" align="middle">
          <Col {...menuLayout}>{isMobile ? <HeaderMenuWrapperMob /> : <HeaderMenu />}</Col>

          <Row justify="end" align="middle">
            {visibleLogout && (
              <Button onClick={deleteAuthModel} className="header-franchisee__desktop-logout-btn pr-0">
                Log out
              </Button>
            )}
            {visibleLogin && (
              <Link to={ERoutesPublic.Login}>
                <Button className="header-franchisee__desktop-logout-btn pr-0">Log In</Button>
              </Link>
            )}
          </Row>
        </Row>
      </div>
    </header>
  );
};

export const HeaderFranchisee = communicationAuth.injector(HeaderFranchiseeComponent);
