import { IBasePathParams } from 'common/models/requestModels';

export interface IStatisticModel extends Components.Schemas.StatisticStoreDto {}
export interface IStatisticParams
  extends Paths.StatisticControllerGetStoreStatistic.QueryParameters,
    Paths.StatisticControllerGetStoreStatistic.PathParameters {}
export interface IStatisticCollection extends Components.Schemas.StatisticStoreCollectionDto {}
export interface IStatisticCollectionFilter extends Paths.StatisticControllerGetStoreStatisticList.QueryParameters {}

export interface IStatisticGlobalModel extends Components.Schemas.StatisticStoreGlobalDto {}
export interface IStatisticGlobalParams extends Paths.StatisticControllerGetStoreStatisticGlobal.QueryParameters {}
export interface IStatisticSalesOpsParams extends Paths.StatisticControllerGetStoreStatisticListByFranchisee.QueryParameters {}

export interface IStatisticStoreParams extends Paths.StatisticControllerGetStoreStatistic.QueryParameters, IBasePathParams {}

export interface IStatisticProductMixModel extends Components.Schemas.ProductMix {}

export interface IStatisticOutOfStockProductModel extends Components.Schemas.OutOfStockProduct {}
export interface IStatisticCSV extends Blob {}

export enum EStatisticRange {
  Today = 'today',
  Yesterday = 'yesterday',
  Last7Days = 'last7Days',
  Last14Days = 'last14Days',
  Last30Days = 'last30Days',
  ThisWeek = 'thisWeek',
  LastWeek = 'lastWeek',
  ThisMonth = 'thisMonth',
  LastMonth = 'lastMonth'
}

export enum EStatisticRangeText {
  Today = 'Today',
  Yesterday = 'Yesterday',
  ThisWeek = 'This week',
  ThisMonth = 'This month',
  LastWeek = 'Last week',
  LastMonth = 'Last month',
  Last7Days = 'Last 7 days',
  Last14Days = 'Last 14 days',
  Last30Days = 'Last 30 days'
}
export enum EStatisticStoreOrderTabText {
  New = 'New',
  Preparing = 'Preparing',
  Ready = 'Ready',
  Completed = 'Completed',
  Canceled = 'Canceled'
}

export enum EStatisticStoreOrderStatus {
  New = 'new',
  Preparing = 'inProgress',
  Ready = 'ready',
  Completed = 'finished',
  CanceledByCustomer = 'rejectedByCustomer',
  CanceledByStore = 'rejectedByStore'
}
