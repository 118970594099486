import { APIProvider, BaseStrategy, Branch, buildCommunication, EActionsTypes, StoreBranch } from '@axmit/redux-communications';
import { message } from 'antd';
import {
  ECustomerSuccessMessage,
  ICustomerCollection,
  ICustomerCollectionFilter,
  ICustomerModel,
  ICustomerParamsModel,
  ICustomerUpdateStatusParams
} from 'entities/Customer/Customer.models';
import { customerTransport } from 'entities/Customer/Customer.transport';

const namespace = 'customer';

export interface ICustomerConnectedProps {
  customerModel: StoreBranch<ICustomerModel, ICustomerParamsModel>;
  customerCollection: StoreBranch<ICustomerCollection, ICustomerCollectionFilter>;

  getCustomerModel(id: string): void;
  updateStatusCustomerModel(params: ICustomerUpdateStatusParams): void;

  getCustomerCollection(filter?: ICustomerCollectionFilter): void;

  clearCustomerModel(): void;
  clearCustomerCollection(): void;
}

const CustomerModelAPIProviders = [
  new APIProvider(EActionsTypes.get, customerTransport.get),
  new APIProvider(`updateStatus`, customerTransport.updateStatus, {
    onSuccess: function() {
      message.success(ECustomerSuccessMessage.StatusUpdate);
    }
  })
];

const CustomerCollectionAPIProviders = [new APIProvider(EActionsTypes.get, customerTransport.getCollection)];

const branches = [new Branch('model', CustomerModelAPIProviders), new Branch('collection', CustomerCollectionAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationCustomer = buildCommunication<ICustomerConnectedProps>(strategy);
