import React from 'react';
import { IStatisticOutOfStockProductModel, IStatisticProductMixModel } from 'entities/Statistic/Statistic.models';

interface IComponentProps {
  items?: (IStatisticOutOfStockProductModel | IStatisticProductMixModel)[];
}

type AllProps = IComponentProps;

export const MenuItemsListForStatistic: React.FC<AllProps> = (props): any => {
  const { items } = props;

  return items
    ? items.map((item: any, index: number) => (
        <React.Fragment key={index}>
          <span className="font-family__default fs-xxs font-weight-7">
            {item.name} ({item.orderFrequency || item.outOfStockCount})
          </span>
          <br />
        </React.Fragment>
      ))
    : null;
};
